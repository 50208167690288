import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Moment from "moment";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import Select from "react-select";

function Home() {
  //const options = ["one", "two", "three"];
  const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
  ];

  const network_options = [
    {value: "erc20token", label: "ERC20"},
    {value: "bep20token", label: "BEP20"},
    {value: "trc20token", label: "TRC20"},
    {value: "rptc20token", label: "RPTC20"},
  ];

  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState();
  const [view, setview, viewref] = useState("");
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus] = useState(0);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [cur_currency, setcur_currency, cur_currencyref] = useState("");
  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] = useState("");
  const [maintain, setmaintain, maintainref] = useState(false);

  useEffect(() => {
    getAllcurrency();
    getdepositHistory();
    getKYCstatus();
    getSiteSettingstatus();
    
  }, [0]);
  console.log(addressref.current,"--=-=-addressref=-=-=addressref=-=-=-=-addressref")


  // const getTransaction = async () => {
  //   var data = {
  //     apiUrl: apiService.transaction,
  //   };
  //   var resp = await getMethod(data);
  //   if (resp.message == true) {
  //     getdepositHistory();
  //   }
  // };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };

    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if(data[i].depositStatus == "Active")
        {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
          };
          currArrayCrypto.push(obj);
        }
        
      }
      console.log("network_currencyref===", network_currencyref.current);
      setallCurrency(currArrayCrypto);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    console.log("getkyc===", getKYC);
    if (getKYC.status) {
      console.log(getKYC.Message.kycstatus, "========");
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======", kycStatus);
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: {FilPerpage: 5, FilPage: 1},
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.crypto_deposit);
    }
  };

  const onSelect = async (option) => {
    console.log(option, "-=-onSelecttop");
    //setAddress()
    // if(option.label == "RPTC" || option.label == "RWT")
    // {
    //    setmaintain(true);
    // }
    // else
    // {
    //   setmaintain(false);
    setcur_network([]);
    setnet_default("");
    setcurrency(option.label);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      setcur_currency(currencydata);

      var network_cur = {};
      var network_names = [];
      if (currencydata.currencyType == "2") {
        if (currencydata.erc20token == "1") {
          network_cur = {
            value: "erc20token",
            label: "ERC20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.bep20token == "1") {
          network_cur = {
            value: "bep20token",
            label: "BEP20",
          };
          network_names.push(network_cur);
        }
        if (currencydata.trc20token == "1") {
          network_cur = {
            value: "trc20token",
            label: "TRC20",
          };
          network_names.push(network_cur);
        }
        setcur_network(network_names);
        console.log("network_currencyref===", network_currencyref.current);
        setnet_default(network_currencyref.current[0].label);
      }

      if (currencydata.coinType == "1" && currencydata.currencyType == "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };
        setview("load");
        var resp = await postMethod(data);
        console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview("view");
          setAddress(resp.Message);
          console.log(addressref.current,"--=-=-addressref=-=-=addressref=-=-=-=-addressref")
          // if (depData.erc20token == "1") {
          //   setNetwork("ERC20");
          // } else if (depData.trc20token == "1") {
          //   setNetwork("TRC20");
          // } else if (depData.bep20token == "1") {
          //   setNetwork("BEP20");
          // }
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
      //   else if (currencydata.coinType== "1" && currencydata.currencyType== "2") {
      //       console.log("call here")
      //     var obj = {
      //       currency: option.label,
      //       currId: option.value,
      //     };
      //     var data = {
      //       apiUrl: apiService.generateAddress_network,
      //       payload: obj,
      //     };

      //     var resp = await postMethod(data);
      //     console.log(resp, "=-=-=resp-=-=--");
      //     if (resp.status) {
      //       setview("view");
      //       setAddress(resp.Message);
      //       // if (depData.erc20token == "1") {
      //       //   setNetwork("ERC20");
      //       // } else if (depData.trc20token == "1") {
      //       //   setNetwork("TRC20");
      //       // } else if (depData.bep20token == "1") {
      //       //   setNetwork("BEP20");
      //       // }
      //     } else {
      //       //toast.error("Something went wrong, please try again later");
      //     }
      //   }
      else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
    }
    //}
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  const onSelect_network = async (option) => {
    console.log(option, "-=-onSelect_network");
    if (
      cur_currencyref.current.coinType == "1" &&
      cur_currencyref.current.currencyType == "2"
    ) {
      var obj = {
        currency: cur_currencyref.current.currencySymbol,
        currId: cur_currencyref.current._id,
        network: option.value,
      };
      console.log("call here 1111", obj);
      var data = {
        apiUrl: apiService.generateAddress_network,
        payload: obj,
      };
      setview("load");
      var resp = await postMethod(data);
      console.log(resp, "=-=-=resp-=-=--");
      if (resp.status) {
        setview("view");
        setAddress(resp.Message);
        // if (depData.erc20token == "1") {
        //   setNetwork("ERC20");
        // } else if (depData.trc20token == "1") {
        //   setNetwork("TRC20");
        // } else if (depData.bep20token == "1") {
        //   setNetwork("BEP20");
        // }
      } else {
        //toast.error("Something went wrong, please try again later");
      }
    }
  };
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">Deposit</h1>
                    </div>
                  </div>
                </div>
                {sitekycStatus == "Active" ? (
                  kycStatus == 1 ? (
                    <>
                      <div className="card m-0 table_card_new ">
                        <div className="row justify-content-center">
                          <div className="col-lg-6 mb-4">
                            <div className="row"></div>
                            <div className="deposit mt-4">
                              {viewref.current == "view" ? (
                                <div className="balance_balance">
                                  {addressref.current != "" ? (
                                    <p>
                                      Available Balance{" "}
                                      <span>
                                        {" "}
                                        {addressref.current==undefined?"":addressref.current.balance.toFixed(
                                          8
                                        )}
                                        {/* {addressref.current.balance.toFixed(
                                          4
                                        )}{" "} */}
                                        {currencyref.current}
                                      </span>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="form_accout calsss_dropdown">
                                <label>Select Currency</label>
                                {/* <Dropdown
                              options={allCurrencyref.current}
                              placeholder="Select an Currency"
                              onChange={(o) => onSelect(o)}
                              value={currencyref.current}
                            /> */}
                                <Dropdown
                                  options={allCurrencyref.current}
                                  placeholder="Select an Currency"
                                  onChange={(o) => onSelect(o)}
                                  defaultValue={currencyref.current}
                                  isSearchable={true}
                                  className="call_seclecy"
                                />
                              </div>
                              {cur_currencyref.current.currencyType == "2" ? (
                                <div className="form_accout calsss_dropdown">
                                  <label>Network</label>

                                  <Dropdown
                                    options={network_currencyref.current}
                                    placeholder="Select an Network"
                                    onChange={(o) => onSelect_network(o)}
                                    defaultValue={net_defaultref.current}
                                    isSearchable={true}
                                    className="call_seclecy"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {viewref.current == "view" ? (
                                <div className="QR_code">
                                  {addressref.current != "" ? (
                                    <img
                                      src={ addressref.current==undefined?"":addressref.current.qrcode }
                                      className=""
                                    />
                                  ) : (
                                    <img
                                      src={require("../img/newimg/qr.png")}
                                      className=""
                                    />
                                  )}

                                  <p>Scan QR</p>
                                </div>
                              ) : viewref.current == "load" ? (
                                <div className="spinner_icon">
                                  <div class="spinner-border" role="status">
                                    <i
                                      class="fa fa-spinner d-none"
                                      style={{color: "white"}}
                                    ></i>{" "}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="form_accout form_flex copyicon">
                                <label>Address</label>
                                <div className="copy_icon_deposi">
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    value={addressref.current==undefined?"":addressref.current.address}
                                    readOnly
                                  />
                                  {viewref.current == "view" ? (
                                    <span
                                      onClick={() =>
                                        copy(addressref.current.address)
                                      }
                                    >
                                      <i class="bi bi-clipboard-check"></i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {/* </>
                        <div className="form_accout form_flex copyicon">
                          <label>Address</label>
                          <div className="copy_icon_deposi">
                            <input type="text" value={addressref.current.address} readOnly placeholder="Address" />
                            {viewref.current == "view" ? (
                              <span
                                onClick={() =>
                                  copy(addressref.current.address)
                                }
                              >
                                <i class="bi bi-clipboard-check"></i>
                              </span>
                            ) : ("")}</div>
                        </div>
                        {/* </>
                          ) : ("")} */}

                              {/* {viewref.current == "view" ? (
                                <div className="balance_balance">
                                  <p>
                                    Minimum Deposit{" "}
                                    <span>
                                      {cur_currencyref.current.minDepositLimit}{" "}
                                    </span>
                                  </p>

                                  <p>
                                    Maximum Deposit{" "}
                                    <span>
                                      {cur_currencyref.current.maxDepositLimit}{" "}
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                ""
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-10px deposit_table_div">
                        <div className="card p-0 mt-0 deposit_card">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="Heading">
                                <h2>Recent Deposit Records</h2>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="table-responsive mt-4 history_table deposit_table">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Time</th>
                                      <th>Currency</th>
                                      <th>Amount</th>
                                      <th>Transaction Id</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {depositHistory &&
                                    depositHistory.length > 0 ? (
                                      depositHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              {Moment(item.date).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </td>
                                            <td>
                                              {Moment(item.date).format(
                                                "HH:mm:ss"
                                              )}
                                            </td>
                                            <td>{item.currencySymbol}</td>
                                            <td>
                                              {parseFloat(item.amount).toFixed(
                                                8
                                              )}
                                            </td>
                                            <td className="d-flex">
                                              <span className="hash_code">
                                                {item.txnid}
                                              </span>
                                              <i
                                                class="fa fa-clone"
                                                aria-hidden="true"
                                                onClick={() => copy(item.txnid)}
                                                style={{cursor: "pointer"}}
                                              ></i>
                                            </td>
                                            <td>
                                              <span className="text-green">
                                                Completed
                                              </span>
                                            </td>
                                          </tr>
                                          // <tr>
                                          //   <td>20:20:45</td>
                                          //   <td>BTC</td>
                                          //   <td>1000 INR</td>
                                          //   <td>BSC</td>
                                          //   <td>
                                          //     <span class="hash_code">
                                          //       3423423423423vrfsfsdfe55345dfg454
                                          //     </span>
                                          //   </td>
                                          //   <td>ID0922201</td>
                                          //   <td>
                                          //     <span className="text-red">Canceled</span>
                                          //   </td>
                                          // </tr>
                                          // <tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="6">
                                          <div className="norecode_found">
                                            <img
                                              src={require("../img/newimg/banksearch.png")}
                                            />
                                            <span>No records found</span>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="card m-0 table_card_new ">
                      <div className="row justify-content-center">
                        <div className="row">
                          <div className="col-lg-12">
                            <img
                              src={require("../img/newimg/6306471.png")}
                              className="img-fluid warning_ing"
                            />
                            <div className="warning_content">
                              <h2>
                                Please complete the KYC verification process to
                                gain access to the page.
                              </h2>
                              <Link to="/kyc">Verify KYC Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <>
                    <div className="card m-0 table_card_new ">
                      <div className="row justify-content-center">
                        <div className="col-lg-6 mb-4">
                          <div className="row"></div>
                          <div className="deposit mt-4">
                            {viewref.current == "view" ? (
                              <div className="balance_balance">
                                {addressref.current != "" ? (
                                  <p>
                                    Available Balance{" "}
                                    <span>
                                      {" "}
                                      {addressref.current.balance.toFixed(
                                        8
                                      )}{" "}
                                      {currencyref.current}
                                    </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form_accout calsss_dropdown">
                              <label>Select Currency</label>
                              {/* <Dropdown
                              options={allCurrencyref.current}
                              placeholder="Select an Currency"
                              onChange={(o) => onSelect(o)}
                              value={currencyref.current}
                            /> */}
                              <Dropdown
                                options={allCurrencyref.current}
                                placeholder="Select an Currency"
                                onChange={(o) => onSelect(o)}
                                defaultValue={currencyref.current}
                                isSearchable={true}
                                className="call_seclecy"
                              />
                            </div>
                            {cur_currencyref.current.currencyType == "2" ? (
                              <div className="form_accout calsss_dropdown">
                                <label>Network</label>

                                <Dropdown
                                  options={network_currencyref.current}
                                  placeholder="Select an Network"
                                  onChange={(o) => onSelect_network(o)}
                                  defaultValue={net_defaultref.current}
                                  isSearchable={true}
                                  className="call_seclecy"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {viewref.current == "view" ? (
                              <div className="QR_code">
                                {addressref.current != "" ? (
                                  <img
                                    src={addressref.current.qrcode}
                                    className=""
                                  />
                                ) : (
                                  <img
                                    src={require("../img/newimg/qr.png")}
                                    className=""
                                  />
                                )}

                                <p>Scan QR</p>
                              </div>
                            ) : viewref.current == "load" ? (
                              <div className="spinner_icon">
                                <div class="spinner-border" role="status">
                                  <i
                                    class="fa fa-spinner d-none"
                                    style={{color: "white"}}
                                  ></i>{" "}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form_accout form_flex copyicon">
                              <label>Address</label>
                              <div className="copy_icon_deposi">
                                <input
                                  type="text"
                                  placeholder="Address"
                                  value={addressref.current==undefined ?"":addressref.current.address}
                                  readOnly
                                />
                                {viewref.current == "view" ? (
                                  <span
                                    onClick={() =>
                                      copy(addressref.current.address)
                                    }
                                  >
                                    <i class="bi bi-clipboard-check"></i>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* </>
                        <div className="form_accout form_flex copyicon">
                          <label>Address</label>
                          <div className="copy_icon_deposi">
                            <input type="text" value={addressref.current.address} readOnly placeholder="Address" />
                            {viewref.current == "view" ? (
                              <span
                                onClick={() =>
                                  copy(addressref.current.address)
                                }
                              >
                                <i class="bi bi-clipboard-check"></i>
                              </span>
                            ) : ("")}</div>
                        </div>
                        {/* </>
                          ) : ("")} */}

                            {/* {viewref.current == "view" ? (
                              <div className="balance_balance">
                                <p>
                                  Minimum Deposit{" "}
                                  <span>
                                    {cur_currencyref.current.minDepositLimit}{" "}
                                  </span>
                                </p>

                                <p>
                                  Maximum Deposit{" "}
                                  <span>
                                    {cur_currencyref.current.maxDepositLimit}{" "}
                                  </span>
                                </p>
                              </div>
                            ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-10px deposit_table_div">
                      <div className="card p-0 mt-0 deposit_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2>Recent Deposit Records</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table deposit_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Currency</th>
                                    <th>Amount</th>
                                    <th>Transaction Id</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {depositHistory &&
                                  depositHistory.length > 0 ? (
                                    depositHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            {Moment(item.date).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </td>
                                          <td>
                                            {Moment(item.date).format(
                                              "HH:mm:ss"
                                            )}
                                          </td>
                                          <td>{item.currencySymbol}</td>
                                          <td>
                                            {parseFloat(item.amount).toFixed(8)}
                                          </td>
                                          <td className="d-flex">
                                            <span className="hash_code">
                                              {item.txnid}
                                            </span>
                                            <i
                                              class="fa fa-clone"
                                              aria-hidden="true"
                                              onClick={() => copy(item.txnid)}
                                              style={{cursor: "pointer"}}
                                            ></i>
                                          </td>
                                          <td>
                                            <span className="text-green">
                                              Completed
                                            </span>
                                          </td>
                                        </tr>
                                        // <tr>
                                        //   <td>20:20:45</td>
                                        //   <td>BTC</td>
                                        //   <td>1000 INR</td>
                                        //   <td>BSC</td>
                                        //   <td>
                                        //     <span class="hash_code">
                                        //       3423423423423vrfsfsdfe55345dfg454
                                        //     </span>
                                        //   </td>
                                        //   <td>ID0922201</td>
                                        //   <td>
                                        //     <span className="text-red">Canceled</span>
                                        //   </td>
                                        // </tr>
                                        // <tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="6">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No records found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
