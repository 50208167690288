import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Moment from "moment";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  const [perpage, setperpage] = useState(5);
  const [notifications, setNotification] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);



  useEffect(() => {
    getNotificationsall(currentPage);
  }, [0]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getNotificationsall(pageNumber);
  };

  const recordPerPage = 5;
  const pageRange = 5;



  const getNotificationsall = async (pages) => {
    try {
      var obj = {
        perpage: perpage,
        page: pages,
      };
      var data = {
        apiUrl: apiService.getnotificationsAll,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotal(resp.data.total);
        setNotification(resp.data.data);
      } else {
      }
    } catch (error) { }
  };


  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">Notification</h1>
                    </div>
                  </div>
                </div>

                <div className="card m-0 table_card_new ">
                  <div className="row">
                   {notifications&&notifications.length>0?
                      notifications.map((item, i) => {
                        var dates = Moment(item.createdAt).format(
                          "DD.MM.YYYY hh:mm a"
                        );
                        return (

                          <div className="col-lg-12">
                            <Link to={item.link}>
                              <div className="notificarion_items">
                                <div className="dataile">
                                  <h5>
                                    <i class="bi bi-card-list"></i>{item.message}
                                  </h5>
                                  {item.message == 'Login sucessfully' ?
                                    (<p className="text-white">Your account is logged in {item.IP} IP address.</p>) : ""
                                  }
                                </div>
                                <div className="date">
                                  <span> {dates} </span>
                                </div>

                              </div>
                            </Link>

                          </div>

                        )
                      }
                     
                      )
                      :
                      <div className="col-lg-12 text-white text-center  pt-5px">

                          <p className="paginate">No records found</p>
                          </div>
                      
                    }
                    {notifications && notifications.length>0?

                      <div className="col-lg-12">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPage}
                          itemsCountPerPage={recordPerPage}
                          totalItemsCount={total}
                          pageRangeDisplayed={pageRange}
                          onChange={handlePageChange}
                        />
                      </div>
                      :""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
