import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link, useNavigate} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import Moment from "moment";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";

function Home() {
  const options = ["one", "two", "three"];

  const [perpage, setperpage] = useState(5);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [stopOrders, setstopOrders] = useState([]);
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [depositHistory, setdepositHistory] = useState([]);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [TfaHistory, setTfaHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);

  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [currentPagestop, setCurrentPagestop] = useState(1);
  const [totalStop, settotalStop] = useState(0);

  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const [logincurrentpage, setlogincurrentpage] = useState(1);
  const [logintotalpage, setloginTotalpages] = useState(0);

  const [tfacurrentpage, settfacurrentpage] = useState(1);
  const [tfatotalpage, settfaTotalpages] = useState(0);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const recordPerPageStop = 5;
  const pageRangeStop = 5;

  const recordPerPageLogin = 5;
  const pageRangeLogin = 5;

  const navigate = useNavigate();

  useEffect(() => {
    getActiveOrders(1);
    tradeHistory(1);
    getCancelOrders(1);
    getStopOrders(1);
    getdepositHistory(1);
    getwithdrawHistory(1);
    getLoginHistory(1);
    Tfa_History(1);
  }, [0]);

  const getActiveOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getStopOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getStopOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalStop(resp.count);
        setstopOrders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        toast.success(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1);
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const getLoginHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setloginTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber);
  };

  const cancelPageChange = (pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderstopactive = (pageNumber) => {
    setCurrentPagestop(pageNumber); // call API to get data based on pageNumber
    getStopOrders(pageNumber);
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: {FilPerpage: 5, FilPage: page},
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setdepositTotalpages(deposit_history_list.total);
      console.log("deposit_history_list.pages===", deposittotalpage);
    }
  };

  const depositrecordpage = 5;
  const depositpagerange = 5;
  const handlepagedeposit = (page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: {FilPerpage: 5, FilPage: page},
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.total);
    }
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;
  const handlepagewithdraw = (page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const loginrecordpage = 5;
  const loginpagerange = 5;

  const handlepagelogin = (pageNumber) => {
    setlogincurrentpage(pageNumber); // call API to get data based on pageNumber
    getLoginHistory(pageNumber);
  };

  const Tfa_History = async (page) => {
    var data = {
      apiUrl: apiService.Tfa_History,
      payload: {perpage: 5, page: page},
    };
    var Tfa_History = await postMethod(data);
    if (Tfa_History) {
      setTfaHistory(Tfa_History.data.data);
      settfaTotalpages(Tfa_History.data.total);
    }
  };
  const tfarecordpage = 5;
  const tfapagerange = 5;
  const handlepagetfachange = (pageNumber) => {
    Tfa_History(pageNumber);
    settfacurrentpage(pageNumber);
  };
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">All History</h1>
                    </div>
                  </div>
                </div>
                <div className="card m-0 table_card_new ">
                  <div className="history_tabs">
                    <ul class="nav nav-tabs">
                      <li class="active">
                        <a
                          data-toggle="tab"
                          href="#Open_orders"
                          className="active"
                        >
                          Trade Open orders
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Cancel_Orders">
                          Cancel Orders
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Trade_History">
                          Trade History{" "}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Stop_Orders">
                          Stop Orders{" "}
                        </a>
                      </li>

                      <li>
                        <a data-toggle="tab" href="#Deposit_History">
                          Deposit History{" "}
                        </a>
                      </li>

                      <li>
                        <a data-toggle="tab" href="#Withdraw_History">
                          Withdraw History
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Login_History">
                          Login History{" "}
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#Tfa_History">
                          2FA History{" "}
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content">
                    <div id="Open_orders" class="tab-pane fade in active show">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Trade Open orders</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Pair </th>
                                    <th>Type </th>
                                    <th>Side </th>
                                    <th>Quantity</th>
                                    <th>Price </th>
                                    <th>Total </th>
                                    <th>Status </th>
                                    <th>Action </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {stopOrders.length > 0 ? (
                                    stopOrders.map((item, i) => {
                                      var dates = Moment(
                                        item.createddate
                                      ).format("DD.MM.YYYY hh:mm a");
                                      return (
                                        <tr>
                                          <td>{dates}</td>
                                          <td>{item.pairName}</td>
                                          <td>{item.ordertype}</td>
                                          <td>{item.tradeType}</td>
                                          <td>
                                            {parseFloat(
                                              item.filledAmount
                                            ).toFixed(8)}{" "}
                                            {item.firstSymbol}
                                          </td>
                                          <td>
                                            {item.ordertype == "Stop"
                                              ? parseFloat(
                                                  item.stoporderprice
                                                ).toFixed(8)
                                              : parseFloat(item.price).toFixed(
                                                  8
                                                )}{" "}
                                            {item.toSymbol}
                                          </td>
                                          <td>
                                            {item.ordertype == "Stop"
                                              ? parseFloat(
                                                  item.filledAmount *
                                                    item.stoporderprice
                                                ).toFixed(8)
                                              : parseFloat(
                                                  item.filledAmount * item.price
                                                ).toFixed(8)}{" "}
                                            {item.toSymbol}
                                          </td>
                                          <td>
                                            {item.tradeType == "buy" ? (
                                              <span className="text-green">
                                                {item.status}
                                              </span>
                                            ) : (
                                              <span className="text-red">
                                                {item.status}
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            <Button
                                              onClick={() => orderCancel(item)}
                                            >
                                              Cancel
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="9">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Active Orders Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                    <td>01.01.2022</td>
                                    <td>BTC/USTD</td>
                                    <td>Limit</td>
                                    <td>0.0001 BTC</td>
                                    <td>1000 INR</td>
                                    <td>1000 INR</td>
                                    <td>
                                      <span className="text-green">Active</span>
                                    </td>
                                    <td>
                                      <Button>Cancel</Button>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {activeOrderDatas && activeOrderDatas.length > 0 ? (
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPage}
                          itemsCountPerPage={recordPerPage}
                          totalItemsCount={totalactive}
                          pageRangeDisplayed={pageRange}
                          onChange={activePageChange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Cancel_Orders" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Cancelled Orders</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Pair </th>
                                    <th>Type </th>
                                    <th>Side </th>
                                    <th>Quantity</th>
                                    <th>Price </th>
                                    <th>Total </th>
                                    <th>Status </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {cancelOrders.length > 0 ? (
                                    cancelOrders.map((item, i) => {
                                      var total =
                                        item.ordertype == "Stop"
                                          ? +item.filledAmount *
                                            +item.stoporderprice
                                          : +item.filledAmount * +item.price;
                                      var price =
                                        item.ordertype == "Stop"
                                          ? +item.stoporderprice
                                          : +item.price;
                                      return (
                                        <tr>
                                          <td>{item.createddate}</td>
                                          <td>{item.pairName}</td>
                                          <td>{item.ordertype}</td>
                                          <td>{item.tradeType}</td>
                                          <td>
                                            {item.filledAmount}{" "}
                                            {item.firstSymbol}
                                          </td>
                                          <td>
                                            {price} {item.toSymbol}
                                          </td>
                                          <td>
                                            {total} {item.toSymbol}
                                          </td>
                                          <td>
                                            <span className="text-red">
                                              Cancelled
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="8">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Cancelled Orders Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {cancelOrders && cancelOrders.length > 0 ? (
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPagecan}
                          itemsCountPerPage={recordPerPagecan}
                          totalItemsCount={totalcan}
                          pageRangeDisplayed={pageRangecan}
                          onChange={cancelPageChange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Trade_History" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Trade History</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Pair </th>
                                    <th>Type </th>
                                    <th>Price </th>
                                    <th>Amount </th>
                                    <th>Total </th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {tradeHistoryData.length > 0 ? (
                                    tradeHistoryData.map((item, i) => {
                                      var datesHis = Moment(
                                        item.created_at
                                      ).format("DD.MM.YYYY hh:mm a");
                                      return (
                                        <tr>
                                          <td>{datesHis} </td>
                                          <td>{item.pair} </td>
                                          <td>{item.type} </td>
                                          <td> {item.askPrice} </td>
                                          <td> {item.askAmount} </td>
                                          <td>{item.total}</td>
                                          <td>
                                            {item.type == "buy" ? (
                                              <span className="text-green">
                                                Filled
                                              </span>
                                            ) : (
                                              <span className="text-red">
                                                Filled
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="7">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Trade History Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {tradeHistoryData && tradeHistoryData.length > 0 ? (
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPageHis}
                          itemsCountPerPage={recordPerPageHist}
                          totalItemsCount={totalHist}
                          pageRangeDisplayed={pageRangeHis}
                          onChange={orderhistoryactive}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Stop_Orders" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Stop Orders</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Pair </th>
                                    <th>Side</th>
                                    <th>Quantity</th>
                                    <th>Stop Price </th>
                                    <th>Limit Price </th>
                                    <th>Total </th>
                                    <th>Status </th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {stopOrders.length > 0 ? (
                                    stopOrders.map((item, i) => {
                                      var dates = Moment(
                                        item.createddate
                                      ).format("DD.MM.YYYY hh:mm a");
                                      return (
                                        <tr>
                                          <td>{dates}</td>
                                          <td>{item.pairName}</td>
                                          <td>{item.tradeType}</td>
                                          <td>
                                            {parseFloat(
                                              item.filledAmount
                                            ).toFixed(8)}{" "}
                                            {item.firstSymbol}
                                          </td>
                                          <td>
                                            {parseFloat(item.price).toFixed(8)}{" "}
                                            {item.toSymbol}
                                          </td>
                                          <td>
                                            {parseFloat(
                                              item.stoporderprice
                                            ).toFixed(8)}{" "}
                                            {item.toSymbol}
                                          </td>
                                          <td>
                                            {parseFloat(
                                              item.filledAmount *
                                                item.stoporderprice
                                            ).toFixed(8)}
                                          </td>
                                          <td>
                                            {item.tradeType == "buy" ? (
                                              <span className="text-green">
                                                {item.status}
                                              </span>
                                            ) : (
                                              <span className="text-red">
                                                {item.status}
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            <Button
                                              onClick={() => orderCancel(item)}
                                            >
                                              Cancel
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="9">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Stop Orders Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                    <td>01.01.2022</td>
                                    <td>BTC/USTD</td>
                                    <td>Limit</td>
                                    <td>0.0001 BTC</td>
                                    <td>1000 INR</td>
                                    <td>1000 INR</td>
                                    <td>1000 INR</td>
                                    <td>
                                      <span className="text-green">Active</span>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {stopOrders && stopOrders.length > 0 ? (
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={currentPagestop}
                          itemsCountPerPage={recordPerPageStop}
                          totalItemsCount={totalStop}
                          pageRangeDisplayed={pageRangeStop}
                          onChange={orderstopactive}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Deposit_History" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Deposit History</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Currency</th>
                                    <th>Amount</th>
                                    <th>Transaction Id</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {depositHistory &&
                                  depositHistory.length > 0 ? (
                                    depositHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            {Moment(item.date).format(
                                              "DD.MM.YYYY hh:mm a"
                                            )}
                                          </td>
                                          <td>{item.currencySymbol}</td>
                                          <td>
                                            {parseFloat(item.amount).toFixed(8)}
                                          </td>
                                          <td>
                                            <span className="hash_code">
                                              {item.txnid}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="text-green">
                                              Completed
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="5">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Deposit Records Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                    <td>01.01.2022</td>
                                    <td>BTC</td>
                                    <td>BNB</td>
                                    <td>0.0001 BTC</td>
                                    <td>
                                      <span className="hash_code">
                                        aksdjhkjha7638723jhg342
                                      </span>
                                    </td>

                                    <td>
                                      <span className="text-green">Active</span>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {depositHistory && depositHistory.length > 0 ? (
                        <Pagination
                          itemClass="page-item" // add it for bootstrap 4
                          linkClass="page-link" // add it for bootstrap 4
                          activePage={depositcurrentpage}
                          itemsCountPerPage={depositrecordpage}
                          totalItemsCount={deposittotalpage}
                          pageRangeDisplayed={depositpagerange}
                          onChange={handlepagedeposit}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Withdraw_History" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Withdraw History</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Currency</th>
                                    <th>Amount</th>
                                    <th>Address</th>
                                    <th>Transaction Id</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {withdrawHistory &&
                                  withdrawHistory.length > 0 ? (
                                    withdrawHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            {Moment(item.created_at).format(
                                              "DD.MM.YYYY hh:mm a"
                                            )}
                                          </td>
                                          <td>{item.currency}</td>
                                          <td>
                                            {parseFloat(item.amount).toFixed(8)}
                                          </td>
                                          <td>
                                            <span className="hash_code">
                                              {item.withdraw_address}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="hash_code">
                                              {item.txn_id}
                                            </span>
                                          </td>
                                          <td>
                                            {item.status == "Completed" ? (
                                              <span className="text-green">
                                                {item.status}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {item.status !== "Completed" ? (
                                              <span className="text-red">
                                                {item.status}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="7">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Withdraw history found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                    <td>01.01.2022</td>
                                    <td>BTC</td>
                                    <td>BNB</td>
                                    <td>
                                      <span className="hash_code">
                                        3423423423423vrfsfsdfe55345dfg454
                                      </span>
                                    </td>
                                    <td>
                                      <span className="hash_code">
                                        93423423423423vrfret453sfsdfe55345dfg454e
                                      </span>
                                    </td>
                                    <td>0.0001 BTC</td>
                                    <td>
                                      <span className="hash_code">
                                        aksdjhkjha7638723jhg342
                                      </span>
                                    </td>

                                    <td>
                                      <span className="text-green">Active</span>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {withdrawHistory && withdrawHistory.length > 0 ? (
                        <Pagination
                          itemClass="page-item" // add it for bootstrap 4
                          linkClass="page-link" // add it for bootstrap 4
                          activePage={withdrawcurrentpage}
                          itemsCountPerPage={withdrawrecordpage}
                          totalItemsCount={withdrawtotalpage}
                          pageRangeDisplayed={withdrawpagerange}
                          onChange={handlepagewithdraw}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Tfa_History" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> 2fa History</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Data & Time </th>
                                    <th>Ip Address </th>
                                    <th>Browser</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {TfaHistory.length > 0 ? (
                                    TfaHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>{item.createdDate}</td>
                                          <td>{item.ipAddress}</td>
                                          <td>{item.browser}</td>
                                          <td>{item.status}</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="9">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Active Orders Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {TfaHistory && TfaHistory.length > 0 ? (
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={tfacurrentpage}
                          itemsCountPerPage={tfarecordpage}
                          totalItemsCount={tfatotalpage}
                          pageRangeDisplayed={tfapagerange}
                          onChange={handlepagetfachange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div id="Login_History" class="tab-pane fade">
                      <div className="card p-0 mt-0 allhistory_card">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2> Login History</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Ip Address</th>
                                    <th>Device</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {sessionHistory.length > 0 &&
                                  sessionHistory ? (
                                    sessionHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            {Moment(item.createdDate).format(
                                              "DD.MM.YYYY hh:mm a"
                                            )}
                                          </td>
                                          <td>{item.ipAddress}</td>
                                          <td>{item.platform}</td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="3">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No Login History Found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {sessionHistory && sessionHistory.length > 0 ? (
                        <Pagination
                          itemClass="page-item" // add it for bootstrap 4
                          linkClass="page-link" // add it for bootstrap 4
                          activePage={logincurrentpage}
                          itemsCountPerPage={loginrecordpage}
                          totalItemsCount={logintotalpage}
                          pageRangeDisplayed={loginpagerange}
                          onChange={handlepagelogin}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
