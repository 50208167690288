import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import {Button} from "@material-ui/core";

import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";
import moment from "moment";
import Pagination from "react-js-pagination";

function Home() {
  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata] = useState(value);

  const [SubjectErr, setSubjectErr] = useState(value);
  const [CategoryErr, setCategoryErr] = useState(value);
  const [textErr, settextErr] = useState(value);

  const [formErr, setformErr] = useState("");

  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [supportCategories, setCategories] = useState(0);

  const {Subject, Category, text} = Formdata;

  const getItem = (e) => {
    var {name, value} = e.target;
    let check = {...Formdata, ...{[name]: value}};
    setFormdata(check);
    validate(Formdata);
  };

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    viewData();
    getCategory();
  }, [0]);

  const validate = async (condition) => {
    var error = {};
    if (condition.Subject == "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (condition.Subject.length < 5) {
      error.Subject = "Minimum 5 Characters only allowed ";
      setSubjectErr(true);
    } else if (condition.Subject.length > 50) {
      error.Subject = "Maximum 50 Characters only allowed ";
      setSubjectErr(true);
    } else {
      setSubjectErr(false);
    }

    if (condition.text == "") {
      error.text = "Message is a required field";
      settextErr(true);
    } else if (condition.text.length < 10) {
      error.text = "Minimum 10 Characters only allowed ";
      settextErr(true);
    } else if (condition.text.length > 250) {
      error.text = "Maximum 250 Characters only allowed ";
      settextErr(true);
    } else {
      settextErr(false);
    }
    setformErr(error);
  };

  const submit = async () => {
    validate(Formdata);
    // return false;
    if (
      Formdata.Subject != "" &&
      Formdata.Subject.length > 5 &&
      Formdata.Subject.length < 50 &&
      Formdata.Category != "Choose Category" &&
      Formdata.text != "" &&
      Formdata.text.length > 10 &&
      Formdata.text.length < 250
    ) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData();
    } else {
      //toast.error("All are required fields");
    }
  };
  const viewData = async () => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
      };
      var view = await postMethod(api);
      if (view.status) setuser(view.data.data);
      setTotalpages(view.data.total);
      console.log(view.data.total);
    } catch (error) {}
  };

  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      var response = await getMethod(api);
      if (response.status) {
        setCategories(response.data);
      }
    } catch (error) {}
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover height_100">
        {/* <Header /> */}
        <div className="container pt-5 padin_zero">
          <div className=" padin_zero">
            <div className="container d-flex justify-content-center flexnew_center contecnt_ee_pading">
              <div className="card add_padin_card w-100">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <h1 className="bard_tirlrr">Chat</h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 padin_zero">
                <div className="staking_title launcpad d-block fonrm_card_s need_meanissss">
                  <div class="row justify-center">
                    <div className="col-lg-6">
                      <div className="chat-interface">
                        <div className="admin_message">
                          <div className="messamge_section admin">
                            <p>
                              Admin <span>01.01.2022 12:00:00</span>
                            </p>
                            <div className="content">
                              Use Figma's prototyping tools to create
                              interactive elements such as hover effects and
                              button interactions
                            </div>
                          </div>
                          <div className="messamge_section user">
                            <p>
                              UserName <span>01.01.2022 12:00:00</span>
                            </p>
                            <div className="content">
                              Use Figma's prototyping tools to create
                              interactive elements such as hover effects and
                              button interactions
                            </div>
                          </div>
                        </div>
                        <div className="msg_send_section">
                          <input type="text" placeholder="Enter your message" />
                          <div className="actionbutton">
                            <div className="file_upload">
                              <input type="file" />
                              <span>
                                <i class="bi bi-paperclip"></i>
                              </span>
                            </div>
                            <Button>
                              <i
                                class="fa fa-paper-plane"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
