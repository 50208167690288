const service = {
  signup: "users/register",
  emailotpverify: "users/emailotpverify",
  resendCode: "users/resendCode",
  signin: "users/login",
  logout: "users/logout",
  getContent: "adminapi/getContent",
  changepswdlink: "users/changepswdlink",
  verifyForgotpasslink: "users/verifyForgotpasslink",
  resetpassword: "users/forgotpassword",
  profileUpdate: "/user/profileAdd",
  update_profile: "users/update_profile",
  getuserProfile: "users/getUserProfile",
  getUserDetails: "users/getUserDetails",
  mobilenumberupdate: "users/mobilenumberupdate",
  emailupdate: "users/emailupdate",
  getnotification: "users/getnotification",
  getSessionHisotry: "users/getSessionHisotry",
  getKYC: "kyc/getkyc",
  savebankdetails: "users/savebankdetails",
  savekyc: "kyc/savekyc",
  Bankdetails: "users/submit_bankdetails",
  Getbankdetails: "users/Get_bankdetails",
  // defaultBanks:"user/defaultBanks",
  generateAddress: "address/getUserAddress",
  submitWithdraw: "withdraw/withdraw_submit",
  confirmWithdraw: "withdraw/withdraw_user_action",
  bankwire: "users/getbankwire",
  fiatdepositSubmit: "withdraw/fiatdeposit_submit",
  createdSupport: "support/create_ticket",
  findedSupport: "support/ticket_list",
  getP2Pcurrency: "p2p/p2p_currencies",
  p2pOrder: "p2p/postAddOrder",
  p2pGetOrder: "p2p/getAllp2pOrder/",
  getp2pOrder: "p2p/getp2pOrder/",
  p2pchat: "p2p/p2p_chat/",
  getp2pchat: "p2p/getp2pchat/",
  buyer_confirm: "p2p/buyer_confirm_payment/",
  seller_confirm: "p2p/seller_coin_confirm",
  buyer_cancel: "p2p/buyer_cancel/",
  seller_cancel: "p2p/seller_cancel",
  submitfiatWithdraw: "withdraw/fiatwithdraw_submit",
  p2pOrders: "p2p/myOrders",
  getnotifyOrder: "p2p/getnotifyOrder/",

  getCurrpairData: "trade/getCurrpairData",
  
  getUserTotalbalance: "users/getUserTotalbalance",
  getUserBalance: "users/getUserBalance",
  getparUserBalance: "trade/getparUserBalance",
  orderPlaceapi: "orderPlaceApi/orderPlace",
  addBank: "/user/addBank",
  getBank: "/user/getBank",
  defaultBanks: "users/defaultBankChoose",
  deletbank: "users/deletbankDetails",
  savekyc_address: "/kyc/savekyc_Address",
  userLoginHistory: "/user/getLoginHistory",
  p2pCompareAPI: "p2p/cryptoCompare",
  buy_sell_Express: "p2p/buy_sell_Express_api",
  changePassword: "users/changePassword",
  getTfaDetials: "users/tfaDetials",
  changeTfaStatus: "users/changeTfaStatus",
  tfaVerify: "users/tfa_login",
  getBalance: "/user/getAllBalances",
  checkwallet: "/checkwallet",
  transactionHistory: "/withdraw/transactionHistory",
  resetPass: "/user/retetPassword",
  forgotepassword: "/user/forgotepassword",
  notifications: "p2p/notifications",
  p2pMyorders: "/p2p/p2pMyorders",
  p2pExpress: "/p2p/p2pExpressOrders",
  getSupportData: "/adminapi/support_category_list",
  createSupport: "/user/create_ticket",
  get_Ticket: "/user/get_Ticket",
  updateTickets: "/user/updateTicket",
  viewChat: "/user/viewChat",
  closeTicket: "support/closeTicket",
  pairbycurrency: "adminapi/tradepair/viewbycurrency",
  fetch_tickers: "users/fetch_tickers",
  withdraw_history: "withdraw/withdraw_history",
  Tfa_History: "users/Tfa_History",
  deposit_history: "withdraw/get_user_deposit",
  getActiveOrders: "users/getActiveOrders",
  getStop_limit_Orders: "users/getStop_limit_Orders",
  marketTrades: "marketTrades/getMarketTrades",
  tradeHistory: "users/tradeHistory",
  getCancelOrders: "users/getCancelOrders",
  getCurrency: "users/getcurrency",
  getSiteDatas: "adminapi/get_sitedata",
  getStatkingDetails: "staking/getStatkingDetails",
  getUserBalanceAll: "withdraw/getUserBalanceStaking",
  confirmStaking: "staking/confirmStaking_user",
  getAllstakingHistory: "staking/getAllstakingHistory",
  getStakingTotal: "staking/getStakingTotal",
  claimNowapi: "staking/claimNowapi",
  submitForm: "launchPad/submitForm",
  getAllLaunchpad: "launchPad/getAllLaunchpad",
  getTokenDetails: "launchPad/getTokenDetails",
  launchPadcurrency: "launchPad/launchPadcurrency",
  getCurrencyConvertion: "launchPad/getCurrencyConvertion",
  tokenPurchase: "launchPad/tokenPurchase",
  lauchPadHistory: "launchPad/lauchPadHistory",
  cancelOrder: "orderCancelApi/cancelOrder",
  claimNowapiFlexible: "staking/claimNowapiFlexible",
  portfolioBalance: "users/portfolio_user_balance",
  transfer_balance: "users/transfer_balance",
  currency_list: "users/currency_list",
  p2p_confirm_order: "p2p/p2p_confirm_order",
  p2p_confirm_sellorder: "p2p/p2p_confirm_sellorder",
  p2p_confirm_check: "p2p/p2p_confirm_check",
  p2pHistory: "p2p/p2p_history",
  getP2Pbalance: "p2p/p2p_balance",
  allpairs: "adminapi/allpairs",
  getKYCDetails: "kyc/getKYCDetails",
  savebank: "kyc/savekyc",
  raise_dispute: "p2p/dispute_order",
  confirmp2porder: "p2p/p2p_check_confirm",
  fetch_price: "p2p/fetch_price",
  getp2pBalance: "p2p/getp2pBalance",
  viewp2pOrder: "p2p/viewp2pOrder/",
  cancelConfirmBuy: "p2p/cancel_confirm_order",
  cancelConfirmSell: "p2p/cancel_confirmorder_sell",
  readNotify: "p2p/read_notify",
  update_p2p: "p2p/update_order",
  buyer_pay_cancel: "p2p/buyer_pay_cancel",
  p2pProcessOrders: "p2p/processOrders",
  contact: "users/contact_us",
  walletcurrency: "withdraw/get_currency_list",
  stripeWallet: "withdraw/api/create-checkout-session",
  findDeposit: "withdraw/findDeposit",
  user_balance: "withdraw/user_balance",
  getStopOrders: "users/getStopOrders",
  getKYCStatus: "users/kycStatus",
  getnotifications: "users/notifications",
  getnotificationsAll: "users/getnotificationsAll",
  updateNotifyRecord: "users/updateNotifyRecord",
  supportCategories: "support/get_support_category",
  signup_mobile: "users/register_mobile",
  mobileotpverify: "users/mobileotpverify",
  resendsmsCode: "users/resendsmsCode",

  signin_mobile: "users/login_mobile",
  changepswdlink_mobile: "users/changepswdlink_mobile",
  resetpassword_mobile: "users/forgotpassword_mobile",
  getUserBalanceSwap: "withdraw/getUserBalanceSwap",
  // currencyConversion: "swap/currencyConversion",
  swapping: "swap/swapping",
  swappingHistory: "swap/swappingHistory",
  getSupportDetails: "support/getSupportDetails",
  addUserSupport: "support/supportList/update",
  // closeTicket: "support/closeTicket",
  generateAddress_network: "address/getUserAddress_network",
  send_otp: "withdraw/send_withdraw_otp",
  getReward: "users/getReward",
  referralHistory: "users/referralHistory",
  homeCurrency: "users/homecurrency",
  get_p2p_active_currency: "users/get_p2p_active_currency",
  currencyConversion: "users/currencyConversion",
  transaction: "users/getTransaction",
  Addaddress: "users/Addaddress",
  getAddress: "users/getAddress",
  removeAddress: "users/removeAddress",
  mobile_currency_network:"address/mobile_currency_network",
  earningHistory: "users/earningHistory",
  

};

export default service;
