import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button, useTheme } from "@material-ui/core";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import Slider, {Range} from "rc-slider";
// import "rc-slider/assets/index.css";
import Header from "./Header";
import Sideheader from "./Sidebarheader";
import Newsideheader from "./Newsideheader";
import Footer from "./Footer";
import Chart from "./chart/Chart";
import ReactPlayer from "react-player";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { socket } from "./context/socket";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from "react-js-pagination";

import SliderNew from "react-slick";
import IconC from "../img/BNB.png";
import { toast } from "react-toastify";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};
var newsticker = {
  speed: 4000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: "linear",
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
};

function Home() {
  const options = ["one", "two", "three"];
  const navigate = useNavigate();

  const [refferance, setrefferance] = useState("");
  const [refferHistoty, setRefferHistoty] = useState("");
  const [totalPage, setTotalPages] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  const [totalRefferal, setTotalRefferal] = useState("");
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    getReferralDetails(0);
    getReward(0);
    referralHistory(0);
    get_rewardHistory(0);
  }, []);

  const title = "Welcome to Cashbit";
  const getReferralDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        var link = resp.refer_link;
        setrefferance(link);
        console.log(refferance, "reffernce");
      } else {
      }
    } catch (error) {}
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Referral Link copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };
  const getReward = async () => {
    var get = {
      apiUrl: apiService.getReward,
    };
    var response = await getMethod(get);
    console.log(response, "response");
    setTotalRewards(response.data.total_bonus);
    setTotalRefferal(response.data.totalRefCount);
  };
  const referralHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.referralHistory,
        payload: payload,
      };
      var resp = await postMethod(data);
      console.log(resp);
      if (resp.status) {
        setRefferHistoty(resp.data.data);
        console.log(resp.data.data);
        setTotalPages(resp.data.total);
      }
    } catch (error) {}
  };
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    referralHistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const [rewardHistory, setrewardHistory] = useState([]);
  const [totalPage_reward, setTotalpages_reward] = useState(0);
  const [currentPage_reward, setCurrentPage_reward] = useState(1);

  const recordPerPage_reward = 5;
  const pageRange_reward = 5;

  const get_rewardHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.earningHistory,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setrewardHistory(resp.data.data);
        setTotalpages_reward(resp.data.total);
       // setTotalRewards(resp.data.totalearning)
        
      }
    } catch (error) {}
  };

  const handlePageChange_reward = (pageNumber) => {
    get_rewardHistory(pageNumber);
    setCurrentPage_reward(pageNumber);
  };
  return (
    <div className="home contact fixed_header">
      <Header />
      <main>
        <section className="contact_us refral_sss content-wrapper pt-0">
          <div className="banner_referal">
            <div className="container">
              <div className="row justify-center">
                <div className="col-lg-8">
                  <div className="banner_title_conte">
                    <h1>Invite Friends and Earn Crypto Together</h1>
                    <p>
                      {" "}
                      Up to <span>50% </span>Trading Rebate
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-10">
                <div className="referal_card">
                  <h3 className="headinasdaaa">Default Referral</h3>
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="flex_colorma">
                        {" "}
                        <div className="card_content_section">
                         
                          <h2> {totalRefferal!=null &&totalRefferal!=undefined &&totalRefferal!=""?totalRefferal:0}</h2>

                          <p>Total Refarral</p>
                        </div>
                        <div className="card_content_section">
                          {/* <h2>{totalRewards.toFixed(6)}</h2> */}
                          <h2> {totalRewards!=null &&totalRewards!=undefined &&totalRewards!=""?parseFloat(totalRewards).toFixed(6):0.00}</h2>
                          <p>Total Rewards (USDT)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="flex_colorma">
                        <div className="refatd_link">
                          <p>Referral Link</p>

                          <div className="link_refer">
                            {refferance}
                            <span>
                              <i
                                class="fa fa-clone"
                                aria-hidden="true"
                                onClick={() => copy(refferance)}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </span>
                          </div>
                          <div className="link_social">
                            <FacebookShareButton url={refferance} title={title}>
                              <FacebookIcon size={20} round color="red" />
                            </FacebookShareButton>
                            <TwitterShareButton url={refferance} title={title}>
                              <TwitterIcon size={20} round />
                            </TwitterShareButton>
                            {/* <InstapaperShareButton
                              url={refferance}
                              title={title}
                            >
                              <InstapaperIcon size={20} round />
                            </InstapaperShareButton> */}
                            <LinkedinShareButton url={refferance} title={title}>
                              <LinkedinIcon size={20} round />
                            </LinkedinShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-center mt-5">
              <div className="col-lg-10">
                <div className="card mt-10px ">
                  <div className="card p-0 mt-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="Heading">
                          <h2>Referral History</h2>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive mt-4 history_table">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>S.No</th>

                                <th>Email</th>
                                <th>Username</th>
                                <th>Date</th>
                              </tr>
                            </thead>

                            <tbody>
                              {refferHistoty.length > 0 ? (
                                refferHistoty &&
                                refferHistoty.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>{item.email}</td>
                                      <td>{item.username}</td>
                                      <td>
                                        {moment(item.createdDate).format("lll")}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="4">
                                    <div className="norecode_found">
                                      <img
                                        src={require("../img/newimg/nofound.png")}
                                      />
                                      <span>No records found</span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {refferHistoty.length > 0 ? (
                          
                          <Pagination
                            itemClass="page-item" // add it for bootstrap 4
                            linkClass="page-link" // add it for bootstrap 4
                            activePage={currentPage}
                            itemsCountPerPage={recordPerPage}
                            totalItemsCount={totalPage}
                            pageRangeDisplayed={pageRange}
                            onChange={handlePageChange}
                          />
                          ):""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-center mt-5">
              <div className="col-lg-10">
                <div className="card mt-10px ">
                  <div className="card p-0 mt-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="Heading">
                          <h2>Earning History</h2>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive mt-4 history_table">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Amount</th>
                                <th>Currency</th>
                                <th>Type</th>
                                <th>From</th>
                                <th>Date / time</th>
                              </tr>
                            </thead>

                            <tbody>
                              {rewardHistory.length > 0 ? (
                                rewardHistory &&
                                rewardHistory.map((item, i) => {
                                  return (
                                    <tr>
                                      <td>{item.id}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.currency}</td>
                                      <td>{item.type}</td>
                                      <td>{item.from}</td>
                                      <td>{item.date}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="6">
                                    <div className="norecode_found">
                                      <img
                                        src={require("../img/newimg/nofound.png")}
                                      />
                                      <span>No records found</span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {rewardHistory.length > 0 ? (

                          <Pagination
                             itemClass="page-item" // add it for bootstrap 4
                             linkClass="page-link" // add it for bootstrap 4
                             activePage={currentPage_reward}
                             itemsCountPerPage={recordPerPage_reward}
                             totalItemsCount={totalPage_reward}
                             pageRangeDisplayed={pageRange_reward}
                             onChange={handlePageChange_reward}
                          />
                          ):""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
