import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link, useNavigate} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {removeAuthorization} from "../core/service/axios";
import {removeAuthToken} from "../core/lib/localStorage";
import Loader from "../Component/Loader";
import PhoneInput from "react-phone-number-input";

function Home() {
  const options = ["one", "two", "three"];
  const navigate = useNavigate();
  const [loaderButton, setloaderButton] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoader, setSiteLoader] = useState(false);

  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const initailValue = {
    email: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [mailValue, setmailValue] = useState(initailValue);

  const {email} = mailValue;
  const {oldpassword, password, confirmPassword} = formValue;
  const [emailValidate, setemailValidate] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] = useState(
    false
  );
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] = useState(
    false
  );
  const [details, setDetails, setDetailsref] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [mobileNumber, setMobileNumber] = useState(false);
  const [Email, setEmail] = useState("");
  const [validationnErr, setvalidationnErr] = useState("");
  const [mobileValidate, setmobileValidate, mobileValidateref] = useState(
    false
  );
  const [mobilevalue, setmobileValue, mobilevalueref] = useState();

  useEffect(() => {
    fetchTfaData();
    postProfile();
  }, [0]);

  const validate = async (values) => {
    const errors = {};
    if (values.oldpassword == "") {
      errors.oldpassword = "Old Password is required";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (values.password.length < 8 || values.password.length > 45) {
      setpasswordValidate(true);
      errors.password =
        "New Password is required and shouldnot below 8 above 45letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter alone digit character";
    } else if (values.password == values.oldpassword) {
      errors.password = "Old Password and New password should not be same";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = "Confirm password is a required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and confirm password does not match";
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
const [buttonStatus,setButtonstatus]= useState(false)
  const handleChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setFormValue(formData);
    validate(formData);
    if(  confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false){
        setButtonstatus(true)
      }else{
        setButtonstatus(false)

      }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast(resp.Message);
        await removeAuthorization();
        await removeAuthToken();
        localStorage.clear();
        navigate("/login");
        window.location.reload(true);
      } else {
        toast(resp.Message);
      }
    }
  };
  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      toast("2FA code is required");
    } else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      setloaderButton(true);
      var resp = await postMethod(data);
      if (resp.status) {
        setloaderButton(false);
        toast.success(resp.Message);
        setTfaCode("");
        fetchTfaData();
        postProfile();
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
        setloaderButton(false);
        toast.error(resp.Message);
      }
    }
  };

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      var resp = await getMethod(data);
      setTfaDetails(resp?.data.tfastatus);
      setaddDetails(resp?.data);
    } catch (error) {}
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const passwordHidenewP = (data) => {
    if (data == "hide") {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  const postProfile = async () => {
    try {
      setSiteLoader(true);
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setDetails(resp.data.registertype);
        console.log("registertpe", resp.data.registertype);
        setMobileNumber(resp.data.username);
        setEmail(resp.data.username);
        localStorage.setItem("eligibleforEarn", resp.data.kycstatus);
        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) {}
  };
  const mobileNumbersubmit = async () => {
    const errors = {};
    formValue["mobile"] = mobilevalueref.current;
    if (!mobilevalueref.current) {
      errors.mobile = "Mobile Number is a required field";
      setmobileValidate(true);
    }
    setvalidationnErr(errors);

    if (formValue.mobile != "") {
      var obj = {
        Mobile: formValue.mobile,
        Email: Email,
      };
      var data = {
        apiUrl: apiService.mobilenumberupdate,
        payload: obj,
      };
      var resp = await postMethod(data);
      toast(resp.Message);
    } else {
    }
  };
  const emailChange = async (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...mailValue, ...{[name]: value}};
    setmailValue(formData);
  };
  const emailSubmit = async () => {
    const errors = {};
    if (mailValue.email == "") {
      errors.email = "Email is a required field";
      setemailValidate(true);
    }
    setvalidationnErr(errors);
    if (mailValue.email != "") {
      var obj = {
        Email: mailValue.email,
        Mobilenumber: mobileNumber,
      };
      var data = {
        apiUrl: apiService.emailupdate,
        payload: obj,
      };
      var resp = await postMethod(data);
      toast(resp.Message);
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Secret Key Copied");
  };
  
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            {siteLoader == true ? (
              <Loader />
            ) : (
              <div className="content-wrapper">
                <div className="container contaier_padine">
                  <div className="card add_padin_card ml-0 mr-0">
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <h1 className="bard_tirlrr">Account security</h1>
                      </div>
                    </div>
                  </div>
                  <div className="card m-0 table_card_new ">
                    <div className="history_tabs">
                      <ul class="nav nav-tabs">
                        <li class="active">
                          <a
                            data-toggle="tab"
                            href="#towFA_Authentication"
                            className="active"
                          >
                            2FA Authentication
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#Change_Password">
                            Change Password
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="tab-content">
                      <div
                        id="towFA_Authentication"
                        class="tab-pane fade in active show"
                      >
                        <div className="card p-0 mt-0 card_security">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="Heading">
                                <h2>2FA Authentication</h2>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="enable_2fa_secf">
                                <div className="enable_sectio">
                                  {tfaDetails === 0 ? (
                                    <h3>Enable 2FA Authentication</h3>
                                  ) : (
                                    <h3>Disable 2FA Authentication</h3>
                                  )}
                                  <span className="note">
                                    Protect your transactions and account
                                    security
                                  </span>
                                </div>
                                <div className="buttM_">
                                  {tfaDetails === 0 ? (
                                    <Button>Disable</Button>
                                  ) : (
                                    <Button>Enable</Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="enable_2fa_secf justify-content-center">
                                <div className="enable_sectio mr-4">
                                  {tfaDetails === 0 ? (
                                    <div className="mt-4">
                                      <span className="note mb-3 d-block">
                                        Scan QR
                                      </span>
                                      <img
                                        src={addDetails?.tfa_url}
                                        className="img-fluid mx-auto"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                              {tfaDetails === 0 ? (
                              <div className="form_accout form_flex copyicon">
                                <label>Secret Key</label>
                                <div className="copy_icon_deposi">
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    value={addDetails?.tfaenablekey==undefined?"":addDetails?.tfaenablekey}
                                    readOnly
                                    className="tfa_key"
                                  />
                                    <span
                                      onClick={() =>
                                        copy(addDetails?.tfaenablekey)
                                      }
                                    >
                                      <i class="bi bi-clipboard-check"></i>
                                    </span>
                                </div>
                              </div>
                               ) : (
                                ""
                              )}

                                  {tfaDetails === 0 ? (
                                    <h3>Enable 2FA </h3>
                                  ) : (
                                    <h3>Disable 2FA </h3>
                                  )}
                                  <span className="note">2FA Code</span>
                                  <div className="w2faform">
                                    <input
                                      type="number"
                                      min="0"
                                      placeholder="Enter Your Code"
                                      name="tfaCode"
                                      value={tfaCode}
                                      onChange={(e) =>
                                        setTfaCode(e.target.value)
                                      }
                                    />
                                    {tfaDetails === 0 ? (
                                      loaderButton == false ? (
                                        <Button onClick={handleSubmit}>
                                          Enable
                                        </Button>
                                      ) : (
                                        <Button>loading...</Button>
                                      )
                                    ) : loaderButton == false ? (
                                      <Button onClick={handleSubmit}>
                                        Disable
                                      </Button>
                                    ) : (
                                      <Button>loading...</Button>
                                    )}
                                  </div>
                                </div>
                                <div className="enable_sectio"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Change_Password" class="tab-pane fade">
                        <div className="card p-0 mt-0 modify_card">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="Heading">
                                <h2 className="headei_a">
                                  Modify login password
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form_chaon_pass">
                                <form>
                                  <div className="form-group">
                                    <label>Old Password</label>
                                    <div className="postion_reletitt">
                                      <input
                                        type={inputType}
                                        name="oldpassword"
                                        placeholder="Old Password"
                                        className="form-control"
                                        maxLength={40}
                                        value={oldpassword}
                                        onChange={handleChange}
                                      />
                                      <div className="input-group-addon">
                                        {passHide == false ? (
                                          <i
                                            className="bi bi-eye-slash-fill"
                                            onClick={() => passwordHide("hide")}
                                          ></i>
                                        ) : (
                                          <i
                                            className="bi bi-eye-fill"
                                            onClick={() => passwordHide("show")}
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                    {oldpassvalidate == true ? (
                                      <small>
                                        {validationnErr.oldpassword}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label>New Password</label>
                                    <div className="postion_reletitt">
                                      <input
                                        type={inputTypenew}
                                        name="password"
                                        maxLength={40}
                                        placeholder="New Password"
                                        className="form-control"
                                        value={password}
                                        onChange={handleChange}
                                      />
                                      <div className="input-group-addon">
                                        {passHidnew == false ? (
                                          <i
                                            className="bi bi-eye-slash-fill"
                                            onClick={() =>
                                              passwordHidenewP("hide")
                                            }
                                          ></i>
                                        ) : (
                                          <i
                                            className="bi bi-eye-fill"
                                            onClick={() =>
                                              passwordHidenewP("show")
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                    {passwordValidate == true ? (
                                      <small> {validationnErr.password} </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label>Confirm Password</label>
                                    <div className="postion_reletitt">
                                      <input
                                        type={inputTypeconf}
                                        name="confirmPassword"
                                        maxLength={40}
                                        placeholder="Confirm Password"
                                        className="form-control"
                                        value={confirmPassword}
                                        onChange={handleChange}
                                      />
                                      <div className="input-group-addon">
                                        {passHidconf == false ? (
                                          <i
                                            className="bi bi-eye-slash-fill"
                                            onClick={() =>
                                              passwordHideconf("hide")
                                            }
                                          ></i>
                                        ) : (
                                          <i
                                            className="bi bi-eye-fill"
                                            onClick={() =>
                                              passwordHideconf("show")
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                    {confirmPasswordValidate == true ? (
                                      <small>
                                        {" "}
                                        {validationnErr.confirmPassword}{" "}
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="submit_i">
                                    {buttonStatus==true ?
                                     buttonLoader == false ? (
                                      <Button onClick={formSubmit}>
                                        Submit
                                      </Button>
                                    ) : (
                                      <Button>loading...</Button>
                                    )
                                    :
                                    ""}
                                   
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <Pagination
                      itemClass="page-item" // add it for bootstrap 4
                      linkClass="page-link" // add it for bootstrap 4
                      activePage={TFacurrentpage}
                      itemsCountPerPage={TFarecordpage}
                      totalItemsCount={TFatotalpage}
                      pageRangeDisplayed={TFapagerange}
                      onChange={TFahistorychanges}
                    /> */}
                      </div>
                      <div id="Security" class="tab-pane fade">
                        <div className="card p-0 mt-0">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="Heading">
                                <h2 className="headei_a">Security</h2>
                              </div>
                            </div>
                          </div>
                          {setDetailsref.current == "Email" ? (
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form_chaon_pass">
                                  <form>
                                    <div className="form-group">
                                      <label>Email</label>
                                      <div className="postion_reletitt">
                                        <input
                                          type="email"
                                          name="email"
                                          placeholder="email"
                                          className="form-control"
                                          value={Email}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <PhoneInput
                                        country="US"
                                        placeholder="Enter phone number"
                                        value={mobilevalue}
                                        onChange={setmobileValue}
                                      />
                                      <div>
                                        {mobileValidate == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {validationnErr.mobile}{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div className="submit_i">
                                      {buttonLoader == false ? (
                                        <Button onClick={mobileNumbersubmit}>
                                          Submit
                                        </Button>
                                      ) : (
                                        <Button>loading...</Button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form_chaon_pass">
                                  <form>
                                    <div className="form-group">
                                      <label>MobileNumber</label>
                                      <div className="postion_reletitt">
                                        <input
                                          type="MobileNumber"
                                          name="MobileNumber"
                                          placeholder="MobileNumber"
                                          className="form-control"
                                          value={mobileNumber}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label>Email</label>
                                      <div className="postion_reletitt">
                                        <input
                                          type="email"
                                          name="email"
                                          className="form-control"
                                          placeholder="email"
                                          value={email}
                                          onChange={emailChange}
                                        />
                                      </div>
                                      <div>
                                        {emailValidate == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {validationnErr.email}{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div className="submit_i">
                                      {buttonLoader == false ? (
                                        <Button onClick={emailSubmit}>
                                          Submit
                                        </Button>
                                      ) : (
                                        <Button>loading...</Button>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
