import React, { useState } from "react";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Sideheader from "./Sidebarheader";
import Bankdetails from "./Bankdetails";
import Newsideheader from "./Newsideheader";
import { Button } from "semantic-ui-react";
import useStateref from "react-usestateref";
import apiService from "../core/service/v2/index";
import { postMethod } from "../core/service/common.api2";
import { toast } from "react-toastify";
import { env } from "../core/service/envconfig";

function ApplyLaunchpadjs() {
    const options = ["one", "two", "three"];

    const values = {
        FullName: "",
        Email: "",
        Summary: "",
        TokenName: "",
        TokenSymbol: "",
        TokenNetwork: "",
        TokenDecimals: "",
        TokenAddress: "",
        TotalSupply: "",
        TokensForLaunchpool: "",
        StakeRateinusd: "",
        InitialMarketCap: "",
        SoftCap: "",
        HardCap: "",
        LaunchpoolStartTime: "",
        LaunchpoolEndTime: "",
        ListingOn: "",
        RewardPercent: "",
        LiquidityLockupTime: "",
        tokenomics: "",
        Twitter: "",
        Telegram: "",
        Facebook: "",
        Instagram: "",
        WebsiteLink: "",
    }

    const [inputValues, setinputValues] = useState(values)
    const [FullNamerr, setFullNamerr] = useState(false)
    const [Emailrr, setEmailrr] = useState(false)
    const [Whitepaperrr, setWhitepaperrr] = useState(false)
    const [Summaryrr, setSummaryrr] = useState(false)
    const [TokenNamerr, setTokenNamerr] = useState(false)
    const [TokenSymbolrr, setTokenSymbolrr] = useState(false)
    const [TokenNetworkrr, setTokenNetworkrr] = useState(false)
    const [TokenDecimalsrr, setTokenDecimalsrr] = useState(false)
    const [TokenAddressrr, setTokenAddressrr] = useState(false)
    const [TotalSupplyrr, setTotalSupplyrr] = useState(false)
    const [TokensForLaunchpoolrr, setTokensForLaunchpoolrr] = useState(false)
    const [StakeRateinusdrr, setStakeRateinusdrr] = useState(false)
    const [InitialMarketCaprr, setInitialMarketCaprr] = useState(false)
    const [SoftCaprr, setSoftCaprr] = useState(false)
    const [HardCaprr, setHardCaprr] = useState(false)
    const [LaunchpoolStartTimerr, setLaunchpoolStartTimerr] = useState(false)
    const [LaunchpoolEndTimerr, setLaunchpoolEndTimerr] = useState(false)
    const [ListingOnrr, setListingOnrr] = useState(false)
    const [RewardPercentrr, setRewardPercentrr] = useState(false)
    const [LiquidityLockupTimerr, setLiquidityLockupTimerr] = useState(false)
    const [tokenomicsrr, settokenomicsrr] = useState(false)
    const [Teamandroadmaprr, setTeamandroadmaprr] = useState(false)
    const [Twitterrr, setTwitterrr] = useState(false)
    const [Telegramrr, setTelegramrr] = useState(false)
    const [Facebookrr, setFacebookrr] = useState(false)
    const [Instagramrr, setInstagramrr] = useState(false)
    const [Websitelinkrr, setWebsitelinkrr] = useState(false)
    const [InitialCirculatingsupplyrr, setInitialCirculatingsupplyrr] = useState(false)
    const [validerr, setvaliderr] = useState("")
    const [teamandroadmapupload, setteamandroadmapupload] = useState("")
    const [Whitepaperupload, setWhitepaperupload] = useState("")

    const getValue = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...inputValues, ...{ [name]: value } };
        setinputValues(formData);
    };

    const imageUpload = (type, val) => {
        try {
            const fileExtension = val.name.split(".").at(-1);
            const fileSize = val.size;
            const fileName = val.name;
            if (
                fileExtension != "png" &&
                fileExtension != "jpg" &&
                fileExtension != "jpeg" &&
                fileExtension != "pdf" &&
                fileExtension != "doc"
            ) {
                toast.error(
                    "File does not support. You must use .png or .jpg or .jpeg or .pdf or .doc "
                );
            } else if (fileSize > 10000000) {
                toast.error("Please upload a file smaller than 1 MB");
            } else {
                const data = new FormData();
                data.append("file", val);
                data.append("upload_preset", env.upload_preset);
                data.append("cloud_name", env.cloud_name);
                fetch(
                    "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
                    { method: "post", body: data }
                )
                    .then((resp) => resp.json())
                    .then((data) => {
                        console.log("cloudinary upload===", data);
                        if (type == "Teamandroadmap") {
                            setteamandroadmapupload(data.secure_url);
                        }
                        if (type == "Whitepaper") {
                            setWhitepaperupload(data.secure_url)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Please try again later");
                    });
            }
        } catch (error) {
            toast.error("Please try again later");
        }
    };

    const onSubmit = async () => {
        validate();
        if (inputValues.FullName != "" && inputValues.TokenNetwork != "" && inputValues.InitialMarketCap != "" && inputValues.ListingOn != "" && inputValues.Twitter != "" && inputValues.WebsiteLink != "" &&
            inputValues.Email != "" && inputValues.TokenAddress != "" && inputValues.SoftCap != "" && inputValues.RewardPercent != "" && inputValues.Telegram != "" && inputValues.TokenSymbol != "" &&
            inputValues.Summary != "" && inputValues.TotalSupply != "" && inputValues.HardCap != "" && inputValues.LiquidityLockupTime != "" && inputValues.Facebook != "" &&
            inputValues.TokenName != "" && inputValues.TokensForLaunchpool != "" && inputValues.LaunchpoolStartTime != "" && inputValues.tokenomics != "" && inputValues.Instagram != "" &&
            inputValues.TokenDecimals != "" && inputValues.StakeRateinusd != "" && inputValues.LaunchpoolEndTime != "") {
            inputValues["Whitepaper"] = Whitepaperupload
            inputValues["Teamandroadmap"] = teamandroadmapupload
            var data = {
                apiUrl: apiService.applyLaunchpad,
                payload: inputValues,
            };
            var resp = await postMethod(data);
            if (resp.Status == true) {
                var resdata = {
                    FullName : "",
                    Email : "",
                    Summary : "",
                    TokenName : "",
                    TokenSymbol : "",
                    TokenNetwork : "",
                    TokenDecimals : "",
                    TokenAddress : "",
                    TotalSupply : "",
                    TokensForLaunchpool : "",
                    StakeRateinusd : "",
                    InitialMarketCap : "",
                    SoftCap : "",
                    HardCap : "",
                    LaunchpoolStartTime : "",
                    LaunchpoolEndTime : "",
                    ListingOn : "",
                    RewardPercent : "",
                    LiquidityLockupTime : "",
                    tokenomics : "",
                    Twitter : "",
                    Telegram : "",
                    Facebook : "",
                    Instagram : "",
                    WebsiteLink : ""
                }
                setinputValues(resdata)
            }
        }
    }

    const validate = () => {
        const error = {}
        if (teamandroadmapupload == "") {
            error.Teamandroadmap = "Team and Roadmap is required field"
            setTeamandroadmaprr(true)
        }
        if (inputValues.FullName == "") {
            error.FullName = "Full Name is required field"
            setFullNamerr(true)
        }
        if (inputValues.Email == "") {
            error.Email = "Email is required field"
            setEmailrr(true)
        }
        if (Whitepaperupload == "") {
            error.Whitepaper = "Whitepaper is required field"
            setWhitepaperrr(true)
        }
        if (inputValues.Summary == "") {
            error.Summary = "Summary is required field"
            setSummaryrr(true)
        }
        if (inputValues.TokenName == "") {
            error.TokenName = "Token Name is required field"
            setTokenNamerr(true)
        }
        if (inputValues.TokenSymbol == "") {
            error.TokenSymbol = "Token Symbol is required field"
            setTokenSymbolrr(true)
        }
        if (inputValues.TokenNetwork == "") {
            error.TokenNetwork = "Token Network is required field"
            setTokenNetworkrr(true)
        }
        if (inputValues.TokenDecimals == "") {
            error.TokenDecimals = "Token Decimals is required field"
            setTokenDecimalsrr(true)
        }
        if (inputValues.ListingOn == "") {
            error.ListingOn = "ListingOn is required field"
            setListingOnrr(true)
        }
        if (inputValues.TokenAddress == "") {
            error.TokenAddress = "TokenAddress is required field"
            setTokenAddressrr(true)
        }
        if (inputValues.TotalSupply == "") {
            error.TotalSupply = "TotalSupply is required field"
            setTotalSupplyrr(true)
        }
        if (inputValues.TokensForLaunchpool == "") {
            error.TokensForLaunchpool = "Tokens For Launchpool is required field"
            setTokensForLaunchpoolrr(true)
        }
        if (inputValues.StakeRateinusd == "") {
            error.StakeRateinusd = "Stake Ratein (usd) is required field"
            setStakeRateinusdrr(true)
        }
        if (inputValues.InitialMarketCap == "") {
            error.InitialMarketCap = "Initial MarketCap is required field"
            setInitialMarketCaprr(true)
        }
        if (inputValues.SoftCap == "") {
            error.SoftCap = "SoftCap is required field"
            setSoftCaprr(true)
        }
        if (inputValues.HardCap == "") {
            error.HardCap = "HardCap is required field"
            setHardCaprr(true)
        }
        if (inputValues.LaunchpoolStartTime == "") {
            error.LaunchpoolStartTime = "Launchpool StartTime is required field"
            setLaunchpoolStartTimerr(true)
        }
        if (inputValues.LaunchpoolEndTime == "") {
            error.LaunchpoolEndTime = "Launchpool EndTime is required field"
            setLaunchpoolEndTimerr(true)
        }
        if (inputValues.RewardPercent == "") {
            error.RewardPercent = "Reward Percent is required field"
            setRewardPercentrr(true)
        }
        if (inputValues.LiquidityLockupTime == "") {
            error.LiquidityLockupTime = "Liquidity LockupTime is required field"
            setLiquidityLockupTimerr(true)
        }
        if (inputValues.tokenomics == "") {
            error.tokenomics = "tokenomics is required field"
            settokenomicsrr(true)
        }
        if (inputValues.Twitter == "") {
            error.Twitter = "Twitter is required field"
            setTwitterrr(true)
        }
        if (inputValues.FullName == "") {
            error.FullName = "Fullname is required field"
            setFullNamerr(true)
        }
        if (inputValues.Telegram == "") {
            error.Telegram = "Telegram is required field"
            setTelegramrr(true)
        }
        if (inputValues.Facebook == "") {
            error.Facebook = "Facebook is required field"
            setFacebookrr(true)
        }
        if (inputValues.Instagram == "") {
            error.Instagram = "Instagram is required field"
            setInstagramrr(true)
        }
        if (inputValues.WebsiteLink == "") {
            error.WebsiteLink = "WebsiteLink is required field"
            setWebsitelinkrr(true)
        }

        setvaliderr(error)
    }




    return (
        <div>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                    <Sideheader />
                    <div className="main-panel">
                        <div>
                            <Newsideheader />
                        </div>
                        <div className="content-wrapper">
                            <div className=" padin_zero">
                                <div className="card add_padin_card">
                                    <div className="row">
                                        <div className="col-lg-12 p-0">
                                            <h1 className="bard_tirlrr">Apply Launchpad</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="container d-flex justify-content-center flexnew_center caed_padind_00">
                                    <div className="col-lg-12 padin_zero">
                                        <div className="staking_title launcpad d-block fonrm_card_s need_meanissss">
                                            <div class="row">
                                                <div className="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <label>FullName</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter FullName"
                                                            name="FullName"
                                                        />
                                                        {FullNamerr == true ? <p style={{ color: "red" }}>{validerr.FullName}</p> : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6  col-sm-6">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Email"
                                                            name="Email"
                                                        />
                                                        {Emailrr == true ? <p style={{ color: "red" }}>{validerr.Email}</p> : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Whitepaper</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                imageUpload(
                                                                    "Whitepaper",
                                                                    e.target.files[0]
                                                                )}
                                                            placeholder="Enter Whitepaper"
                                                            name="Whitepaper"
                                                        />
                                                        {Whitepaperrr == true ? <p style={{ color: "red" }}>{validerr.Whitepaper}</p> : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Summary</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Summary"
                                                            name="Summary"
                                                        />
                                                        {Summaryrr == true ? <p style={{ color: "red" }}>{validerr.Summary}</p> : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Token Name</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Token Name"
                                                            name="TokenName"
                                                        />
                                                        {TokenNamerr == true ? <p style={{ color: "red" }}>{validerr.TokenName}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Token Network</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Token Network"
                                                            name="TokenNetwork"
                                                        />
                                                        {TokenNetworkrr == true ? <p style={{ color: "red" }}>{validerr.TokenNetwork}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Token Address</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Token Address"
                                                            name="TokenAddress"
                                                        />
                                                        {TokenAddressrr == true ? <p style={{ color: "red" }}>{validerr.TokenAddress}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Stake Rate in usd</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Stake Rate in usd"
                                                            name="StakeRateinusd"
                                                        />
                                                        {StakeRateinusdrr == true ? <p style={{ color: "red" }}>{validerr.StakeRateinusd}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Hard Cap</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Hard Cap"
                                                            name="HardCap"
                                                        />
                                                        {HardCaprr == true ? <p style={{ color: "red" }}>{validerr.HardCap}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Launchpool Start Time</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Launchpool Start Time"
                                                            name="LaunchpoolStartTime"
                                                        />
                                                        {LaunchpoolStartTimerr == true ? <p style={{ color: "red" }}>{validerr.LaunchpoolStartTime}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Tokens For Launchpool</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Tokens For Launchpool"
                                                            name="TokensForLaunchpool"
                                                        />
                                                        {TokensForLaunchpoolrr == true ? <p style={{ color: "red" }}>{validerr.TokensForLaunchpool}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Initial Market Cap (estimate)</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Initial Market Cap (estimate)"
                                                            name="InitialMarketCap"
                                                        />
                                                        {InitialMarketCaprr == true ? <p style={{ color: "red" }}>{validerr.InitialMarketCap}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Reward Percent</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Reward Percent"
                                                            name="RewardPercent"
                                                        />
                                                        {RewardPercentrr == true ? <p style={{ color: "red" }}>{validerr.RewardPercent}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Twitter Link</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Twitter Link"
                                                            name="Twitter"
                                                        />
                                                        {Twitterrr == true ? <p style={{ color: "red" }}>{validerr.Twitter}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Telegram links</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Telegram links"
                                                            name="Telegram"
                                                        />
                                                        {Telegramrr == true ? <p style={{ color: "red" }}>{validerr.Telegram}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Website Link</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Website Link"
                                                            name="WebsiteLink"
                                                        />
                                                        {Websitelinkrr == true ? <p style={{ color: "red" }}>{validerr.WebsiteLink}</p> : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Token Symbol</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Token Symbol"
                                                            name="TokenSymbol"
                                                        />
                                                        {TokenSymbolrr == true ? <p style={{ color: "red" }}>{validerr.TokenSymbol}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Token Decimals</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Token Decimals"
                                                            name="TokenDecimals"
                                                        />
                                                        {TokenDecimalsrr == true ? <p style={{ color: "red" }}>{validerr.TokenDecimals}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Total Supply</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Total Supply"
                                                            name="TotalSupply"
                                                        />
                                                        {TotalSupplyrr == true ? <p style={{ color: "red" }}>{validerr.TotalSupply}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Listing On</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Listing On"
                                                            name="ListingOn"
                                                        />
                                                        {ListingOnrr == true ? <p style={{ color: "red" }}>{validerr.ListingOn}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Soft Cap</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Soft Cap"
                                                            name="SoftCap"
                                                        />
                                                        {SoftCaprr == true ? <p style={{ color: "red" }}>{validerr.SoftCap}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Launchpool End Time</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Launchpool End Time"
                                                            name="LaunchpoolEndTime"
                                                        />
                                                        {LaunchpoolEndTimerr == true ? <p style={{ color: "red" }}>{validerr.LaunchpoolEndTime}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Tokenomics (Ticker name)</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter tokenomics"
                                                            name="tokenomics"
                                                        />
                                                        {tokenomicsrr == true ? <p style={{ color: "red" }}>{validerr.tokenomics}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Team and roadmap</label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(e) =>
                                                                imageUpload(
                                                                    "Teamandroadmap",
                                                                    e.target.files[0]
                                                                )}
                                                            placeholder="Enter Team and roadmap"
                                                            name="Teamandroadmap"
                                                        />
                                                        {Teamandroadmaprr == true ? <p style={{ color: "red" }}>{validerr.Teamandroadmap}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Liquidity Lockup Time</label>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Liquidity Lockup Time"
                                                            name="LiquidityLockupTime"
                                                        />
                                                        {LiquidityLockupTimerr == true ? <p style={{ color: "red" }}>{validerr.LiquidityLockupTime}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Facebook Link</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Facebook Link"
                                                            name="Facebook"
                                                        />
                                                        {Facebookrr == true ? <p style={{ color: "red" }}>{validerr.Facebook}</p> : ""}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Instagram Link</label>
                                                        <input
                                                            className="form-control"
                                                            onChange={getValue}
                                                            placeholder="Enter Instagram Link"
                                                            name="Instagram"
                                                        />
                                                        {Instagramrr == true ? <p style={{ color: "red" }}>{validerr.Instagram}</p> : ""}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="submit_butn_s">
                                                        <Button onClick={onSubmit} >Submit</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplyLaunchpadjs

