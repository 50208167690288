import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Count from "./Countdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import useState from "react-usestateref";
import P2homePost from "./P2homePost";
import Newsideheader from "./Newsideheader";
import Undermaintain from "./Undermaintain";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { toast } from "react-toastify";
import apiService from "../core/service/v2/index";
import { postMethod, getMethod } from "../core/service/common.api2";
function Home() {
  const options = ["one", "two", "three"];
  const paymentMethod = [
    {
      key: "All Payments",
      text: "All Payments",
      value: "All Payments",
    },
    {
      key: "Bank Transfer",
      text: "Bank Transfer",
      value: "Bank Transfer",
    },
    {
      key: "UPI ID",
      text: "UPI ID",
      value: "UPI ID",
    },
    {
      key: "Pay TM",
      text: "Pay TM",
      value: "Pay TM",
    },
  ];
  const timelimit = [
    {
      key: "15 Minutes",
      text: "15 Minutes",
      value: "15 Minutes",
    },
    {
      key: "30 Minutes",
      text: "30 Minutes",
      value: "30 Minutes",
    },
    {
      key: "45 Minutes",
      text: "45 Minutes",
      value: "45 Minutes",
    },
    {
      key: "1 Minutes",
      text: "1 Minutes",
      value: "1 Minutes",
    },
    {
      key: "2 Minutes",
      text: "2 Minutes",
      value: "2 Minutes",
    },
    {
      key: "3 Minutes",
      text: "3 Minutes",
      value: "3 Minutes",
    },
    {
      key: "4 Minutes",
      text: "4 Minutes",
      value: "4 Minutes",
    },
  ];
  const friendOptions = [
    {
      key: "Jenny Hess",
      text: "Jenny Hess",
      value: "Jenny Hess",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Elliot Fu",
      text: "Elliot Fu",
      value: "Elliot Fu",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Stevie Feliciano",
      text: "Stevie Feliciano",
      value: "Stevie Feliciano",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Christian",
      text: "Christian",
      value: "Christian",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Matt",
      text: "Matt",
      value: "Matt",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Justen Kitsune",
      text: "Justen Kitsune",
      value: "Justen Kitsune",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
  ];

  const initialFormValue = {
    orderlimitstart: "",
    orderlimitend: "",
    terms:"",
    autoreply:""
  };

  const [p2p_currencies, setp2p_currencies] = useState();
  const [fiat_currencies, set_fiat_currencies] = useState();
  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromcurrencyprice, setfromcurrencyprice, setfromcurrencypriceref] =
    useState();
  const [tocurrencyprice, settocurrencyprice, settocurrencypriceref] =
    useState();
  const [currentmarketprice, setcurrentmarketprice] = useState(0);
  const [count, setCount] = useState(0);
  const [startcount, setstartcount] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [orderlimitstartErr, setorderlimitstartErr] = useState(false);
  const [orderlimitendErr, setorderlimitendErr] = useState(false);
  const { orderlimitstart, orderlimitend ,terms,autoreply} = formValue;
  const [tabOne, settabOne] = useState("active");
  const [tabTwo, settabTwo] = useState("");
  const [tabThree, settabThree] = useState("");
  const [activeTab, setactiveTab] = useState("settype&pricetab");
  const [preferPay, setpreferPay] = useState("All payments");
  const [preferPaymentlimit, setpreferPaymentlimit] = useState("15 Minutes");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [kycstatus, setkycstatus] = useState(0);
  const [vallidation, setvallidation] = useState();

  useEffect(() => {
    get_p2p_active_currency();
    getKycData()
  }, [0]);

  const get_p2p_active_currency = async () => {
    try {
      var data = {
        apiUrl: apiService.get_p2p_active_currency,
      };
      var resp = await getMethod(data);
      console.log(resp.Message.get_p2p_fiat_currency, "resp");
      setFromTab(resp.Message.get_p2p_active_currency);
      var currArray = [{ value: "all", label: "" }];
      var data = resp.Message.get_p2p_active_currency;
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          key: data[i].currencySymbol,
          text: data[i].currencySymbol,
          image: {
            avatar: true,
            src: data[i].Currency_image,
          },
        };
        currArray.push(obj);
      }
      setp2p_currencies(currArray);

      var currArrayFiat = [{ value: "all", label: "" }];
      var fiatcurrency = resp.Message.get_p2p_fiat_currency;
      setToTab(resp.Message.get_p2p_fiat_currency);
      for (var i = 0; i < fiatcurrency.length; i++) {
        var obj = {
          value: fiatcurrency[i]._id,
          key: fiatcurrency[i].currencySymbol,
          text: fiatcurrency[i].currencySymbol,
          image: {
            avatar: true,
            src: fiatcurrency[i].Currency_image,
          },
        };
        console.log(obj, "objobj");
        currArrayFiat.push(obj);
      }
      set_fiat_currencies(currArrayFiat);
      console.log(currArrayFiat, "currArrayFiat--=-=");
    } catch (error) {}
  };
  const handleOnChange_from = (e, data) => {
    setstartcount(false);
    console.log("handleOnChange_from", data.value);
    var findIndexing = fromTab.findIndex((x) => x._id == data.value);
    if (findIndexing != -1) {
      setfromcurrencyprice(fromTab[findIndexing].currencySymbol);
    }
    price();
  };

  const handleOnChange_to = (e, data) => {
    setstartcount(false);
    console.log("handleOnChange_to", data.value);
    var findIndexingTo = toTab.findIndex((x) => x._id == data.value);
    if (findIndexingTo != -1) {
      settocurrencyprice(toTab[findIndexingTo].currencySymbol);
      console.log(toTab[findIndexingTo].currencySymbol);
    }
    price();
  };

  const price = async () => {
    try {
      var obj = {
        from:
          setfromcurrencypriceref.current != undefined
            ? setfromcurrencypriceref.current
            : "BTC",
        to:
          settocurrencypriceref.current != undefined
            ? settocurrencypriceref.current
            : "INR",
      };
      console.log(obj, "obj=-=-=obj");

      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      var resp = await postMethod(data);
      console.log(resp.Message);
      if (resp.status) {
        setcurrentmarketprice(resp.Message);
      }
      console.log(
        currentmarketprice,
        "currentmarketprice=-=-=currentmarketprice=--=currentmarketprice"
      );
    } catch (error) {}
  };
  const Increment = () => {
    setstartcount(true);
    setcurrentmarketprice(currentmarketprice + 1);
    console.log(currentmarketprice);
  };
  const Decrement = () => {
    setstartcount(true);
    setcurrentmarketprice(currentmarketprice - 1);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };



  const vallidate = async () => {
    try {
      console.log("oioioioioi")
      const error = {};
      if (formValue.orderlimitstart) {
        setorderlimitstartErr(true);
        error.orderlimitstart = "Must Enter Field";
      } else {
        setorderlimitstartErr(true);
      }
      if (formValue.orderlimitend) {
        setorderlimitendErr(true);
        error.orderlimitend = "Must Enter Field";
      } else {
        setorderlimitendErr(true);
      }
      setvallidation(error)
    } catch {}
  };

  const preferPayment = async (option) => {
    console.log(option.target.innerText, "-=-prferPayments");
    setpreferPay(option.target.innerText);
  };

  const paymentTimeLimit = async (option) => {
    console.log(option.target.innerText, "-=-prferPayments");
    setpreferPaymentlimit(option.target.innerText);
  };
  const steps = async (count) => {
    try {
      formValue["fromCurrency"] = fromcurrencyprice;
      formValue["toCurrency"] = tocurrencyprice;
      formValue["Price"] = currentmarketprice;
      console.log(formValue, "formvalue=-=-formvalue");
      if (count == "one") {
        if (
          formValue.fromCurrency != undefined &&
          formValue.toCurrency != undefined
        ) {
          settabTwo("active");
          setactiveTab("paymentmethodtab");
        } else {
          toast.error("Choose your currency");
        }
      }
      formValue["preferpayment"] = preferPay;
      formValue["paymentimelimit"] = preferPaymentlimit;
      if (count == "two") {
        vallidate(formValue)
        if (formValue.preferpayment != undefined &&
          formValue.orderlimitend !="" &&
          formValue.orderlimitstart !="" ) {
          settabThree("active");
          setactiveTab("remarktab");
        }else{
          toast.error("Please fill all fields");

        }
      }
    } catch {}
  };
  const getKycData = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        var responseData = resp.data.kycstatus;
        setkycstatus(responseData);
      } else {
        var responseData = 0;
        setkycstatus(responseData);
      }
    } catch (error) {
      var responseData = 0;
      setkycstatus(responseData);

    }
  };
const submitPost = (type) =>{
  try{
 if(kycstatus ==1){
  vallidate(formValue)
 }else if(
  formValue.fromCurrency != undefined &&
  formValue.toCurrency != undefined &&
  formValue.preferpayment != undefined &&
  formValue.orderlimitend =="" &&
  formValue.orderlimitstart =="" 
 ){
  formValue["fromCurrency"] = fromcurrencyprice;
  formValue["toCurrency"] = tocurrencyprice;
  formValue["Price"] = currentmarketprice;
  formValue["preferpayment"] = preferPay;
  formValue["paymentimelimit"] = preferPaymentlimit;
  formValue["ordertype"] = type;
  
 }
 console.log(formValue,"formvalue=-=-=-=formvalue=-=-=formvalue")
  }catch{

  }
}
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contante">
                        <h1 className="text-left">Post Normal Ad</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="row">
                    {" "}
                    ;
                    <div className="col-lg-12">
                      <div className="processs_step">
                        <div class="step2 active">
                          <h2 class={tabOne} id="settype&pricetab">
                            1
                          </h2>
                          <p>Set Type & Price</p>
                        </div>
                        <div class="step2">
                          <h2 class={tabTwo} id="paymentmethodtab">
                            2
                          </h2>
                          <p>Set Total Amount & Payment Method</p>
                        </div>
                        <div class="step2">
                          <h2 class={tabThree} id="remarktab">
                            {" "}
                            3
                          </h2>
                          <p>Set Remark & Automatic Response</p>
                        </div>
                      </div>
                      <div className="tabs_section_post_ad history_tabs mt-4">
                        <ul class="nav nav-tabs">
                          <li class="active">
                            <a
                              data-toggle="tab"
                              href="#home"
                              className="active"
                            >
                              I want to Buy
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#menu1">
                              I want to Sell
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content pt-4">
                          <div id="home" class="tab-pane fade in active show">
                            {activeTab == "settype&pricetab" ? (
                              <div>
                                <div className="submit_form_post">
                                  <div className="select_form">
                                    <div className="select_iocn">
                                      <label>Asset</label>
                                      <Dropdown
                                        placeholder="Select currency"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={p2p_currencies}
                                        onChange={handleOnChange_from}
                                      />
                                    </div>
                                    <div className="select_iocn clmariduke">
                                      <i class="ri-arrow-right-line"></i>
                                    </div>
                                    <div className="select_iocn">
                                      <label>With Fiat</label>
                                      <Dropdown
                                        placeholder="Select Friend"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={fiat_currencies}
                                        onChange={handleOnChange_to}
                                      />
                                    </div>
                                  </div>
                                  <div className="price_section_pluse">
                                    <div className="price_content">
                                      <p>Your Price</p>
                                      <h3> {Number (parseFloat(currentmarketprice)).toFixed(
                                            1
                                          )}</h3>
                                    </div>
                                    {/* <div className="price_content">
                                 <p>Highest order Price</p>
                                 <h3>INR 90.62</h3>
                               </div> */}
                                  </div>
                                  <div className="input_increase">
                                  <span onClick={Decrement}>-</span>
                                    <p>
                                      {startcount == false ? (
                                        <>
                                          {Number (parseFloat(currentmarketprice)).toFixed(
                                            1
                                          )}
                                        </>
                                      ) : (
                                        <>{currentmarketprice}</>
                                      )}
                                    </p>
                                    {/* {setstartcount==true ?{Number(+currentmarketprice).toFixed(1)} :{}</p> */}
                                    <span onClick={Increment}>+</span>
                                  </div>
                                </div>
                                <div className="p2ppost_footer">
                             
                              <Button onClick={() => steps("one")}>Next</Button>
                              </div>
                              </div>
                            ) : activeTab == "paymentmethodtab" ? (
                              <div>
                                {/* step2 */}
                                <div className="subm  it_form_post">
                                  <div className="select_form new_form_selce">
                                    <div className="select_iocn">
                                      <label>Total Trading Amount</label>
                                      <div className="priceinput">
                                        <input type="text" />
                                        <span>
                                          {setfromcurrencypriceref.current}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="select_form">
                                    <div className="select_iocn">
                                      <label>Order Limit</label>
                                      <div className="priceinput">
                                        <input
                                          type="number"
                                          name="orderlimitstart"
                                          value={orderlimitstart}
                                          onChange={handleChange}
                                        />
                                        <span>
                                          {settocurrencypriceref.current}
                                        </span>
                                      </div>
                                      {orderlimitstartErr == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {vallidation.orderlimitstart}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                    </div>
                                    <div className="select_iocn clmariduke">
                                      <i class="ri-separator"></i>
                                    </div>
                                    <div className="select_iocn">
                                      <label></label>
                                      <div className="priceinput">
                                        <input
                                          type="text"
                                          name="orderlimitend"
                                          value={orderlimitend}
                                          onChange={handleChange}
                                        />
                                        <span>
                                          {settocurrencypriceref.current}
                                        </span>
                                      </div>
                                      {orderlimitendErr == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {vallidation.orderlimitend}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                    </div>
                                  </div>
                                  <div className="select_form mt-3 new_form_selce">
                                    <div className="select_iocn">
                                      <label>Payment Method</label>
                                      <Dropdown
                                        placeholder="Select Payment Method"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={paymentMethod}
                                        onChange={preferPayment}
                                      />
                                      {/* <p className="payment_time_">
                                    Payment Time Limit <span>15min</span>
                                  </p> */}
                                    </div>
                                  </div>
                                  <div className="select_form mt-3 new_form_selce">
                                    <div className="select_iocn">
                                      <label>Payment Time Limit</label>
                                      <Dropdown
                                        placeholder="Select timelimit"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={timelimit}
                                        onChange={paymentTimeLimit}
                                      />
                                    </div>
                                  </div>
                                </div>
                               
                                <div className="p2ppost_footer">
                             
                              <Button onClick={() => steps("two")}>Next</Button>
                              </div>
                              </div>
                            ) : activeTab == "remarktab" ? (
                              <div>
                                {/* step3 */}
                            <div className="submit_form_post mb-3">
                              <div className="select_form new_form_selce">
                                <div className="select_iocn flex_text_are">
                                  <label>Terms(Optional)</label>
                                  <textarea
                                     name="terms"
                                     value={terms}
                                     onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="select_form ">
                                <div className="select_iocn flex_text_are">
                                  <label>Auto Replay(Optional)</label>
                                  <textarea
                                      name="autoreply"
                                      value={autoreply}
                                      onChange={handleChange}
                                  ></textarea>
                                </div>

                              </div>
                              <div className="p2ppost_footer">
                              {buttonLoader == false ? (
                                <Button onClick={() => submitPost("buy")}>
                                  Submit Post
                                </Button>
                              ) : (
                                <Button>Loading...</Button>
                              )}
                            </div>
                            </div>
                              </div>
                            ):("")}
                            

                            
                          </div>
                          <div id="menu1" class="tab-pane fade">
                            {activeTab == "settype&pricetab" ? (
                              <div>
                                <div className="submit_form_post">
                                  <div className="select_form">
                                    <div className="select_iocn">
                                      <label>Asset</label>
                                      <Dropdown
                                        placeholder="Select currency"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={p2p_currencies}
                                        onChange={handleOnChange_from}
                                      />
                                    </div>
                                    <div className="select_iocn clmariduke">
                                      <i class="ri-arrow-right-line"></i>
                                    </div>
                                    <div className="select_iocn">
                                      <label>With Fiat</label>
                                      <Dropdown
                                        placeholder="Select Friend"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={fiat_currencies}
                                        onChange={handleOnChange_to}
                                      />
                                    </div>
                                  </div>
                                  <div className="price_section_pluse">
                                    <div className="price_content">
                                      <p>Your Price</p>
                                      <h3> {Number (parseFloat(currentmarketprice)).toFixed(
                                            1
                                          )}</h3>
                                    </div>
                                    {/* <div className="price_content">
                                 <p>Highest order Price</p>
                                 <h3>INR 90.62</h3>
                               </div> */}
                                  </div>
                                  <div className="input_increase">
                                  <span onClick={Decrement}>-</span>
                                    <p>
                                      {startcount == false ? (
                                        <>
                                          {Number (parseFloat(currentmarketprice)).toFixed(
                                            1
                                          )}
                                        </>
                                      ) : (
                                        <>{currentmarketprice}</>
                                      )}
                                    </p>
                                    {/* {setstartcount==true ?{Number(+currentmarketprice).toFixed(1)} :{}</p> */}
                                    <span onClick={Increment}>+</span>
                                  </div>
                                </div>
                                <div className="p2ppost_footer">
                             
                              <Button onClick={() => steps("one")}>Next</Button>
                              </div>
                              </div>
                            ) : activeTab == "paymentmethodtab" ? (
                              <div>
                                {/* step2 */}
                                <div className="subm  it_form_post">
                                  <div className="select_form new_form_selce">
                                    <div className="select_iocn">
                                      <label>Total Trading Amount</label>
                                      <div className="priceinput">
                                        <input type="text" />
                                        <span>
                                          {setfromcurrencypriceref.current}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="select_form">
                                    <div className="select_iocn">
                                      <label>Order Limit</label>
                                      <div className="priceinput">
                                        <input
                                          type="number"
                                          name="orderlimitstart"
                                          value={orderlimitstart}
                                          onChange={handleChange}
                                        />
                                        <span>
                                          {settocurrencypriceref.current}
                                        </span>
                                      </div>
                                         {orderlimitstartErr == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {vallidation.orderlimitstart}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                    </div>
                                    <div className="select_iocn clmariduke">
                                      <i class="ri-separator"></i>
                                    </div>
                                    <div className="select_iocn">
                                      <label></label>
                                      <div className="priceinput">
                                        <input
                                          type="text"
                                          name="orderlimitend"
                                          value={orderlimitend}
                                          onChange={handleChange}
                                        />
                                        <span>
                                          {settocurrencypriceref.current}
                                        </span>
                                      </div>
                                      {orderlimitendErr == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    {vallidation.orderlimitend}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                    </div>
                                  </div>
                                  <div className="select_form mt-3 new_form_selce">
                                    <div className="select_iocn">
                                      <label>Payment Method</label>
                                      <Dropdown
                                        placeholder="Select Payment Method"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={paymentMethod}
                                        onChange={preferPayment}
                                      />
                                      {/* <p className="payment_time_">
                                    Payment Time Limit <span>15min</span>
                                  </p> */}
                                    </div>
                                  </div>
                                  <div className="select_form mt-3 new_form_selce">
                                    <div className="select_iocn">
                                      <label>Payment Time Limit</label>
                                      <Dropdown
                                        placeholder="Select timelimit"
                                        fluid
                                        search
                                        selection
                                        className="data_dropdoen"
                                        options={timelimit}
                                        onChange={paymentTimeLimit}
                                      />
                                    </div>
                                 
                                  </div>
                                </div>
                               
                                <div className="p2ppost_footer">
                             
                              <Button onClick={() => steps("two")}>Next</Button>
                              </div>
                              </div>
                            ) : activeTab == "remarktab" ? (
                              <div>
                                {/* step3 */}
                            <div className="submit_form_post mb-3">
                              <div className="select_form new_form_selce">
                                <div className="select_iocn flex_text_are">
                                  <label>Terms(Optional)</label>
                                  <textarea
                                     name="terms"
                                     value={terms}
                                     onChange={handleChange}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="select_form ">
                                <div className="select_iocn flex_text_are">
                                  <label>Auto Replay(Optional)</label>
                                  <textarea
                                      name="autoreply"
                                      value={autoreply}
                                      onChange={handleChange}
                                  ></textarea>
                                </div>

                              </div>
                              <div className="p2ppost_footer">
                              {buttonLoader == false ? (
                                <Button onClick={() => submitPost("sell")}>
                                  Submit Post
                                </Button>
                              ) : (
                                <Button>Loading...</Button>
                              )}                            </div>
                            </div>
                              </div>
                            ):("")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
