import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Moment from "moment";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";

function Home() {
  const options = ["one", "two", "three"];
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);

  useEffect(() => {
    getAllcurrency();
    getdepositHistory();
  }, [0]);

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          label: data[i].currencySymbol,
          coinType: data[i].coinType,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const getdepositHistory = async () => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: {FilPerpage: 5, FilPage: 1},
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
    }
  };

  const onSelect = async (option) => {
    console.log(option, "-=-onSelecttop");
    setcurrency(option.label);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      console.log("currencydata===", currencydata);
      if (currencydata.coinType == "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=resp-=-=--");
        if (resp.status) {
          setview(true);
          setAddress(resp.Message);
          // if (depData.erc20token == "1") {
          //   setNetwork("ERC20");
          // } else if (depData.trc20token == "1") {
          //   setNetwork("TRC20");
          // } else if (depData.bep20token == "1") {
          //   setNetwork("BEP20");
          // }
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        console.log(resp, "=-=-=fiat deposit resp-=-=--");
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="row">
                  <div className="col-lg-12">
                    <img
                      src={require("../img/newimg/6306471.png")}
                      className="img-fluid warning_ing"
                    />
                    <div className="warning_content">
                      <h2>
                        Please complete the KYC verification process to gain
                        access to the page.
                      </h2>
                      <Link to="">Verify KYC Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
