import React from "react";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import P2homePost from "./P2homePost";
import Newsideheader from "./Newsideheader";
import Undermaintain from "./Undermaintain";
import Select from "react-select";
function Home() {
  const options = ["one", "two", "three"];

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contante">
                        <h1>
                          Buy and Sell Crypto With Your Preferred Payment Method
                        </h1>
                        <p>
                          Buy and sell Crypto safely and easily on Cashbit P2P.
                          Find the best offer below and buy and sell Crypto with
                          Your Preferred Payment Methods today.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card add_padin_card ">
                <div className="select_option">
                  <div className="tabs_section">
                    <div>
                      <ul class="nav nav-tabs">
                        <li class="active">
                          <a
                            data-toggle="tab"
                            href="#buy"
                            className="active buy"
                          >
                            {" "}
                            Buy
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#sell" className=" sell">
                            Sell
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="">
                      <div className="tabs_coin_section">
                        <ul class="nav nav-tabs newtabs">
                          <li class="active">
                            <a
                              data-toggle="tab"
                              href="#BTC"
                              className="active "
                            >
                              {" "}
                              BTC
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#USDT" className=" ">
                              USDT
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#ETH" className=" ">
                              ETH
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs_contnte_section">
                  <div class="tab-content">
                    <div id="buy" class="tab-pane fade in active show">
                      <div className="filter_section justify-content-between">
                        <div className="filter_section">
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Amount</label>
                              <input type="text" placeholder="" />
                            </div>
                          </div>
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Fiat</label>
                              <Select
                                options={options}
                                placeholder="Select an Currency"
                                className="call_seclecy new_height"
                              />
                            </div>
                          </div>
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Payment Method</label>
                              <Select
                                options={options}
                                placeholder="All payment"
                                className="call_seclecy new_height"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <Button className="refresg_button">
                            <i class="bi bi-arrow-clockwise"></i> refresh
                          </Button>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div id="BTC" class="tab-pane fade in active show">
                          <div className="action_bootrsebe_tanbel">
                            <div className="table-responsive mt-4 history_table">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Advertisers (Completion rate)</th>
                                    <th>Price</th>
                                    <th>Limit/Available</th>
                                    <th>Payment</th>
                                    <th>Trade</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    data-toggle="collapse"
                                    data-target="#demo1"
                                    class="accordion-toggle"
                                  >
                                    {/* <td colspan="7">
                                      <div class="norecode_found">
                                        <span>No records found</span>
                                      </div>
                                    </td> */}

                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="5" className="paddingh_0">
                                      <div
                                        class="accordian-body collapse"
                                        id="demo1"
                                      >
                                        {" "}
                                        <div className="chat_detaile">
                                          <div className="row">
                                            <div className="col-lg-7 d-flex flex-column justify-content-between">
                                              <div className="row">
                                                <div className="col-lg-6">
                                                  <div className="price_detaile">
                                                    <p>
                                                      Price
                                                      <span className="text-red">
                                                        100 INR
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Payment
                                                      <span className="">
                                                        15 Minutes
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div className="price_detaile">
                                                    <p>
                                                      Available
                                                      <span className="text-red">
                                                        0.001 USDT
                                                      </span>
                                                    </p>
                                                    <p>
                                                      Payment
                                                      <div className="payment_types">
                                                        <small>Phonepe</small>
                                                        <small>
                                                          Google pay(gpay)
                                                        </small>
                                                      </div>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  <div className="terms_dess">
                                                    <h5>
                                                      Terms and Conditions
                                                    </h5>
                                                    <ul>
                                                      <li>
                                                        Corem ipsum dolor sit
                                                        amet, consectetur
                                                        adipiscing elit. Nunc
                                                        vulputate libero et
                                                        velit interdum
                                                      </li>
                                                      <li>
                                                        Korem ipsum dolor sit
                                                        amet, consectetur
                                                        adipiscing elit. Nunc
                                                        vulputate libero et
                                                        velit interdum, ac
                                                        aliquet odio mattis.
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-5">
                                              <div className="class_form_section">
                                                <div className="form_p2p mb-4">
                                                  <label>I want to bay</label>
                                                  <div className="input_row_q">
                                                    <input type="text" />
                                                    <div>
                                                      <small>All</small>
                                                      <span>USDT</span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form_p2p mb-4">
                                                  <label>I will recieve</label>
                                                  <div className="input_row_q">
                                                    <input type="text" />
                                                    <div>
                                                      <span>USDT</span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form_p2p mb-4">
                                                  <label>Paymet Method</label>
                                                  <div className="selct_pay">
                                                    <Select
                                                      options={options}
                                                      placeholder="Select Payment method"
                                                      className="call_seclecy new_height"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="button_cancel">
                                                  <Button>Cancel</Button>
                                                  <Button className="color_section">
                                                    Buy BTC
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_buy">
                                        Buy BTC
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div id="USDT" class="tab-pane fade">
                          <h3>Menu 1</h3>
                          <p>Some content in menu 1.</p>
                        </div>
                        <div id="ETH" class="tab-pane fade">
                          <h3>Menu 2</h3>
                          <p>Some content in menu 2.</p>
                        </div>
                      </div>
                    </div>
                    <div id="sell" class="tab-pane fade">
                      <div className="filter_section justify-content-between">
                        <div className="filter_section">
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Amount</label>
                              <input type="text" placeholder="" />
                            </div>
                          </div>
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Fiat</label>
                              <Select
                                options={options}
                                placeholder="Select an Currency"
                                className="call_seclecy new_height"
                              />
                            </div>
                          </div>
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Payment Method</label>
                              <Select
                                options={options}
                                placeholder="All payment"
                                className="call_seclecy new_height"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <Button className="refresg_button">
                            <i class="bi bi-arrow-clockwise"></i> refresh
                          </Button>
                        </div>
                      </div>
                      <div class="tab-content">
                        <div id="BTC" class="tab-pane fade in active show">
                          <div className="action_bootrsebe_tanbel">
                            <div className="table-responsive mt-4 history_table">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Advertisers (Completion rate)</th>
                                    <th>Price</th>
                                    <th>Limit/Available</th>
                                    <th>Payment</th>
                                    <th>Trade</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {/* <td colspan="7">
                                      <div class="norecode_found">
                                        <span>No records found</span>
                                      </div>
                                    </td> */}

                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_sell">
                                        Sell BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_sell">
                                        Sell BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_sell">
                                        Sell BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_sell">
                                        Sell BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_sell">
                                        Sell BTC
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile_namepe">
                                        <span>J</span>
                                        <div className="details">
                                          <p>junawasarkar</p>
                                          <small>
                                            16 orders | 100.00% completion
                                          </small>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="price_">
                                        <p>
                                          80.50 <span>INR</span>
                                        </p>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="avli_limit">
                                        <div className="price_">
                                          <p>
                                            <span>Available</span> 675.47 USDT
                                          </p>
                                        </div>
                                        <div className="price_">
                                          <p>
                                            <span>Limit</span> ₹18,500.00 - ₹
                                            59,779.09
                                          </p>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="playment_methoid">
                                        Bank Transfer
                                      </div>
                                    </td>

                                    <td>
                                      <Button className="btn_sell">
                                        Sell BTC
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div id="USDT" class="tab-pane fade">
                          <h3>Menu 1</h3>
                          <p>Some content in menu 1.</p>
                        </div>
                        <div id="ETH" class="tab-pane fade">
                          <h3>Menu 2</h3>
                          <p>Some content in menu 2.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
