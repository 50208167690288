import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { toast } from "react-toastify";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Moment from "moment";
import { useLocation } from "react-router-dom";
import Select from "react-select";

function Home() {
  const navigate = useNavigate();
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [balance, setBalance, balanceref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [newAddres, setnewAddres] = useState("");
  const [newAddresErr, setnewAddresErr] = useState("");
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [currentcurrency, setcurrentcurrency, currentcurrencyref] =
    useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [withdrawType, setwithdrawType] = useState("");

  const [network_currency, setcur_network, network_currencyref] = useState([]);
  const [network_default, setnet_default, net_defaultref] =
    useState("");
  const [network_current, setnet_current, network_currentref] = useState("");
  const [show_otp, setshow_otp, show_otpref] = useState(false);

  const bankdetails = () => {};
  const withdrawAction = async (data) => {
    var obj = {
      withdraw_id: data,
    };
    var data = {
      apiUrl: apiService.confirmWithdraw,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status) {
      toast.success(resp.message, {
        toastId: "3",
      });
      //window.location.href = "/transaction";
      navigate("/withdraw");
    } else {
      toast.error(resp.message, {
        toastId: "3",
      });
      navigate("/withdraw");
    }
  };

  const qry_search = useLocation().search;
  const confirmation = new URLSearchParams(qry_search).get("transaction");
  if (confirmation != "" && confirmation != null) {
    withdrawAction(confirmation);
  }

  const initialFormValue = {
    amount: "",
    withAddress: "",
    tfa: "",
    withdraw_otp: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [amountValidate, setamountValidate] = useState(false);
  const [withAddressValidate, setwithAddress] = useState(false);
  const [tfaValidate, settfaValidate] = useState(false);
  const [otpValidate, setotpValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [sitekycStatus, setsitekycStatus, sitekycStatusref] =
    useState("DeActive");
  const [kycStatus, setkycStatus] = useState(0);

  const { amount, withAddress, tfa, withdraw_otp } = formValue;

  useEffect(() => {
    getKYCstatus();
    getAllcurrency();
    getwithdrawHistory(1);
    getAddress();
    getSiteSettingstatus();
    var status = localStorage.getItem("withdraw_status");
    if (status == null) {
      localStorage.setItem("withdraw_status", false);
    }
    if (status == "false") {
      setalertTab("show");
    } else {
      setalertTab("remove");
    }
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        if(data[i].withdrawStatus == "Active")
        {
          var obj = {
            value: data[i]._id,
            label: data[i].currencySymbol,
            coinType: data[i].coinType,
            erc20token: data[i].erc20token,
            bep20token: data[i].bep20token,
            trc20token: data[i].trc20token,
            rptc20token: data[i].rptc20token,
          };
          currArrayCrypto.push(obj);
        }
        
      }
      setallCurrency(currArrayCrypto);
    }
  };
  const onSelect1 = async (option) => {
    console.log(option,'-=-=-=-=option-=-=-=')
    setnewCurrency(option.label);
  };
  const onSelect = async (option) => {
    formValue.withAddress = null ;
    setwithdrawAddress("")
    setcurrAddres([])
    console.log(currAddresref.current,"=-=currAddresref=--=-")
    setnet_default(null);
    setwithdrawAddress(null);
    setcur_network("");
    setcurrency(option.label);
    setcointype(option.coinType);
    setnet_current("");
    getAddress();
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (option.label == "USD") {
      toast.error("Fiat withdraw is not allowed by the site");
    } else {
      if (indexData != -1) {
        var currencydata = allCryptoref.current[indexData];
        setcurrentcurrency(currencydata);
        setcur_network([]);
        setnet_default("");
        var network_cur = {};
        var network_names = [];
        if (currencydata.currencyType == "2") {
          if (currencydata.erc20token == "1") {
            network_cur = {
              value: "erc20token",
              label: "ERC20",
            };
            network_names.push(network_cur);
          }
          if (currencydata.bep20token == "1") {
            network_cur = {
              value: "bep20token",
              label: "BEP20",
            };
            network_names.push(network_cur);
          }
          if (currencydata.trc20token == "1") {
            network_cur = {
              value: "trc20token",
              label: "TRC20",
            };
            network_names.push(network_cur);
          }

          if (currencydata.rptc20token == "1") {
            network_cur = {
              value: "rptc20token",
              label: "RPTC20",
            };
            network_names.push(network_cur);
          }
          setcur_network(network_names);
          // setnet_default(network_currencyref.current[0].label);
        }
        setwithdrawType(currencydata.coinType);
        // if (currencydata.coinType== "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.user_balance,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setBalance(resp.data);
        } else {
        }
        // } else {
        // }
      }
    }
  };

  const nav_page = async (link) => {
    navigate(link);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    if (!values.withAddress) {
      errors.withAddress = "Destination address is a required field";
      setwithAddress(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_preview = async (values) => {
    const errors = {};
    if (!values.amount) {
      errors.amount = "Amount is a required field";
      setamountValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }

    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }

    setvalidationnErr(errors);
    return errors;
  };
  const validateFiat_submit = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is a required field";
      settfaValidate(true);
    }
    if (!values.withdraw_otp) {
      errors.withdraw_otp = "Withdraw OTP is a required field";
      setotpValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    if (getKYC.status) {
      setkycStatus(getKYC.Message.kycstatus);
      setBankwire(getKYC.bankdatastatus);
    } else {
      setkycStatus(0);
    }
  };

  const onSelect_network = async (option) => {
    setnet_current(option.label);
  };
  const onSelect_address = async (option) => {
    console.log(option,'=-=-=-=option=-=-=-=-=option=-=-=-option=-=-=')
    setwithdrawAddress(option.label)
    formValue.withAddress = option.label;

  };

  const withdrawPreview = async () => {
    try {
      console.log(formValue,"=-=-=v=-formValue-=-formValue=-=-vformValue")
      if (
        currentcurrency.currencySymbol == "USD" ||
        currentcurrency.currencySymbol == "INR"
      ) {
        if (bankwire == 1) {
          if (withdrawType == "2") {
            validateFiat_preview(formValue);
            if (formValue.amount != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    toast.error(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    toast.error(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      toast.success(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      toast.error(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  toast.error("Insufficient Balance!");
                  setbuttonLoader(false);
                }
              } else {
                toast.error("Please give valid withdraw amount!");
                setbuttonLoader(false);
              }
            }
          } else {
            validate_preview(formValue);
            if (formValue.amount != "" && formValue.withAddress != "") {
              if (+formValue.amount > 0) {
                if (+balanceref.current.balance > +formValue.amount) {
                  if (currentcurrency.minWithdrawLimit > formValue.amount) {
                    toast.error(
                      "Please enter greater than " +
                        currentcurrency.minWithdrawLimit +
                        " amount"
                    );
                  } else if (
                    currentcurrency.maxWithdrawLimit < formValue.amount
                  ) {
                    toast.error(
                      "Please enter less than " +
                        currentcurrency.maxWithdrawLimit +
                        " amount"
                    );
                  } else {
                    var data = {
                      apiUrl: apiService.send_otp,
                    };
                    setbuttonLoader(true);
                    var resp = await postMethod(data);
                    if (resp.status) {
                      toast.success(resp.message);
                      setbuttonLoader(false);
                      setshow_otp(true);
                    } else {
                      toast.error(resp.message);
                      setbuttonLoader(false);
                    }
                  }
                } else {
                  toast.error("Insufficient Balance");
                  setbuttonLoader(false);
                }
              } else {
                toast.error("Please give valid withdraw amount!");
              }
            }
          }
        } else {
          toast.error("Kindly update your Bank details");
          navigate("/bankdetails");
        }
      } else {
        if (withdrawType == "2") {
          validateFiat_preview(formValue);
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  toast.error(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  toast.error(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    toast.success(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    toast.error(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                toast.error("Insufficient Balance!");
                setbuttonLoader(false);
              }
            } else {
              toast.error("Please give valid withdraw amount!");
              setbuttonLoader(false);
            }
          }
        } else {
          validate_preview(formValue);
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (+balanceref.current.balance > +formValue.amount) {
                if (currentcurrency.minWithdrawLimit > formValue.amount) {
                  toast.error(
                    "Please enter greater than " +
                      currentcurrency.minWithdrawLimit +
                      " amount"
                  );
                } else if (
                  currentcurrency.maxWithdrawLimit < formValue.amount
                ) {
                  toast.error(
                    "Please enter less than " +
                      currentcurrency.maxWithdrawLimit +
                      " amount"
                  );
                } else {
                  var data = {
                    apiUrl: apiService.send_otp,
                  };
                  setbuttonLoader(true);
                  var resp = await postMethod(data);
                  if (resp.status) {
                    toast.success(resp.message);
                    setbuttonLoader(false);
                    setshow_otp(true);
                  } else {
                    toast.error(resp.message);
                    setbuttonLoader(false);
                  }
                }
              } else {
                toast.error("Insufficient Balance");
                setbuttonLoader(false);
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };
  const withdrawSubmit = async () => {
    try {
      if (withdrawType == "2") {
        validateFiat_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (formValue.amount != "" && formValue.tfa != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                var data = {
                  apiUrl: apiService.submitfiatWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  // formValue.withdraw_otp = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        }
      } else {
        validate_submit(formValue);

        if (localStorage.getItem("tfa_status") == 1) {
          if (
            formValue.amount != "" &&
            formValue.withAddress != "" &&
            formValue.tfa != ""
          ) {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          } else {
            toast.error("Please give all the fields !");
          }
        } else {
          if (formValue.amount != "" && formValue.withAddress != "") {
            if (+formValue.amount > 0) {
              if (currentcurrency.minWithdrawLimit > formValue.amount) {
                toast.error(
                  "Please enter greater than " +
                    currentcurrency.minWithdrawLimit +
                    " amount"
                );
              } else if (currentcurrency.maxWithdrawLimit < formValue.amount) {
                toast.error(
                  "Please enter less than " +
                    currentcurrency.maxWithdrawLimit +
                    " amount"
                );
              } else {
                var obj = {
                  amount: formValue.amount,
                  withdraw_address: formValue.withAddress,
                  tfaCode: formValue.tfa,
                  currency_symbol: currentcurrency.currencySymbol,
                  currId: currentcurrency._id,
                  network: network_currentref.current,
                  withdrawOtp: formValue.withdraw_otp,
                  tfa_status: localStorage.getItem("tfa_status"),
                };
                // console.log("submit withdraw params---",obj);
                // return;
                var data = {
                  apiUrl: apiService.submitWithdraw,
                  payload: obj,
                };
                setbuttonLoader(true);
                var resp = await postMethod(data);
                if (resp.status) {
                  toast.success(resp.message);
                  getwithdrawHistory(1);
                  setbuttonLoader(false);
                  setshow_otp(false);
                  formValue.amount = "";
                  setwithdrawAddress("");
                  formValue.tfa = "";
                  formValue.withdraw_otp = "";
                  //window.location.reload(false);
                } else {
                  toast.error(resp.message);
                  setbuttonLoader(false);
                  // formValue.amount = "";
                  // formValue.withAddress = "";
                  // formValue.tfa = "";
                  //window.location.reload(false);
                }
              }
            } else {
              toast.error("Please give valid withdraw amount!");
            }
          }
        }
      }
    } catch (error) {}
  };

  const [newCurrency, setnewCurrency] = useState("");
  const [newCurrencyErr, setnewCurrencyErr] = useState(false);
  const [newNetwork, setnewNetwork] = useState("");
  const [alertTab, setalertTab] = useState("hide");
  const [allAddress, setallAddress, allAddressref] = useState([]);
  const [currAddres, setcurrAddres, currAddresref] = useState([]);
  const [withdrawAddress, setwithdrawAddress, withdrawAddressref] = useState();

  const getAddress = async () => {
    var data = {
      apiUrl: apiService.getAddress,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      setallAddress(resp.data);
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        const element = resp.data[i];
        if (currencyref.current == element.currency) {
          var dropData = {
            value: element.currency,
            label: element.address,
          };
          data.push(dropData);
        }
        setcurrAddres(data);
      }
    } else {
      setallAddress([]);
    }
  };
  const Addaddress = async () => {
    if (newAddres == "") {
      setnewAddresErr(true);
    } else if (newCurrency == "") {
      setnewCurrencyErr(true);
      setnewAddresErr(false);
    } else {
      setnewCurrencyErr(false);
      var obj = {
        Address: newAddres,
        currency: newCurrency,
        network: newNetwork,
      };
      var data = {
        apiUrl: apiService.Addaddress,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        toast.success(resp.message);
        setnewAddres("");
        setnewCurrency("");
        setnewNetwork("");
        getAddress();
      } else {
        toast.error(resp.message);
      }
    }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Address copied");
  };

  const deleteAddress = async (_id) => {
    var data = {
      apiUrl: apiService.removeAddress,
      payload: { _id: _id },
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      toast.success(resp.message);
      getAddress();
    } else {
      toast.error(resp.message);
    }
  };

  const cancel = () => {
    setalertTab("show");
    navigate("/dashboard");
  };
  const agree = () => {
    setalertTab("remove");
    localStorage.setItem("withdraw_status", "true");
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    if (response.status) {
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">Withdraw</h1>
                    </div>
                  </div>
                </div>
                {sitekycStatusref.current == "Active" ? (
                  kycStatus == 1 ? (
                    <>
                      <div className="card m-0 table_card_new ">
                        <div className="row ">
                          <div className="col-lg-6 px-5 position_releative">
                            <div className="deposit mt-4">
                              {alertTab == "show" ? (
                                <div className="terms_content_popo">
                                  <p>Withdraw Note :</p>
                                  <ul>
                                    <li>
                                      Please double-check the destination
                                      address and blockchain networks
                                    </li>
                                    <li>
                                      If there's an issue with the withdrawal to
                                      your destination address, unfortunately,
                                      the site cannot process a refund.
                                    </li>
                                    <li>
                                      Please review carefully, as the amount
                                      appears to be double.
                                    </li>
                                    <li>
                                      You can only withdraw funds to approved
                                      addresses.
                                    </li>
                                  </ul>
                                  <div className="button_flex">
                                    <button onClick={cancel}>Cancel</button>
                                    <button
                                      onClick={agree}
                                      className="agree_button"
                                    >
                                      Agree
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {show_otpref.current == false ? (
                                <div className="form_accout calsss_dropdown">
                                  <label>Select Currency</label>
                                  <Dropdown
                                    options={allCurrencyref.current}
                                    placeholder="Select An Currency"
                                    onChange={(o) => onSelect(o)}
                                    defaultValue={currencyref.current}
                                    isSearchable={true}
                                    className="call_seclecy"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              {show_otpref.current == false &&
                              viewref.current == true ? (
                                <div className="balance_balance mt-2 mb-2">
                                  <p>
                                    Available Balance{" "}
                                    <span>
                                      {balanceref.current.balance.toFixed(8)}{" "}
                                      {currencyref.current}
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              {show_otpref.current == false &&
                              currentcurrencyref.current.currencyType == "2" ? (
                                <div className="form_accout calsss_dropdown">
                                  <label>Network</label>
                                  <Dropdown
                                    options={network_currencyref.current}
                                    placeholder="Select an Network"
                                    onChange={(o) => onSelect_network(o)}
                                    defaultValue={net_defaultref.current}
                                    isSearchable={true}
                                    className="call_seclecy"
                                  />
                                </div>
                              ) : (
                                ""
                              )}

                              {withdrawType == "1" &&
                              show_otpref.current == false ? (
                                <>
                                  <div className="form_accout calsss_dropdown">
                                    <div className="d-flex">
                                      <label>To Address</label>
                                    </div>
                                    <Dropdown
                                      options={currAddresref.current}
                                      placeholder="Select an Address"
                                      onChange={(o) => onSelect_address(o)}
                                      // defaultValue={net_defaultref.current }
                                      value={withdrawAddressref.current}
                                      className="call_seclecy"
                                    />
                                  </div>
                                  <div>
                                    {withAddressValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.withAddress}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>{" "}
                                </>
                              ) : (
                                ""
                              )}

                              {show_otpref.current == false ? (
                                <>
                                  <div className="form_accout form_flex">
                                    <label>Amount</label>
                                    <input
                                      type="number"
                                      pattern="[0-9]*"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(
                                          evt.key
                                        ) && evt.preventDefault()
                                      }
                                      placeholder="Enter Amount"
                                      name="amount"
                                      value={amount}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    {amountValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.amount}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}

                              {show_otpref.current == true ? (
                                <>
                                  {localStorage.getItem("tfa_status") == 0 ||
                                  1 ? (
                                    <>
                                      <div className="form_accout form_flex">
                                        <label>2FA</label>
                                        <input
                                          type="text"
                                          placeholder="Enter 2FA Code"
                                          name="tfa"
                                          value={tfa}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div>
                                        {tfaValidate == true ? (
                                          <p className="text-danger">
                                            {" "}
                                            {validationnErr.tfa}{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <div className="form_accout form_flex">
                                    <label>Withdraw OTP</label>
                                    <input
                                      type="text"
                                      placeholder="Enter Withdraw OTP"
                                      name="withdraw_otp"
                                      value={withdraw_otp}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div>
                                    {otpValidate == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.withdraw_otp}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {show_otpref.current == false &&
                              viewref.current == true ? (
                                <div className="balance_balance">
                                  <p>
                                    Fees{" "}
                                    <span>
                                      {currentcurrencyref.current.withdrawFee} %
                                    </span>
                                  </p>

                                  <p>
                                    Minimum withdraw{" "}
                                    <span>
                                      {
                                        currentcurrencyref.current
                                          .minWithdrawLimit
                                      }{" "}
                                    </span>
                                  </p>

                                  <p>
                                    Maximum withdraw{" "}
                                    <span>
                                      {
                                        currentcurrencyref.current
                                          .maxWithdrawLimit
                                      }{" "}
                                    </span>
                                  </p>

                                  {balanceref.current.network != "" ? (
                                    <p>
                                      Network{" "}
                                      <span>{balanceref.current.network} </span>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* {localStorage.getItem("tfa_status") == 1 ? (
                         buttonLoader == false ? (

                          <div className="submit_btn">
                            {show_otpref.current == true ? (
                             <Button onClick={() => withdrawSubmit()}>Submit</Button>
                            ) : (
                              <Button onClick={() => withdrawPreview()}>Submit</Button>
                            )}
                        </div>
                          
                        ):("loading...")
                        ) : (
                          <div className="submit_btn">
                          <Button onClick={() => nav_page("/security")}>Enable 2FA</Button>
                        </div>
                        )} */}
                              {buttonLoader == false ? (
                                <div className="submit_btn">
                                  {localStorage.getItem("tfa_status") == 0 ? (
                                    <Button
                                      onClick={() => nav_page("/security")}
                                    >
                                      Enable2FA
                                    </Button>
                                  ) : show_otpref.current == true ? (
                                    <Button onClick={() => withdrawSubmit()}>
                                      Submit
                                    </Button>
                                  ) : (
                                    <Button onClick={() => withdrawPreview()}>
                                      Submit
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div className="submit_btn">
                                  <Button>Loading...</Button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 px-5 bg-black">
                            <div className="deposit mt-4">
                              <div className="Addbook">
                                <div className="d-flex">
                                  <input
                                    type="text"
                                    placeholder="Enter To Address"
                                    name="Address"
                                    value={newAddres}
                                    onChange={(e) =>
                                      setnewAddres(e.target.value)
                                    }
                                  />
                                  <div className="ml-3">
                                    <Button onClick={Addaddress}>Add</Button>
                                  </div>
                                </div>
                                {newAddresErr == true ? (
                                  <p style={{ color: "red" }}>
                                    Address is required
                                  </p>
                                ) : (
                                  ""
                                )}
                                {newAddres != "" ? (
                                  <>
                                    <div className="form_accout calsss_dropdown">
                                      <Dropdown
                                        options={allCurrencyref.current}
                                        placeholder="Select An Currency"
                                        onChange={(o) => onSelect1(o)}
                                        defaultValue={currencyref.current}
                                        isSearchable={true}
                                        className="call_seclecy mt-2"
                                      />
                                    </div>
                                    {newCurrencyErr == true ? (
                                      <p style={{ color: "red" }}>
                                        Currency is required
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <div className="mt-2">
                                      <input
                                        type="text"
                                        placeholder="Enter network (Optional)"
                                        value={newNetwork}
                                        onChange={(e) =>
                                          setnewNetwork(e.target.value)
                                        }
                                      />
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="addresses mt-3 p-4">
                                  <h5>Address Book</h5>
                                  {allAddressref.current && allAddressref.current.length > 0 ? (
                                    allAddressref.current.map((data, i) => {
                                      return (
                                        <div className="d-flex add_address">
                                          <h5 className="">{data.address}</h5>
                                          <h5 className="curr_clss">
                                            {data.currency}
                                          </h5>
                                          <i
                                            class="fa fa-clone text-white mr-2"
                                            aria-hidden="true"
                                            onClick={() => copy(data.address)}
                                          ></i>
                                          <i
                                            class="fa fa-trash text-danger ml-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              deleteAddress(data._id)
                                            }
                                          ></i>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    
                                    <p className="norecord text-center">
                                      No Address Found 
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-10px withdraw_card">
                        <div className="card p-0 mt-0 withdraw_card2">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="Heading">
                                <h2>Recent Withdraw Records</h2>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="table-responsive mt-4 history_table">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Time</th>
                                      <th>Currency</th>
                                      <th>Amount</th>
                                      <th>Address</th>
                                      <th>Transaction Id</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {withdrawHistory &&
                                    withdrawHistory.length > 0 ? (
                                      withdrawHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              {Moment(item.created_at).format(
                                                "DD.MM.YYYY"
                                              )}
                                            </td>
                                            <td>
                                              {Moment(item.created_at).format(
                                                "HH:mm a"
                                              )}
                                            </td>
                                            <td>{item.currency}</td>
                                            <td>
                                              {parseFloat(item.amount).toFixed(
                                                8
                                              )}
                                            </td>
                                            <td>
                                              <span className="hash_code">
                                                {item.withdraw_address}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="hash_code">
                                                {item.txn_id}
                                              </span>
                                            </td>
                                            <td>
                                              {item.status == "Completed" ? (
                                                <span className="text-green">
                                                  {item.status}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {item.status !== "Completed" ? (
                                                <span className="text-red">
                                                  {item.status}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="7">
                                          <div className="norecode_found">
                                            <img
                                              src={require("../img/newimg/banksearch.png")}
                                            />
                                            <span>No records found</span>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    {/* <tr>
                                <td>20:20:45</td>
                                <td>BTC</td>
                                <td>1000 INR</td>
                                <td>BSC</td>
                                <td>
                                  <span class="hash_code">
                                    3423423423423vrfsfsdfe55345dfg454
                                  </span>
                                </td>
                                <td>ID0922201</td>
                                <td>
                                  <span className="text-red">Canceled</span>
                                </td>
                              </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="card m-0 table_card_new ">
                      <div className="row justify-content-center">
                        <div className="row">
                          <div className="col-lg-12">
                            <img
                              src={require("../img/newimg/6306471.png")}
                              className="img-fluid warning_ing"
                            />
                            <div className="warning_content">
                              <h2>
                                Please complete the KYC verification process to
                                gain access to the page.
                              </h2>
                              <Link to="/kyc">Verify KYC Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <>
                    <div className="card m-0 table_card_new ">
                      <div className="row ">
                        <div className="col-lg-6 px-5 position_releative">
                          <div className="deposit mt-4">
                            {alertTab == "show" ? (
                              <div className="terms_content_popo">
                                <p>Withdraw Note :</p>
                                <ul>
                                  <li>
                                    Please double-check the destination address
                                    and blockchain networks
                                  </li>
                                  <li>
                                    If there's an issue with the withdrawal to
                                    your destination address, unfortunately, the
                                    site cannot process a refund.
                                  </li>
                                  <li>
                                    Please review carefully, as the amount
                                    appears to be double.
                                  </li>
                                  <li>
                                    You can only withdraw funds to approved
                                    addresses.
                                  </li>
                                </ul>
                                <div className="button_flex">
                                  <button onClick={cancel}>Cancel</button>
                                  <button
                                    onClick={agree}
                                    className="agree_button"
                                  >
                                    Agree
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {show_otpref.current == false ? (
                              <div className="form_accout calsss_dropdown">
                                <label>Select Currency</label>
                                <Dropdown
                                  options={allCurrencyref.current}
                                  placeholder="Select An Currency"
                                  onChange={(o) => onSelect(o)}
                                  defaultValue={currencyref.current}
                                  isSearchable={true}
                                  className="call_seclecy"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {show_otpref.current == false &&
                            viewref.current == true ? (
                              <div className="balance_balance mt-2 mb-2">
                                <p>
                                  Available Balance{" "}
                                  <span>
                                    {balanceref.current.balance.toFixed(8)}{" "}
                                    {currencyref.current}
                                  </span>
                                </p>
                              </div>
                            ) : (
                              ""
                            )}

                            {show_otpref.current == false &&
                            currentcurrencyref.current.currencyType == "2" ? (
                              <div className="form_accout calsss_dropdown">
                                <label>Network</label>
                                <Dropdown
                                  options={network_currencyref.current}
                                  placeholder="Select an Network"
                                  onChange={(o) => onSelect_network(o)}
                                  defaultValue={net_defaultref.current}
                                  isSearchable={true}
                                  className="call_seclecy"
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {withdrawType == "1" &&
                            show_otpref.current == false ? (
                              <>
                                <div className="form_accout calsss_dropdown">
                                  <div className="d-flex">
                                    <label>To Address</label>
                                  </div>
                                  <Dropdown
                                    options={currAddresref.current}
                                    placeholder="Select an Address"
                                    onChange={(o) => onSelect_address(o)}
                                    // defaultValue={net_defaultref.current}
                                    value={withdrawAddressref.current}
                                    className="call_seclecy"
                                  />
                                </div>
                                <div>
                                  {withAddressValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.withAddress}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>{" "}
                              </>
                            ) : (
                              ""
                            )}

                            {show_otpref.current == false ? (
                              <>
                                <div className="form_accout form_flex">
                                  <label>Amount</label>
                                  <input
                                    type="number"
                                    pattern="[0-9]*"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    placeholder="Enter Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {amountValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.amount}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {show_otpref.current == true ? (
                              <>
                                {localStorage.getItem("tfa_status") == 0 ||
                                1 ? (
                                  <>
                                    <div className="form_accout form_flex">
                                      <label>2FA</label>
                                      <input
                                        type="text"
                                        placeholder="Enter 2FA Code"
                                        name="tfa"
                                        value={tfa}
                                        onChange={handleChange}
                                      />
                                    </div>
                                    <div>
                                      {tfaValidate == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.tfa}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="form_accout form_flex">
                                  <label>Withdraw OTP</label>
                                  <input
                                    type="text"
                                    placeholder="Enter Withdraw OTP"
                                    name="withdraw_otp"
                                    value={withdraw_otp}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div>
                                  {otpValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.withdraw_otp}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {show_otpref.current == false &&
                            viewref.current == true ? (
                              <div className="balance_balance">
                                <p>
                                  Fees{" "}
                                  <span>
                                    {currentcurrencyref.current.withdrawFee} %
                                  </span>
                                </p>

                                <p>
                                  Minimum withdraw{" "}
                                  <span>
                                    {
                                      currentcurrencyref.current
                                        .minWithdrawLimit
                                    }{" "}
                                  </span>
                                </p>

                                <p>
                                  Maximum withdraw{" "}
                                  <span>
                                    {
                                      currentcurrencyref.current
                                        .maxWithdrawLimit
                                    }{" "}
                                  </span>
                                </p>

                                {balanceref.current.network != "" ? (
                                  <p>
                                    Network{" "}
                                    <span>{balanceref.current.network} </span>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {localStorage.getItem("tfa_status") == 1 ? (
                         buttonLoader == false ? (

                          <div className="submit_btn">
                            {show_otpref.current == true ? (
                             <Button onClick={() => withdrawSubmit()}>Submit</Button>
                            ) : (
                              <Button onClick={() => withdrawPreview()}>Submit</Button>
                            )}
                        </div>
                          
                        ):("loading...")
                        ) : (
                          <div className="submit_btn">
                          <Button onClick={() => nav_page("/security")}>Enable 2FA</Button>
                        </div>
                        )} */}
                            {buttonLoader == false ? (
                              <div className="submit_btn">
                                {localStorage.getItem("tfa_status") == 0 ? (
                                  <Button onClick={() => nav_page("/security")}>
                                    Enable2FA
                                  </Button>
                                ) : show_otpref.current == true ? (
                                  <Button onClick={() => withdrawSubmit()}>
                                    Submit
                                  </Button>
                                ) : (
                                  <Button onClick={() => withdrawPreview()}>
                                    Submit
                                  </Button>
                                )}
                              </div>
                            ) : (
                              <div className="submit_btn">
                                <Button>Loading...</Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 px-5 bg-black">
                          <div className="deposit mt-4">
                            <div className="Addbook">
                              <div className="d-flex">
                                <input
                                  type="text"
                                  placeholder="Enter To Address"
                                  name="Address"
                                  value={newAddres}
                                  onChange={(e) => setnewAddres(e.target.value)}
                                />
                                <div className="ml-3">
                                  <Button onClick={Addaddress}>Add</Button>
                                </div>
                              </div>
                              {newAddresErr == true ? (
                                <p style={{ color: "red" }}>
                                  Address is required
                                </p>
                              ) : (
                                ""
                              )}
                              {newAddres != "" ? (
                                <>
                                  <div className="form_accout calsss_dropdown">
                                    <Dropdown
                                      options={allCurrencyref.current}
                                      placeholder="Select An Currency"
                                      onChange={(o) => onSelect1(o)}
                                      defaultValue={currencyref.current}
                                      isSearchable={true}
                                      className="call_seclecy mt-2"
                                    />
                                  </div>
                                  {newCurrencyErr == true ? (
                                    <p style={{ color: "red" }}>
                                      Currency is required
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <div className="mt-2">
                                    <input
                                      type="text"
                                      placeholder="Enter network (Optional)"
                                      value={newNetwork}
                                      onChange={(e) =>
                                        setnewNetwork(e.target.value)
                                      }
                                    />
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <div className="addresses mt-3 p-4">
                                <h5>Address Book</h5>
                                {allAddressref.current && allAddressref.current.length > 0 ? (
                                   allAddressref.current.map((data, i) => {
                                  return (
                                    <div className="d-flex add_address">
                                      <h5 className="">{data.address}</h5>
                                      <h5 className="curr_clss">
                                        {data.currency}
                                      </h5>
                                      <i
                                        class="fa fa-clone text-white mr-2"
                                        aria-hidden="true"
                                        onClick={() => copy(data.address)}
                                      ></i>
                                      <i
                                        class="fa fa-trash text-danger ml-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => deleteAddress(data._id)}
                                      ></i>
                                    </div>
                                   );
                                  })
                                ) : (
                                  
                                  <p className="norecord text-center">
                                    No Address Found 
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mt-10px withdraw_card">
                      <div className="card p-0 mt-0 withdraw_card2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2>Recent Withdraw Records</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Currency</th>
                                    <th>Amount</th>
                                    <th>Address</th>
                                    <th>Transaction Id</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {withdrawHistory &&
                                  withdrawHistory.length > 0 ? (
                                    withdrawHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            {Moment(item.created_at).format(
                                              "DD.MM.YYYY"
                                            )}
                                          </td>
                                          <td>
                                            {Moment(item.created_at).format(
                                              "HH:mm a"
                                            )}
                                          </td>
                                          <td>{item.currency}</td>
                                          <td>
                                            {parseFloat(item.amount).toFixed(8)}
                                          </td>
                                          <td>
                                            <span className="hash_code">
                                              {item.withdraw_address}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="hash_code">
                                              {item.txn_id}
                                            </span>
                                          </td>
                                          <td>
                                            {item.status == "Completed" ? (
                                              <span className="text-green">
                                                {item.status}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {item.status !== "Completed" ? (
                                              <span className="text-red">
                                                {item.status}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="7">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No records found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                <td>20:20:45</td>
                                <td>BTC</td>
                                <td>1000 INR</td>
                                <td>BSC</td>
                                <td>
                                  <span class="hash_code">
                                    3423423423423vrfsfsdfe55345dfg454
                                  </span>
                                </td>
                                <td>ID0922201</td>
                                <td>
                                  <span className="text-red">Canceled</span>
                                </td>
                              </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
