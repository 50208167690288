import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Moment from "moment";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {env} from "../core/service/envconfig";
import Loader from "../Component/Loader";
import {loadStripe} from "@stripe/stripe-js";
import Card from "react-bootstrap/Card";

function Home() {
  const options = ["one", "two", "three"];
  const [allCurrency, setallCurrency, allCurrencyref] = useState([]);
  const [allCrypto, setallCrypto, allCryptoref] = useState([]);
  const [currency, setcurrency, currencyref] = useState("");
  const [cointype, setcointype, cointyperef] = useState("");
  const [address, setAddress, addressref] = useState("");
  const [view, setview, viewref] = useState(false);
  const [bankwire, setBankwire] = useState("");
  const [depositHistory, setdepositHistory] = useState([]);
  const [kycStatus, setkycStatus] = useState(1);
  const [bankDetails, serbankDetails] = useState("");
  const [depositProof, segdepositProof] = useState("");
  const [depoistAmount, setdepoistAmount, depoistAmountref] = useState(0);
  const [
    stripedepoistAmount,
    setstripedepoistAmount,
    stripedepoistAmountref,
  ] = useState(0);
  const [amountBool, setamountBool] = useState(false);
  const [stripeamountBool, setstripeamountBool] = useState(false);
  const [proofBool, setproofBool] = useState(false);
  const [loaderImage, setloaderImage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalactive, settotalactive] = useState(0);
  const [perpage, setperpage] = useState(5);
  const [siteLoader, setSiteLoader, siteLoaderref] = useState(false);
  const [depositCount, setdepositCount, depositCountref] = useState(0);
  const [depositQr, setdepositQr, depositQrref] = useState("");
  const [loaderBtn, setloaderBtn] = useState(false);

  const recordPerPage = 5;
  const pageRange = 5;

  var value = {
    name: "stripe Deposit",
    price: 0,
    quantity: 1,
  };
  const [product, setproduct] = useState(value);

  const onChange = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...product, ...{[name]: value}};
    setproduct(formData);
  };
  const findDeposit = async () => {
    var findId = localStorage.getItem("TransactionID");
    var curUrl = window.location.href;
    var details = curUrl.split("?")[1];
    if (findId != null) {
      if (details != undefined) {
        var obj = {
          transactionID: findId,
          Details: details,
        };
        var data = {
          apiUrl: apiService.findDeposit,
          payload: obj,
        };
        var response = await postMethod(data);
        if (response.Status == true) {
          localStorage.removeItem("TransactionID");
          getdepositHistory();
        }
        toast(response.Message);
      }
    } else {
    }
  };

  const makePayment = async () => {
    if (product.price != 0) {
      setstripeamountBool(false);
      const stripe = await loadStripe(
        "pk_test_51KG1pnSIoU3SvLUeJqvxTzITwJ7w88XkbT6of5OyknJLg2unhTLFGErhfREmZdvyzdH8GooGkFEk4rPU42kKABCa008nrxJ0Kp"
      );
      const body = {product};
      const headers = {
        "Content-Type": "application/json",
      };

      var obj = {
        headers: headers,
        body: JSON.stringify(body),
      };

      var data = {
        apiUrl: apiService.stripeWallet,
        payload: obj,
      };
      var response = await postMethod(data);
      if (response) {
        window.location.href = response.data.retractUrl;
        localStorage.setItem("TransactionID", response.data.transactionID);
      }
    } else {
      setstripeamountBool(true);
    }
  };

  useEffect(() => {
    function init() {
      findDeposit();
      getKYCstatus();
      getAllcurrency();
      getdepositHistory(1);
      getBankWire();
    }
    init();
  }, 0);

  const getAllcurrency = async () => {
    var data = {
      apiUrl: apiService.walletcurrency,
    };
    var resp = await getMethod(data);
    if (resp) {
      var currArrayCrypto = [];
      var data = resp.data;
      setallCrypto(data);
      for (var i = 0; i < data.length; i++) {
        var obj = {
          value: data[i]._id,
          label: data[i].currencySymbol,
          coinType: data[i].coinType,
        };
        currArrayCrypto.push(obj);
      }
      setallCurrency(currArrayCrypto);
    }
  };

  const getdepositHistory = async (pages) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: {FilPerpage: perpage, FilPage: pages},
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.fiat_deposit);
      settotalactive(deposit_history_list.total);
      setdepositCount(deposit_history_list.fiat_deposit_count);
    }
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getdepositHistory(pageNumber);
  };

  const onSelect = async (option) => {
    setcurrency(option.label);
    setcointype(option.coinType);
    let indexData = allCryptoref.current.findIndex(
      (x) => x._id == option.value
    );
    if (indexData != -1) {
      var currencydata = allCryptoref.current[indexData];
      if (currencydata.coinType == "1") {
        var obj = {
          currency: option.label,
          currId: option.value,
        };
        var data = {
          apiUrl: apiService.generateAddress,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setview(true);
          setAddress(resp.Message);
          // if (depData.erc20token == "1") {
          //   setNetwork("ERC20");
          // } else if (depData.trc20token == "1") {
          //   setNetwork("TRC20");
          // } else if (depData.bep20token == "1") {
          //   setNetwork("BEP20");
          // }
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      } else {
        var obj = {
          currency: option.label,
        };
        var data = {
          apiUrl: apiService.bankwire,
          payload: obj,
        };

        var resp = await postMethod(data);
        if (resp.status) {
          setBankwire(resp.data);
        } else {
          //toast.error("Something went wrong, please try again later");
        }
      }
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    setSiteLoader(true);
    if (getKYC.status) {
      setSiteLoader(false);
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };
  const getBankWire = async () => {
    var obj = {
      currency: "INR",
    };
    var data = {
      apiUrl: apiService.bankwire,
      payload: obj,
    };
    var getBank = await postMethod(data);
    if (getBank.status) {
      serbankDetails(getBank.data);
      var bank = {
        AccountNumber: getBank.data.Account_Number,
        BankName: getBank.data.Bank_Name,
        IFSC: getBank.data.IFSC_code,
        Branch: getBank.data.Branch_Name,
        Name: getBank.data.Name,
      };
      setdepositQr(bank);
    } else {
      // setkycStatus(0);
    }
  };

  const imageUpload = (val) => {
    try {
      setloaderImage(true);
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg or .pdf or .doc "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", "d1zijfds");
        data.append("cloud_name", "dkojazpdl");
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          {method: "post", body: data}
        )
          .then((resp) => resp.json())
          .then((data) => {
            segdepositProof(data.secure_url);
            setloaderImage(false);
          })
          .catch((err) => {
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const choose = {
    clientSecret: "{{CLIENT_SECRET}}",
  };

  const depositSubmit = async () => {
    try {
      depoistAmountref.current == ""
        ? setamountBool(true)
        : setamountBool(false);
      depositProof == "" ? setproofBool(true) : setproofBool(false);
      if (depoistAmountref.current != "" && depositProof != "") {
        if (+depoistAmountref.current > 0) {
          var obj = {
            amount: depoistAmountref.current,
            txn_proof: depositProof,
            currency_symbol: bankDetails.currency,
            currId: bankDetails.currency_id,
          };
          var data = {
            apiUrl: apiService.fiatdepositSubmit,
            payload: obj,
          };
          setloaderBtn(true);
          var resp = await postMethod(data);
          if (resp.status) {
            setloaderBtn(false);
            toast.success(resp.Message);
            getdepositHistory(1);
            setdepoistAmount("");
            segdepositProof("");
          } else {
            setloaderBtn(false);
            toast.error(resp.Message);
          }
        } else {
          toast.error("Please give valid deposited amount!");
        }
      } else {
        // toast.error("All fields are required!");
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            {/* {
              siteLoaderref.current == true ? <Loader/> : */}
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">Fiat Deposit</h1>
                    </div>
                  </div>
                </div>
                {kycStatus == 1 ? (
                  <>
                    <div className="card m-0 table_card_new pb-4">
                      <div className="row justify-content-center pt-3">
                        <div class="col-lg-12 p-0">
                          <h1 class="headre_tev">Switch Bank Details</h1>
                          {/* <small>Admin bank details</small> */}
                        </div>

                        <div className="col-lg-9">
                          <div className="row">
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Benificiary Name</label>
                                <input
                                  type="text"
                                  placeholder="Benificiary Name"
                                  value={bankDetails.Name}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Bank Name</label>
                                <input
                                  type="text"
                                  placeholder="Bank Name"
                                  value={bankDetails.Bank_Name}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Account Number</label>
                                <input
                                  type="Number"
                                  min="0"
                                  placeholder="Account Number"
                                  value={bankDetails.Account_Number}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  placeholder="IFSC Code"
                                  value={bankDetails.IFSC_code}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex deposit">
                                <label>Account Type</label>
                                <input
                                  type="text"
                                  placeholder="IFSC Code"
                                  value={bankDetails.Account_type}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Branch Name</label>
                                <input
                                  type="text"
                                  placeholder="Branch Name"
                                  value={bankDetails.Branch_Name}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-sm-12">
                              <div className="form_accout form_flex">
                                <div className="QR_code">
                                  {bankDetails != "" ? (
                                    <img
                                      src={
                                        "https://chart.googleapis.com/chart?chs=168x168&chld=M|0&cht=qr&chl=" +
                                        JSON.stringify(depositQrref.current) +
                                        ""
                                      }
                                      className=""
                                    />
                                  ) : (
                                    <img
                                      src={require("../img/newimg/qr.png")}
                                      className=""
                                    />
                                  )}

                                  <p>Scan QR</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-10px fiatdeposit_card">
                      <div className="row justify-content-center ">
                        <div class="col-lg-12 p-0">
                          <h1 class="headre_tev pl-4"> Fiat Deposit </h1>
                        </div>
                        <div className="col-lg-9">
                          <div className="row">
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Amount </label>
                                <input
                                  type="Number"
                                  min="0"
                                  placeholder="Amount"
                                  name="amount"
                                  onChange={(e) =>
                                    setdepoistAmount(e.target.value)
                                  }
                                />
                              </div>
                              <div>
                                {amountBool == true ? (
                                  <small style={{color: "red"}}>
                                    Amount is requird
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Transaction Proof </label>

                                <div class="custom-file">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="customFile"
                                    onChange={(e) =>
                                      imageUpload(e.target.files[0])
                                    }
                                    name="image"
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="customFile"
                                  >
                                    {loaderImage == true
                                      ? "Loading..."
                                      : depositProof == ""
                                      ? "Choose file"
                                      : depositProof}
                                    {/* {  depositProof == "" ?  "Choose file" : depositProof}   */}
                                  </label>
                                </div>
                                <div>
                                  {proofBool == true ? (
                                    <small style={{color: "red"}}>
                                      Deposit proof is requird
                                    </small>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="butn_fiat">
                                {loaderBtn == true ? (
                                  <Button disabled={false}>Loading...</Button>
                                ) : (
                                  <Button
                                    onClick={depositSubmit}
                                    disabled={false}
                                  >
                                    Submit
                                  </Button>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-lg-12">
                              <div className="butn_fiat">
                                <Card.Body>
                                  <Card.Title>{product.name}</Card.Title>
                                  <Card.Text>{product.description}</Card.Text>
                                  <Button variant="primary" onClick={makePayment}>
                                    Buy Now for {product.price}
                                  </Button>
                                </Card.Body>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card mt-10px     ">
                      <div className="row justify-content-center ">
                        <div class="col-lg-12 p-0">
                          <h1 class="headre_tev pl-4">Stripe Fiat Deposit </h1>
                        </div>
                        <div className="col-lg-9">
                          <div className="row">
                            <div className="col-lg-6 col-sm-6">
                              <div className="form_accout form_flex">
                                <label>Amount </label>
                                <input
                                  type="Number"
                                  placeholder="Amount"
                                  name="price"
                                  onChange={onChange}
                                />
                              </div>
                              <div>
                                {stripeamountBool == true ? (
                                  <small style={{ color: "red" }}>
                                    Amount is requird
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="butn_fiat">
                                <Card.Body>
                                  <Button variant="primary" onClick={makePayment} className="d-block m-auto">
                                    Stripe Deposit
                                  </Button>
                                </Card.Body>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="card mt-10px fiatdeposit_card">
                      <div className="card p-0 mt-0 fiatdeposit_card2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2>Recent Deposit Records</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Currency</th>
                                    <th>Amount</th>
                                    <th>Transaction Id</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {depositHistory &&
                                  depositHistory.length > 0 ? (
                                    depositHistory.map((item, i) => {
                                      return item.depType == 1 ? (
                                        <tr>
                                          <td>
                                            {Moment(item.date).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </td>
                                          <td>
                                            {Moment(item.date).format(
                                              "HH:mm:ss"
                                            )}
                                          </td>
                                          <td>{item.currencySymbol}</td>
                                          <td>
                                            {parseFloat(item.amount).toFixed(2)}
                                          </td>
                                          <td>
                                            <span className="hash_code">
                                              {item.txnid}
                                            </span>
                                          </td>
                                          <td>
                                            <span
                                              className={
                                                item.status == "Completed"
                                                  ? "text-green"
                                                  : item.status == "Rejected"
                                                  ? "text-red"
                                                  : "text-orange"
                                              }
                                            >
                                              {item.status}
                                            </span>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      );

                                      // <tr>
                                      //   <td>20:20:45</td>
                                      //   <td>BTC</td>
                                      //   <td>1000 INR</td>
                                      //   <td>BSC</td>
                                      //   <td>
                                      //     <span class="hash_code">
                                      //       3423423423423vrfsfsdfe55345dfg454
                                      //     </span>
                                      //   </td>
                                      //   <td>ID0922201</td>
                                      //   <td>
                                      //     <span className="text-red">Canceled</span>
                                      //   </td>
                                      // </tr>
                                      // <tr>
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="6">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No records found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        {depositCountref.length > 0 ? (
                          <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={currentPage}
                            itemsCountPerPage={recordPerPage}
                            totalItemsCount={depositCount}
                            pageRangeDisplayed={pageRange}
                            onChange={activePageChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-lg-12">
                      <img
                        src={require("../img/newimg/6306471.png")}
                        className="img-fluid warning_ing"
                      />
                      <div className="warning_content">
                        <h2>
                          Please complete the KYC verification process to gain
                          access to the page.
                        </h2>
                        <Link to="/kyc">Verify KYC Now</Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* } */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
