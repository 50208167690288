import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";

import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Home() {
  const navigate = useNavigate();
  const options = ["one", "two", "three"];

  const profileForm = {
    username: "",
    mobileNumber: "",
  };


  const [profileformValue, setprofileFormValue] = useState(profileForm);

  const [usernamevalidate, setusernamevalidate, usernamevalidateref] = useState(false);
  const [mobilevalidate, setmobilevalidate, mobilevalidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [profileLoader, setprofileLoader] = useState(false);

  const { username, mobileNumber } = profileformValue;
  const [profileData, setprofileData] = useState("");


  useEffect(() => {
    getProfile();
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }

  }, [0]);

  const handleChange_profile = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...profileformValue, ...{ [name]: value } };
    setprofileFormValue(formData);
    // validate(formData);
  };


  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setprofileData(resp.data);
        profileformValue.username = resp.data.username;
        profileformValue.mobileNumber = resp.data.mobileNumber;
      }

    } catch (error) { }
  };
  const profileSubmit = async (payload) => {
    profile_validate(profileformValue);
    console.log("usernamevalidateref.current==", usernamevalidateref.current)
    console.log("mobilevalidateref.current==", mobilevalidateref.current)
    if (
      usernamevalidateref.current == false
    ) {
      var obj = {
        username: profileformValue.username,
        mobileNumber: profileformValue.mobileNumber
      };

      var data = {
        apiUrl: apiService.update_profile,
        payload: obj,
      };
      console.log(data, "====data===");
      setprofileLoader(true);
      var resp = await postMethod(data);
      if (resp.status) {
        setprofileLoader(false);
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    } else {
      // toast.error("Invalid fields");
    }
    if (mobilevalidateref.current == false) {
      var obj = {
        username: profileformValue.username,
        mobileNumber: profileformValue.mobileNumber
      };

      var data = {
        apiUrl: apiService.update_profile,
        payload: obj,
      };
      console.log(data, "====data===");
      setprofileLoader(true);
      var resp = await postMethod(data);
      if (resp.status) {
        setprofileLoader(false);
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    }
  };

  const profile_validate = async (values) => {
    const errors = {};
    if (values.username == "") {
      setusernamevalidate(true)
      errors.username = "User name is required"
    }
    else {
      setusernamevalidate(false)
      errors.username = ""
    }

    if (values.mobileNumber == "") {
      errors.mobileNumber = "Mobile Number is a required field";
      setmobilevalidate(true);
    }
    else {
      setmobilevalidate(false);
      errors.mobileNumber = "";
    }
    setvalidationnErr(errors);
    return errors;
  };


  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">Account Edit</h1>
                    </div>
                  </div>
                </div>
                <div className="card m-0 table_card_new ">
                  <div class="tab-content">
                    <div className="card p-0 mt-0">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="Heading">
                            <h2 className="headei_a mt-4">Edit Your Account</h2>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form_chaon_pass">
                            <form>
                              <div className="form-group">
                                <label>Username</label>
                                <div className="postion_reletitt">
                                  <input
                                    type="text"
                                    placeholder="Username"
                                    className="form-control"
                                    name="username"
                                    value={username}
                                    onChange={handleChange_profile}
                                  />
                                </div>
                                <div>

                                  {usernamevalidateref.current == true ? (

                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.username}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                              </div>
                              {/* <div className="form-group">
                                <label>Mobile Number</label>
                                <div className="postion_reletitt">
                                  <input
                                    type="text"
                                    placeholder="Mobile Number"
                                    className="form-control"
                                    name="mobileNumber"
                                    value={mobileNumber}
                                    onChange={handleChange_profile}
                                  />
                                </div>
                                <div>
                                    {mobilevalidateref.current == true ? (
                                      <p className="text-danger">
                                        {" "}
                                        {validationnErr.mobileNumber}{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                              </div> */}

                              <div className="submit_i">
                                <Button onClick={profileSubmit}>Confirm modification</Button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
