import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Count from "./Countdown";
// import Dropdown from "react-dropdown";
// import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Moment from "moment";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import Select from "react-select";
import {Dropdown} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import moment from "moment";
import useStateRef from "react-usestateref";
function Home() {
  const navigate = useNavigate();
  //const options = ["one", "two", "three"];
  const options = [
    {value: "chocolate", label: "Chocolate"},
    {value: "strawberry", label: "Strawberry"},
    {value: "vanilla", label: "Vanilla"},
  ];
  const friendOptions = [
    {
      key: "Jenny Hess",
      text: "Jenny Hess",
      value: "Jenny Hess",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/jenny.jpg",
      },
    },
    {
      key: "Elliot Fu",
      text: "Elliot Fu",
      value: "Elliot Fu",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/elliot.jpg",
      },
    },
    {
      key: "Stevie Feliciano",
      text: "Stevie Feliciano",
      value: "Stevie Feliciano",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/stevie.jpg",
      },
    },
    {
      key: "Christian",
      text: "Christian",
      value: "Christian",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/christian.jpg",
      },
    },
    {
      key: "Matt",
      text: "Matt",
      value: "Matt",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/matt.jpg",
      },
    },
    {
      key: "Justen Kitsune",
      text: "Justen Kitsune",
      value: "Justen Kitsune",
      image: {
        avatar: true,
        src: "https://react.semantic-ui.com/images/avatar/small/justen.jpg",
      },
    },
  ];

  const [fromTab, setFromTab] = useState([]);
  const [toTab, setToTab] = useState([]);
  const [fromCurrency, setfromCurrency, fromref] = useState("");
  const [toCurrency, settoCurrency, toref] = useState("USDT");
  const [appendFromData, setappendFromData] = useState("");
  const [appendToData, setappendFToData] = useState("");
  const [fromcurrencyImage, setFromcurrencyImage] = useState("");
  const [tocurrencyImage, setTocurrencyImage] = useState("");
  const [swapTab, setswapTab] = useState(false);
  const [fromAmount, setfromAmount] = useState(0);
  const [toAmount, settoAmount] = useState(0);
  const [minMax, setMinMax] = useState(10);
  const [price, setPrice, priceRef] = useStateRef(0);
  const [estimateFee, setEstimationFee] = useState(0);
  const [totatlAmount, setTotalAmount] = useState(0);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [kycStatus, setkycStatus, kycstatusref] = useState(0);
  const [ButtonLoader, setButtonLoader] = useState(false);

  const [allCurrencyFiat, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrencyRefs, setToCurrencyRef, toCurrencyRef] = useState([]);

  const [fromSwap, setfromSwapRef, fromSwapRef] = useState([]);
  const [toSwap, settoSwapRef, toSwapRef] = useState([]);

  const recordPerPage = 5;
  const pageRange = 5;

  useEffect(() => {
    getUserbalance();
    swaphistory();
    getKYCstatus();
    getSiteSettingstatus();
  }, [0]);

  const onSelect = async (option, type) => {
    console.log("call onselect====", option);
    console.log("call type====", type);
    if (type == "fromTab") {
      var findIndexing = fromTab.findIndex((x) => x.currid == option.value);
      console.log("findIndexing===", findIndexing);
      if (findIndexing != -1) {
        setappendFromData(fromTab[findIndexing]);
        setFromcurrencyImage(fromTab[findIndexing].image);
        setfromCurrency(fromTab[findIndexing].currencySymbol);
        swapPrice();
        // setAmount("","");
      }
    } else if (type == "toTab") {
      var findIndexingTo = fromTab.findIndex((x) => x.currid == option.value);
      if (findIndexing != -1) {
        settoCurrency(fromTab[findIndexingTo].currencySymbol);
        setappendFToData(fromTab[findIndexingTo]);
        setTocurrencyImage(fromTab[findIndexingTo].image);
        swapPrice();
        // setAmount("","");
      }
    }
  };

  const getUserbalance = async () => {
    var data1 = {
      apiUrl: apiService.getUserBalanceSwap,
    };
    var resp = await getMethod(data1);
    console.log(resp, "resp");
    if (resp.status) {
      setFromTab(resp.data);
      setToTab(resp.data);
      var currArrayCrypto = [];
      //var currArrayFiat = [{value: "all", label: "Select"}];
      var currArrayFiat = [];
      var data = resp.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].currencySymbol) {
          var obj = {
            value: data[i].currid,
            label: data[i].currencySymbol,
            key: data[i].currencySymbol,
            text: data[i].currencySymbol,
            image: {
              avatar: true,
              src: data[i].image,
            },
          };
          currArrayFiat.push(obj);
          currArrayCrypto.push(obj);
        }
      }
      setToCurrencyRef(currArrayFiat);
      setfromCurrencyRef(currArrayCrypto);
    } else {
    }
  };

  const defaulatCurrFiat = fromCurrencyRef.current[1];
  const defaulatCurrTo = toCurrencyRef.current[3];

  const swaping = async () => {
    try {
      console.log("interchangeble");
    } catch (error) {}
  };

  const setAmount = async (value, type) => {
    console.log("value", value, "type", type);
    console.log(appendFromData, "appendFromData");
    try {
      if (!isNaN(value)) {
        type == "fromAmount" ? setfromAmount(value) : settoAmount(value);
        var obj = {
          from: appendFromData.currencySymbol,
          to: appendToData.currencySymbol,
        };
        var data = {
          apiUrl: apiService.currencyConversion,
          payload: obj,
        };
        if (fromCurrency != "" && toCurrency != "") {
          var resp = await postMethod(data);
          if (resp.status) {
            var fee = (+value * +appendFromData.swapFee) / 100;
            console.log("fee===", fee);
            setEstimationFee(fee);
            var total = +value + +fee;
            console.log("total===", total);
            //setTotalAmount(parseFloat(total).toFixed(8));
            setTotalAmount(total);

            setPrice(resp.Message);
            console.log("price===", resp.Message);
            if (type == "fromAmount") {
              var amount = Number(resp.Message) * Number(value);
              console.log("amount===", amount);
              // setfromAmount(parseFloat(value).toFixed(8));
              // settoAmount(parseFloat(amount).toFixed(8));
              setfromAmount(value);
              settoAmount(amount);
            } else if (type == "toAmount") {
              var amount = Number(value) / Number(resp.Message);
              // setfromAmount(parseFloat(amount).toFixed(8));
              // settoAmount(parseFloat(value).toFixed(8));
              setfromAmount(amount);
              settoAmount(value);
            }
          }
        } else {
        }
      } else {
        type == "fromAmount" ? setfromAmount(0) : settoAmount(0);
      }
    } catch (error) {}
  };

  const swapAmount = async () => {
    console.log(appendFromData.currencySymbol, appendToData.currencySymbol);
    try {
      if (
        appendFromData.currencySymbol != undefined &&
        appendToData.currencySymbol != undefined
      ) {
        if (appendFromData.currencySymbol != appendToData.currencySymbol) {
          if (fromAmount > 0 && toAmount > 0) {
            var obj = {
              from: appendFromData.currencySymbol,
              to: appendToData.currencySymbol,
              from_id: appendFromData.currid,
              to_id: appendToData.currid,
              fromAmount: +fromAmount,
              toAmount: +toAmount,
              fee: +estimateFee,
              withFee: +totatlAmount,
              currentPrice: +priceRef.current,
            };

            console.log(obj, "obj");

            var data = {
              apiUrl: apiService.swapping,
              payload: obj,
            };
            setButtonLoader(true);
            var resp = await postMethod(data);
            setButtonLoader(false);
            if (resp.status) {
              setfromAmount(0);
              settoAmount(0);
              swaphistory(1);
              getUserbalance();
              toast.success(resp.Message);
              navigate("/dashboard");
            } else {
              swaphistory(1);
              toast.error(resp.Message);
            }
          } else {
            toast.error("Please enter amount");
          }
        } else {
          toast.error("Same currency should not allow the swapping");
        }
      } else {
        toast.error("Please choose the swapping currencies");
      }
    } catch (error) {}
  };

  const swaphistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.swappingHistory,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const handlePageChange = (pageNumber) => {
    swaphistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const maxBalance = async () => {
    try {
      var fee =
        (+appendFromData.currencyBalance * +appendFromData.swapFee) / 100;
      var amount = appendFromData.currencyBalance - fee;
      setfromAmount(amount.toFixed(8));
      console.log("=-=-=-=-=-=-=-===-=maxBalance=-=-=-==-=-==-");
      setAmount(amount.toFixed(8), "fromAmount");
    } catch (error) {}
  };

  const swapPrice = async () => {
    try {
      console.log(toref.current);
      console.log(fromref.current);
      var obj = {
        from: fromref.current != undefined ? fromref.current : "BTC",
        to:
          toref.current != undefined ||
          toref.current != null ||
          toref.current != ""
            ? toref.current
            : "USDT",
      };
      console.log("swap===", obj);
      var data = {
        apiUrl: apiService.currencyConversion,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setPrice(resp.Message);
      }
    } catch (error) {}
  };

  const handleOnChange_from = (e, data) => {
    console.log("handleOnChange_from", data);
    setAmount();
    setfromSwapRef(data.value);
    var findIndexing = fromTab.findIndex((x) => x.currid == data.value);
    console.log("findIndexing===", findIndexing);
    if (findIndexing != -1) {
      console.log("fromTab[findIndexing]", fromTab[findIndexing]);
      setappendFromData(fromTab[findIndexing]);
      setFromcurrencyImage(fromTab[findIndexing].image);
      setfromCurrency(fromTab[findIndexing].currencySymbol);
      swapPrice();
    }
  };

  const handleOnChange_to = (e, data) => {
    setAmount();
    console.log("handleOnChange_to", data);
    settoSwapRef(data.value);
    var findIndexingTo = toTab.findIndex((x) => x.currid == data.value);
    console.log("findIndexingTo===", findIndexingTo);
    if (findIndexingTo != -1) {
      settoCurrency(fromTab[findIndexingTo].currencySymbol);
      setappendFToData(fromTab[findIndexingTo]);
      setTocurrencyImage(fromTab[findIndexingTo].image);
      swapPrice();
    }
  };

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);
    console.log(getKYC, "getkyc");
    if (getKYC.status == true) {
      console.log(getKYC.Message.kycstatus, "getkyc");

      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc1===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======");
      setsitekycStatus(response.data.kycStatus);
    } else {
      setkycStatus(0);
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="container contaier_padine">
                <div className="card add_padin_card ml-0 mr-0">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <h1 className="bard_tirlrr">Swap</h1>
                    </div>
                  </div>
                </div>

                {sitekycStatus == "Active" ? (
                  kycstatusref.current == 1 ? (
                    <>
                      <div className="card m-0 table_card_new ">
                        <div className="row justify-content-center">
                          <div className="col-lg-10">
                            <div className="row justify-content-center">
                              <div className="col-lg-6 ">
                                <div className="seap_form_">
                                  <div className="form_input_section">
                                    <p>
                                      From{" "}
                                      <span>
                                        Balance:{" "}
                                        {appendFromData == ""
                                          ? "0.0000"
                                          : Number(
                                              appendFromData.currencyBalance
                                            ).toFixed(8)}{" "}
                                      </span>
                                    </p>
                                    <div className="input_seclect input_select_internal">
                                      <input
                                        type="number"
                                        min="0"
                                        value={fromAmount}
                                        onChange={(e) =>
                                          setAmount(
                                            e.target.value,
                                            "fromAmount"
                                          )
                                        }
                                      />
                                      <Dropdown
                                        placeholder="Select Coin"
                                        fluid
                                        selection
                                        options={fromCurrencyRef.current}
                                        // onChange={(o) =>
                                        //   onSelect(o, "fromTab")
                                        // }
                                        onChange={handleOnChange_from}
                                        className="tex_clas_ inttrans"
                                      />
                                    </div>
                                  </div>
                                  <div className="icon_transdfer">
                                    <span className="bi bi-arrow-left-right"></span>
                                  </div>
                                  <div className="form_input_section">
                                    <p>
                                      To <span></span>
                                    </p>
                                    <div className="input_seclect input_select_internal">
                                      <input
                                        type="nmber"
                                        value={toAmount.toFixed(4)}
                                        onChange={(e) =>
                                          setAmount(e.target.value, "toAmount")
                                        }
                                      />
                                      <Dropdown
                                        placeholder="Select Coin"
                                        fluid
                                        selection
                                        options={toCurrencyRef.current}
                                        //onChange={(o) => onSelect(o, "toTab")}
                                        onChange={handleOnChange_to}
                                        className="tex_clas_ inttrans"
                                      />
                                    </div>
                                  </div>
                                  <div className="submit_btn mt-4 justify-content-center">
                                    {ButtonLoader == false ? (
                                      <Button onClick={swapAmount}>Swap</Button>
                                    ) : (
                                      <Button>Loading</Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div class="summer_swap">
                                  <h4>Summary</h4>
                                  <p>
                                    <span>Price</span>
                                    {/* <span> 1 ETH = 1568.3200 USDT </span> */}
                                    {fromref.current != "" &&
                                    toref.current != "" ? (
                                      <span>
                                        {" "}
                                        1 {fromref.current} ={" "}
                                        {Number(priceRef.current).toFixed(8) ==
                                        0 ? (
                                          <span>0</span>
                                        ) : (
                                          Number(priceRef.current).toFixed(8)
                                        )}{" "}
                                        {toref.current}{" "}
                                      </span>
                                    ) : (
                                      <span>0.0000</span>
                                    )}
                                  </p>
                                  <p>
                                    <span>Minimum Swap</span>
                                    <span>
                                      {" "}
                                      {appendFromData == "" ? (
                                        <span>0.0000</span>
                                      ) : (
                                        appendFromData.minSwap
                                      )}{" "}
                                    </span>
                                  </p>
                                  <p>
                                    <span>Maximum Swap</span>
                                    <span>
                                      {" "}
                                      {appendFromData == "" ? (
                                        <span>0.0000</span>
                                      ) : (
                                        appendFromData.maxSwap
                                      )}{" "}
                                    </span>
                                  </p>
                                  <p>
                                    <span>
                                      Swap fee (
                                      {appendFromData == ""
                                        ? "0.0%"
                                        : appendFromData.swapFee}{" "}
                                      )
                                    </span>
                                    <span>
                                      <span>
                                        {" "}
                                        {estimateFee == 0 ? (
                                          <span>0.0000</span>
                                        ) : (
                                          Number(estimateFee).toFixed(8)
                                        )}{" "}
                                      </span>
                                    </span>
                                  </p>
                                  <p>
                                    <span>Total Amount</span>
                                    <span>
                                      {" "}
                                      {totatlAmount == 0 ? (
                                        <span>0.0000</span>
                                      ) : (
                                        Number(totatlAmount).toFixed(4)
                                      )}{" "}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-10px internaltransfer_card">
                        <div className="card p-0 mt-0 internaltransfer_card2">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="Heading">
                                <h2>Recent Transfer Records</h2>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="table-responsive mt-4 history_table">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>S.No</th>
                                      <th>Date / Time</th>
                                      <th>From Currency</th>
                                      <th>To Currency</th>
                                      <th>Amount</th>
                                      <th>Price</th>
                                      <th>Total Amount</th>
                                      <th>Fee</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {sessionHistory.length > 0 ? (
                                      sessionHistory &&
                                      sessionHistory.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              {moment(item.createdDate).format(
                                                "lll"
                                              )}
                                            </td>
                                            <td>{item.fromCurrency}</td>
                                            <td>{item.toCurrency}</td>
                                            <td>
                                              {parseFloat(item.amount).toFixed(
                                                4
                                              )}{" "}
                                              {""} {item.fromCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(item.price).toFixed(
                                                4
                                              )}{" "}
                                              {""} {item.toCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(
                                                item.totalAmount
                                              ).toFixed(6)}{" "}
                                              {""} {item.fromCurrency}
                                            </td>
                                            <td>
                                              {parseFloat(item.fee).toFixed(6)}{" "}
                                              {""} {item.fromCurrency}
                                            </td>
                                          </tr>
                                          // <tr>
                                          //   <td>20:20:45</td>
                                          //   <td>BTC</td>
                                          //   <td>1000 INR</td>
                                          //   <td>BSC</td>
                                          //   <td>
                                          //     <span class="hash_code">
                                          //       3423423423423vrfsfsdfe55345dfg454
                                          //     </span>
                                          //   </td>
                                          //   <td>ID0922201</td>
                                          //   <td>
                                          //     <span className="text-red">Canceled</span>
                                          //   </td>
                                          // </tr>
                                          // <tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan="8">
                                          <div className="norecode_found">
                                            <img
                                              src={require("../img/newimg/banksearch.png")}
                                            />
                                            <span>No records found</span>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>

                                {sessionHistory && sessionHistory.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item" // add it for bootstrap 4
                                    linkClass="page-link" // add it for bootstrap 4
                                    activePage={currentPage}
                                    itemsCountPerPage={recordPerPage}
                                    totalItemsCount={totalPage}
                                    pageRangeDisplayed={pageRange}
                                    onChange={handlePageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="card m-0 table_card_new ">
                      <div className="row justify-content-center">
                        <div className="row">
                          <div className="col-lg-12">
                            <img
                              src={require("../img/newimg/6306471.png")}
                              className="img-fluid warning_ing"
                            />
                            <div className="warning_content">
                              <h2>
                                Please complete the KYC verification process to
                                gain access to the page.
                              </h2>
                              <Link to="/kyc">Verify KYC Now</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <>
                    <div className="card m-0 table_card_new ">
                      <div className="row justify-content-center">
                        <div className="col-lg-10">
                          <div className="row justify-content-center">
                            <div className="col-lg-6 ">
                              <div className="seap_form_">
                                <div className="form_input_section">
                                  <p>
                                    From{" "}
                                    <span>
                                      Balance:{" "}
                                      {appendFromData == ""
                                        ? "0.0000"
                                        : Number(
                                            appendFromData.currencyBalance
                                          ).toFixed(8)}{" "}
                                    </span>
                                  </p>
                                  <div className="input_seclect input_select_internal">
                                    <input
                                      type="number"
                                      min="0"
                                      value={fromAmount}
                                      onChange={(e) =>
                                        setAmount(e.target.value, "fromAmount")
                                      }
                                    />
                                    <Dropdown
                                      placeholder="Select Coin"
                                      fluid
                                      selection
                                      options={fromCurrencyRef.current}
                                      // onChange={(o) =>
                                      //   onSelect(o, "fromTab")
                                      // }
                                      onChange={handleOnChange_from}
                                      className="tex_clas_ inttrans"
                                    />
                                  </div>
                                </div>
                                <div className="icon_transdfer">
                                  <span className="bi bi-arrow-left-right"></span>
                                </div>
                                <div className="form_input_section">
                                  <p>
                                    To <span></span>
                                  </p>
                                  <div className="input_seclect input_select_internal">
                                    <input
                                      type="nmber"
                                      value={toAmount.toFixed(4)}
                                      onChange={(e) =>
                                        setAmount(e.target.value, "toAmount")
                                      }
                                    />
                                    <Dropdown
                                      placeholder="Select Coin"
                                      fluid
                                      selection
                                      options={toCurrencyRef.current}
                                      //onChange={(o) => onSelect(o, "toTab")}
                                      onChange={handleOnChange_to}
                                      className="tex_clas_ inttrans"
                                    />
                                  </div>
                                </div>
                                <div className="submit_btn mt-4 justify-content-center">
                                  {ButtonLoader == false ? (
                                    <Button onClick={swapAmount}>Swap</Button>
                                  ) : (
                                    <Button>Loading</Button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div class="summer_swap">
                                <h4>Summary</h4>
                                <p>
                                  <span>Price</span>
                                  {/* <span> 1 ETH = 1568.3200 USDT </span> */}
                                  {fromref.current != "" &&
                                  toref.current != "" ? (
                                    <span>
                                      {" "}
                                      1 {fromref.current} ={" "}
                                      {Number(priceRef.current).toFixed(8) ==
                                      0 ? (
                                        <span>0</span>
                                      ) : (
                                        Number(priceRef.current).toFixed(8)
                                      )}{" "}
                                      {toref.current}{" "}
                                    </span>
                                  ) : (
                                    <span>0.0000</span>
                                  )}
                                </p>
                                <p>
                                  <span>Minimum Swap</span>
                                  <span>
                                    {" "}
                                    {appendFromData == "" ? (
                                      <span>0.0000</span>
                                    ) : (
                                      appendFromData.minSwap
                                    )}{" "}
                                  </span>
                                </p>
                                <p>
                                  <span>Maximum Swap</span>
                                  <span>
                                    {" "}
                                    {appendFromData == "" ? (
                                      <span>0.0000</span>
                                    ) : (
                                      appendFromData.maxSwap
                                    )}{" "}
                                  </span>
                                </p>
                                <p>
                                  <span>
                                    Swap fee (
                                    {appendFromData == ""
                                      ? "0.0%"
                                      : appendFromData.swapFee}{" "}
                                    )
                                  </span>
                                  <span>
                                    <span>
                                      {" "}
                                      {estimateFee == 0 ? (
                                        <span>0.0000</span>
                                      ) : (
                                        Number(estimateFee).toFixed(8)
                                      )}{" "}
                                    </span>
                                  </span>
                                </p>
                                <p>
                                  <span>Total Amount</span>
                                  <span>
                                    {" "}
                                    {totatlAmount == 0 ? (
                                      <span>0.0000</span>
                                    ) : (
                                      Number(totatlAmount).toFixed(4)
                                    )}{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mt-10px internaltransfer_card">
                      <div className="card p-0 mt-0 internaltransfer_card2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Heading">
                              <h2>Recent Transfer Records</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive mt-4 history_table">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Date / Time</th>
                                    <th>From Currency</th>
                                    <th>To Currency</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                    <th>Total Amount</th>
                                    <th>Fee</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {sessionHistory.length > 0 ? (
                                    sessionHistory &&
                                    sessionHistory.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>{i + 1}</td>
                                          <td>
                                            {moment(item.createdDate).format(
                                              "lll"
                                            )}
                                          </td>
                                          <td>{item.fromCurrency}</td>
                                          <td>{item.toCurrency}</td>
                                          <td>
                                            {parseFloat(item.amount).toFixed(4)}{" "}
                                            {""} {item.fromCurrency}
                                          </td>
                                          <td>
                                            {parseFloat(item.price).toFixed(4)}{" "}
                                            {""} {item.toCurrency}
                                          </td>
                                          <td>
                                            {parseFloat(
                                              item.totalAmount
                                            ).toFixed(6)}{" "}
                                            {""} {item.fromCurrency}
                                          </td>
                                          <td>
                                            {parseFloat(item.fee).toFixed(6)}{" "}
                                            {""} {item.fromCurrency}
                                          </td>
                                        </tr>
                                        // <tr>
                                        //   <td>20:20:45</td>
                                        //   <td>BTC</td>
                                        //   <td>1000 INR</td>
                                        //   <td>BSC</td>
                                        //   <td>
                                        //     <span class="hash_code">
                                        //       3423423423423vrfsfsdfe55345dfg454
                                        //     </span>
                                        //   </td>
                                        //   <td>ID0922201</td>
                                        //   <td>
                                        //     <span className="text-red">Canceled</span>
                                        //   </td>
                                        // </tr>
                                        // <tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="8">
                                        <div className="norecode_found">
                                          <img
                                            src={require("../img/newimg/banksearch.png")}
                                          />
                                          <span>No records found</span>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>

                              {sessionHistory && sessionHistory.length > 0 ? (
                                <Pagination
                                  itemClass="page-item" // add it for bootstrap 4
                                  linkClass="page-link" // add it for bootstrap 4
                                  activePage={currentPage}
                                  itemsCountPerPage={recordPerPage}
                                  totalItemsCount={totalPage}
                                  pageRangeDisplayed={pageRange}
                                  onChange={handlePageChange}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
