import React, {useEffect, useState} from "react";
import Newsideheader from "../Component/Header";
import Footer from "./Footer";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import DOMPurify from "dompurify";
export default function TearmsCondition() {
  const [currencyCount, setcurrencyCount] = useState({content_description: 1});
  useEffect(() => {
    viewMoreCurrency();
  }, []);
  const viewMoreCurrency = async () => {
    var data = {
      apiUrl: apiService.getContent,
      payload: {title: "About Us"},
    };
    var resp = await postMethod(data);
    if (resp) {
      console.log(resp, "=-=-=-=currencyCount");
      setcurrencyCount(resp.data);
    } else {
    }
  };
  return (
    <>
      <Newsideheader />
      <main class="main_terms_condition_policy">
        <div className="terms_contition">
          <h1>{currencyCount.title}</h1>
          <div className="container">
            <div className="row justify-center">
              <div className="col-lg-10">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      currencyCount.content_description
                    ),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
