import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button, formatMs} from "@material-ui/core";
import {Link} from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, {Range} from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import TransactionNew from "./Transactionnew";
import Newsideheader from "./Newsideheader";
import Pagination from "react-js-pagination";
import {toast} from "react-toastify";

import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";

import {env} from "../core/service/envconfig";
import Loader from "../Component/Loader";

function Home() {
  const options = ["one", "two", "three"];

  const [idproof, setidproof, idproofref] = useState("");
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);

  const [addressProof, setaddressProof, addressProofref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const [addresIDFront, setaddresIDFront] = useState("");
  const [validaddresIDFront, setvalidaddresIDFront] = useState(0);
  const [addresIDFrontLoad, setaddresIDFrontLoad] = useState(false);

  const [addresIDBack, setaddresIDBack] = useState("");
  const [validaddresIDBack, setvalidaddresIDBack] = useState(0);
  const [addresIDBackLoad, setaddresIDBackLoad] = useState(false);

  const [photoproof, setphotoproof, photoproofref] = useState("");
  const [validphotoproof, setvalidphotoproof] = useState(0);
  const [photoproofLoad, setphotoproofLoad] = useState(false);
  const [docImage, setdocImage] = useState("");
  const [getKYCData, setgetKYCData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [kycLoader, setkycLoader] = useState(false);
  const value = {
    idNumber: "",
    AadhaarNumber: "",
  };
  const [formValue, setformValue] = useState(value);

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        type == "idproof"
          ? setidproofLoad(true)
          : type == "addressProof"
          ? setaddressProofLoad(true)
          : type == "addressProofFront"
          ? setaddresIDFrontLoad(true)
          : type == "addressProofBack"
          ? setaddresIDBackLoad(true)
          : setphotoproofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          {method: "post", body: data}
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "idproof") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }
              setidproof(data.secure_url);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressProof(data.secure_url);
            }
            if (type == "photoproof") {
              setphotoproofLoad(false);
              setvalidphotoproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidphotoproof(1);
              }
              setphotoproof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const cancelData = () => {
    try {
      setidproof("");
      setaddressProof("");
      setphotoproof("");
      setvalididproof(0);
      setvalidaddressProof(0);
      setvalidphotoproof(0);
    } catch (error) {}
  };
  const uploadKYC = async () => {
    try {
      if (
        idproofref.current != "" &&
        addressProofref.current != "" &&
        photoproofref.current != ""
      ) {
        var obj = {
          addressProof: idproofref.current,
          idproof: addressProofref.current,
          photoproof: photoproofref.current,
        };

        var data = {
          apiUrl: apiService.savekyc,
          payload: obj,
        };
        setkycLoader(true);
        var resp = await postMethod(data);
        if (resp.status) {
          setkycLoader(false);
          getKyc();
          toast.success(resp.Message);
        } else {
          toast.error(resp.Message);
          setkycLoader(false);
        }
      } else {
        toast.error("Please give all proof");
      }
    } catch (error) {}
  };

  const getKyc = async () => {
    try {
      setdocImage(
        "https://res.cloudinary.com/dkojazpdl/image/upload/v1675867828/mhortkfept36zyx0f1ml.png"
      );
      var data = {
        apiUrl: apiService.getKYC,
      };
      setSiteLoader(false);
      var resp = await getMethod(data);
      setSiteLoader(true);
      if (resp.status) {
        if (resp.datas.kycDetails) {
          setvalididproof(2);
          setvalidaddressProof(2);
          setvalidaddresIDFront(2);
          setvalidaddresIDBack(2);
          setvalidphotoproof(2);
        }
        if (Object.keys(resp.datas.kycDetails).length > 0) {
          var kycData = resp.datas.kycDetails;
          setgetKYCData(kycData);
          console.log(kycData.proof1,"kycData.proof1")
          setidproof(kycData.proof1);
          console.log(idproofref.current,"kycData.proof1")

          formValue.idNumber = kycData.IdNumber;
          setaddressProof(kycData.proof2);
          setaddresIDFront(kycData.proof3);
          setaddresIDBack(kycData.proof4);
          setphotoproof(kycData.proof3);
          var filetype_front = kycData.proof1.split(".").pop().trim();
          var filetype_back = kycData.proof2.split(".").pop().trim();
          var filetype_photoproof = kycData.proof3.split(".").pop().trim();
          if (
            filetype_front == "pdf" ||
            filetype_front == "doc" ||
            filetype_front == "docx"
          ) {
            setvalididproof(1);
          }
          if (
            filetype_back == "pdf" ||
            filetype_back == "doc" ||
            filetype_back == "docx"
          ) {
            setvalidaddressProof(1);
          }
          if (
            filetype_photoproof == "pdf" ||
            filetype_photoproof == "doc" ||
            filetype_photoproof == "docx"
          ) {
            setvalidphotoproof(1);
          }

          if (kycData.kycStatus == 3) {
            setvalididproof(2);
            setvalidaddressProof(2);
            setvalidphotoproof(2);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getKyc();
  }, [0]);

  const getValue = (e) => {
    e.preventDefault();
    const {name, value} = e.target;
    let formData = {...formValue, ...{[name]: value}};
    setformValue(formData);
  };
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            {siteLoader == false ? (
              <Loader />
            ) : (
              <div className="content-wrapper">
                <div className="container">
                  <div className="card add_padin_card ml-0 mr-0">
                    <div className="row">
                      <div className="col-lg-6 p-0">
                        <h1 className="bard_tirlrr">KYC Verification</h1>
                      </div>
                      <div className="col-lg-6 ">
                        {getKYCData.kycStatus == 3 ? (
                          <Button className="kyc_status Rejected kyc_rejected">
                            <i class="bi bi-ban"></i>
                            Rejected
                          </Button>
                        ) : getKYCData.kycStatus == 2 ? (
                          <Button className="kyc_status pending kyc_pending">
                            <i class="bi bi-arrow-clockwise"></i>
                            Pending
                          </Button>
                        ) : getKYCData.kycStatus == 1 ? (
                          <Button className="kyc_status accepeted kyc_verified">
                            <i class="bi bi-check-circle"></i>
                            verified
                          </Button>
                        ) : (
                          <Button className="kyc_status notverified not_upload_button">
                            <i class="bi bi-x-circle"></i>
                            Not Upload
                          </Button>
                        )}
                      </div>

                      {getKYCData.kycStatus == 3 ? (
                        <div className="col-lg-12 p-0">
                          <span className="reason">
                            {" "}
                            {getKYCData.rejectReson}{" "}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="card mt-0 kyc_card">
                    <div className="row">
                      <div class="col-lg-12">
                        <div class="form_tite">
                          <p> Upload Proof Of Identity</p>
                          <span>
                            File types supported: JPG, PNG,JPEG
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {/* ----------------------------- Passport--------------------------- */}
                        <form>
                          <div className="row mt-4">
                            <div className="col-lg-4 col-sm-6 mb-3">
                              <div class="form-group new_kyc">
                                <h6 className="text-center text-white h4">
                                  Goverment Issued ID
                                </h6>{" "}
                                <br />
                                <label className="text-center">
                                  The customer is required to upload one of the
                                  following types of documents: Passport,
                                  Driver's License, National ID Card, Residence
                                  Permit, Voter ID.
                                </label>
                                <div class="uppload_file_ kyc_file_upload">
                                  <input
                                    type="file"
                                    name="image"
                                    onChange={(e) =>
                                      imageUpload("idproof", e.target.files[0])
                                    }
                                  />
                                  <div class="input_content">
                                    {idproofLoad == false ? (
                                      valididproof == 0 ? (
                                        <span>
                                          {" "}
                                          <i class="bi bi-cloud-arrow-up kyc_icon"></i>{" "}
                                        </span>
                                      ) : valididproof == 1 ? (
                                        <span className="">
                                          <i class="bi bi-file-earmark-bar-graph"></i>
                                        </span>
                                      ) : (
                                        <img
                                          src={idproofref.current}
                                          className="img-fluid"
                                          alt="National Id Front"
                                        />
                                      )
                                    ) : (
                                      <div
                                        class="spinner-border spinner_kyc"
                                        role="status"
                                      >
                                        <i
                                          class="fa fa-spinner d-none"
                                          style={{color: "green"}}
                                        ></i>{" "}
                                      </div>
                                    )}
                                    {idproofref.current == "" ? (
                                      <>
                                        <p>Drop your file upload or Browse</p>
                                        <small>Maximum size of image 1MB</small>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-3">
                              <div class="form-group new_kyc">
                                <h6 className="text-center text-white h4">
                                  Proof Of Address
                                </h6>{" "}
                                <br />
                                <label className="text-center">
                                  The customer is required to upload one of the
                                  following types of documents: Utility
                                  Bills,Bank Statements,Official Government
                                  Documents , Insurance Documentst,Tax
                                  Statements
                                </label>
                                <div class="uppload_file_ kyc_file_upload">
                                  <input
                                    type="file"
                                    name="image"
                                    onChange={(e) =>
                                      imageUpload(
                                        "addressProof",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  <div class="input_content">
                                    {addressProofLoad == false ? (
                                      validaddressProof == 0 ? (
                                        <span>
                                          {" "}
                                          <i class="bi bi-cloud-arrow-up kyc_icon"></i>{" "}
                                        </span>
                                      ) : validaddressProof == 1 ? (
                                        <span className="">
                                          <i class="bi bi-file-earmark-bar-graph"></i>
                                        </span>
                                      ) : (
                                        <img
                                          src={addressProofref.current}
                                          className="img-fluid"
                                          alt="National Id Front"
                                        />
                                      )
                                    ) : (
                                      <div
                                        class="spinner-border spinner_kyc"
                                        role="status"
                                      >
                                        <i
                                          class="fa fa-spinner d-none"
                                          style={{color: "white"}}
                                        ></i>{" "}
                                      </div>
                                    )}
                                    {addressProofref.current == "" ? (
                                      <>
                                        <p>Drop your file upload or Browse</p>
                                        <small>Maximum size of image 1MB</small>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-3">
                              <div class="form-group new_kyc">
                                <h6 className="text-center text-white h4">
                                  Photoproof Verification
                                </h6>{" "}
                                <br />
                                <label className="text-center">
                                  Users are requested to submit a formal
                                  photoproof while holding any valid
                                  identification document.
                                </label>
                                <div class="uppload_file_ kyc_file_upload">
                                  <input
                                    type="file"
                                    name="image"
                                    onChange={(e) =>
                                      imageUpload(
                                        "photoproof",
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                  <div class="input_content">
                                    {photoproofLoad == false ? (
                                      validphotoproof == 0 ? (
                                        <span>
                                          {" "}
                                          <i class="bi bi-cloud-arrow-up kyc_icon"></i>{" "}
                                        </span>
                                      ) : validphotoproof == 1 ? (
                                        <span className="">
                                          <i class="bi bi-file-earmark-bar-graph"></i>
                                        </span>
                                      ) : (
                                        <img
                                          src={photoproofref.current}
                                          className="img-fluid"
                                          alt="National Id Front"
                                        />
                                      )
                                    ) : (
                                      <div
                                        class="spinner-border spinner_kyc"
                                        role="status"
                                      >
                                        <i
                                          class="fa fa-spinner d-none"
                                          style={{color: "white"}}
                                        ></i>{" "}
                                      </div>
                                    )}

                                    {photoproofref.current == "" ? (
                                      <>
                                        <p>Drop your file upload or Browse</p>
                                        <small>Maximum size of image 1MB</small>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        {/* -----------------------------Passport END--------------------------- */}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div class="col-lg-12"></div>
                    </div>

                    <hr />
                    {getKYCData.kycStatus == 2 || getKYCData.kycStatus == 1 ? (
                      ""
                    ) : (
                      <div className="footer_button">
                        <Button
                          onClick={cancelData}
                          className="cancel cancel_kyc"
                        >
                          Cancel
                        </Button>
                        {kycLoader == false ? (
                          <Button className="confirm" onClick={uploadKYC}>
                            Upload
                          </Button>
                        ) : (
                          <Button className="confirm">Loading...</Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
