import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Header from "./Header";
import {Button} from "@material-ui/core";

import {socket} from "./context/socket";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
function Footer() {
  const [siteData, setSiteData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    socket.connect();
    fetchTfaData();

    socket.on("sitesettings", function (res) {
      console.log(res);
      fetchTfaData();
    });

    var token = localStorage.getItem("user_token");
    if (token) {
      setauthToken(token);
    } else {
      setauthToken("");
    }
  }, [0]);

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      setSiteData(resp.data);
      if (resp.data.undermaintenenceStatus == "DeActive") {
        console.log(resp.data, "=--=-=-=-=-=resp=-=-");
        navigate("/undermaintanance");
        localStorage.setItem("sitestats", resp.data.undermaintenenceStatus);
      } else {
        setSiteData(resp.data);
      }
    } catch (error) {}
  };
  const [authToken, setauthToken] = useState("");

  // /get_sitedata
  return (
    <div className="center_textxx">
      {/* <div className="cta-trading-now">
        <h2 class="has-text-align-center">
          Start <span class="blue">Trading</span> Now
        </h2>
      </div>

      <div className="row justify-center">
        <div className="col-lg-6 d-flex justify-center">
          <div className="phone_number donwload-form">
            <PhoneInput
              placeholder="Enter phone number"
              value={value}
              onChange={setValue}
            />
            <Button className="btn--blue footer_roeund ">
              <span>
                <i class="bi bi-arrow-right"></i>
              </span>
            </Button>
          </div>
        </div>
      </div> */}
      <footer className="rn-footer-one bg-color--1- rn-section-gap  mt_md--80 mt_sm--80">
        <div className="container">
          <div className="row justify-center">
            <div className="col-lg-8">
              <div className="row mb-3">
                {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="widget-content-wrapper">
                    <div className="footer-left"> */}

                {/* <div className="logo-thumbnail logo-custom-css">
                        <a href="" className="logo-light">
                          <img
                            src={require("../img/newimg/logo_symbol.png")}
                            className="logo darktheme footerlogos"
                          />
                          <img
                            src={require("../img/newimg/logo_symbol.png")}
                            className="logo lighttheme footerlogos"
                          />
                        </a>
                      </div> */}

                {/* <div className="footer_newsform">
                      <img
                            src={require("../img/newimg/logo-headerr.png")}
                          
                          />
                        <p className="dfoodsss">
                          A next-gen Blockchain and Crypto Exchange platform
                          that offers trader with an endless assets buy and
                          selling feature with real money.
                        </p>
                      </div> */}

                {/* </div>
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="row justify-content-end align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt_mobile--40">
                      <div className="footer-widget widget-quicklink">
                        <h6 className="widget-title">About Us</h6>
                        <ul className="footer-list-one">
                          <li className="footer-menu">
                            <Link to="/about">About</Link>
                          </li>
                          <li className="footer-menu">
                            <Link to="/privacypolicy">Privacy Policy</Link>
                          </li>
                          <li className="footer-menu">
                            <Link to="/terms">Terms Of Use</Link>
                          </li>
                          <li className="footer-menu">
                            <Link to="/tradingfees">Trading Fees & Roles</Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {authToken == "" ? (
                      <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt_mobile--40">
                        <div className="footer-widget widget-quicklink">
                          <h6 className="widget-title">services</h6>
                          <ul className="footer-list-one">
                            <li className="footer-menu">
                              <Link to="/login">Swap</Link>
                            </li>
                            <li className="footer-menu">
                              <Link to="/trade/BTC_USDT">Exchange</Link>
                            </li>
                            <li className="footer-menu">
                              <Link to="/coming">Apply for listing</Link>
                            </li>
                            <li className="footer-menu">
                              <Link to="/coming">Apply for launchpad</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt_mobile--40">
                        <div className="footer-widget widget-quicklink">
                          <h6 className="widget-title">services</h6>
                          <ul className="footer-list-one">
                            <li className="footer-menu">
                              <Link to="/swap">Swap</Link>
                            </li>
                            <li className="footer-menu">
                              <Link to="/trade/BTC_USDT">Exchange</Link>
                            </li>
                            <li className="footer-menu">
                              <Link to="/coming">Apply for listing</Link>
                            </li>
                            <li className="footer-menu">
                              <Link to="/coming">Apply for launchpad</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt_mobile--40">
                      <div className="footer-widget widget-quicklink">
                        <h6 className="widget-title">Social</h6>
                        <div className="social_lists">
                          <div className="row">
                            <div className="col-lg-6">
                              <ul className="footer-list-one">
                                <li className="footer-menu">
                                  <a target="blank" href={siteData.twitter_url}>
                                    Twitter
                                  </a>
                                </li>
                                <li className="footer-menu">
                                  <a target="blank" href={siteData.fb_url}>
                                    Facebook
                                  </a>
                                </li>
                                <li className="footer-menu">
                                  <a target="blank" href={siteData.insta_url}>
                                    Instagram
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <ul className="footer-list-one">
                                <li className="footer-menu">
                                  <a
                                    target="blank"
                                    href={siteData.linkedin_url}
                                  >
                                    Linkedin
                                  </a>
                                </li>
                                <li className="footer-menu">
                                  <a
                                    target="_blank"
                                    href={siteData.telegram_url}
                                  >
                                    Telegram
                                  </a>
                                </li>
                                <li className="footer-menu">
                                  <a
                                    target="_blank"
                                    href={siteData.youtube_url}
                                  >
                                    Youtube
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 mt_mobile--40">
                      <div className="footer-widget widget-quicklink">
                        <h6 className="widget-title">Mobile</h6>
                        <ul className="footer-list-one">
                          <li className="footer-menu">
                            <a href="">
                              <img
                                src={require("../img/newimg/googlepay.png")}
                              />
                            </a>
                          </li>
                          <li className="footer-menu">
                            <a href="">
                              <img
                                src={require("../img/newimg/applestore.png")}
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="block-4" class="widget widget_block widget_text">
          <p class="site-info__copyright footer_copyrights">
            {siteData.copy_right_text}
          </p>
        </section>
      </footer>
    </div>
  );
}

export default Footer;
