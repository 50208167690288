import React, { useEffect, useState } from "react";
import { Link,useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import { Button } from "@material-ui/core";

import { socket } from "./context/socket";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { getMethod } from "../core/service/common.api";

function Undermaintain(){
  useEffect(() => {
    socket.connect()
    socket.on("sitesettings" , function (res) {
      console.log(res)
    fetchTfaData();
    })
  }, [0]);
  const navigate = useNavigate();
  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      if (resp.data.undermaintenenceStatus=="DeActive") {
      console.log(resp.data, "=--=-=-=-=-=resp=-=-");

        navigate("/undermaintanance");
      }else{
        navigate("/");
      }
    } catch (error) {}
  };
return(
    <div className="home">
    <main class="server_maintanence_main text-white">
<section class="server_maintanence_banner d-flex align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 d-flex flex-column align-items-center text-center">
                <img src={require("../img/maintenance_img1.png")} alt="" class="w-100"/>
                <h2 class="">Server Under Maintenance</h2>
                {/* <p class="">Back to <a class="" href="">Homepage</a></p> */}
            </div>
        </div>
    </div>

</section>
    </main>
</div>
)
}

export default Undermaintain;