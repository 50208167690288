import React, { useEffect } from "react";

import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Arrow from "../img/ArrowRight.svg";
import glogo from "../img/google_logo.svg";
import useState from "react-usestateref";

import { GOOGLE } from "../core/config/";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import Footernew from "./footer_buttom";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Home() {
  const options = ["one", "two", "three"];
  const [value, setValue] = useState();
  const [mobilevalue, setmobileValue, mobilevalueref] = useState();
  const initialFormValue = {
    email: "",
    password: "",
    confirmPassword: "",
    isTerms: "",
    firstName: "",
    mobile: "",
    lastName: "",
    referral_code: "",
    registerType: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState("");
  const [passconfNotmatch, setpassconfNotmatch, passconfNotmatchref] =
    useState("");
  const [disableButton, setdisableButton] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [firstNamevalidation, setfirstNamevalidation] = useState(false);
  const [lasttNamevalidation, setlasttNamevalidation] = useState(false);
  const [mobilevalidation, setmobilevalidation, mobilevalidationref] =
    useState(false);
  const [referralValidation, setreferralValidation, referralValidationref] =
    useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [captcha, setcaptcha] = useState("");
  const [invite_code, setinviteCode, invite_coderef] = useState("");
  const [activeType, setactiveType, activeTyperef] = useState("Email");

  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
    var currURL = window.location.href;
    var refferalId = currURL.split("=");
    setinviteCode(refferalId[1]);
    console.log("invite_code", invite_coderef.current);
    formValue.referral_code = invite_coderef.current;
  }, [0]);

  const {
    email,
    isTerms,
    password,
    confirmPassword,
    firstName,
    mobile,
    lastName,
    referral_code,
  } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const formSubmit = async (payload) => {
    // return
    //if (captcha) {
    validate(formValue);
    console.log(
      "referralValidationref.current====",
      referralValidationref.current
    );
    formValue["registerType"] = activeTyperef.current;
    console.log("formValue===", formValue);
    if (activeTyperef.current == "Email") {
      console.log("call 1 here");
      console.log("passwordValidateref", passwordValidateref.current);
      console.log("mobilevalidationref", emailValidateref.current);
      console.log(
        "confirmPasswordValidateref",
        confirmPasswordValidateref.current
      );
      console.log("referralValidationref", referralValidationref.current);
      if (
        passwordValidateref.current == false &&
        emailValidateref.current == false &&
        confirmPasswordValidateref.current == false &&
        termsValidationref.current == false
        // referralValidationref.current == false
      ) {
        formValue["isTerms"] = isChecked;
        if (captcha) {
          var data = {
            apiUrl: apiService.signup,
            payload: formValue,
          };
          setbuttonLoader(true);
          var resp = await postMethod(data);
          setFormValue(initialFormValue);
          setIschecked(false);
          localStorage.setItem("useremail", resp.email);
          setbuttonLoader(false);
          if (resp.status == true) {
            toast.success(resp.Message);
            navigate("/otp");
          } else {
            toast.error(resp.Message);
          }
        } else {
          console.log("empty captcha");
          toast.error("You may need to verify the reCAPTCHA");
        }
      } else {
      }
    } else {
      console.log("call here111", mobilevalidationref.current);
      console.log("passwordValidateref", passwordValidateref.current);
      console.log("mobilevalidationref", mobilevalidationref.current);
      console.log(
        "confirmPasswordValidateref",
        confirmPasswordValidateref.current
      );
      console.log("referralValidationref", referralValidationref.current);

      if (
        passwordValidateref.current == false &&
        mobilevalidationref.current == false &&
        confirmPasswordValidateref.current == false &&
        referralValidationref.current == false
      ) {
        // return false
        if (captcha != "") {
          var data = {
            apiUrl: apiService.signup_mobile,
            payload: formValue,
          };
          setbuttonLoader(true);
          var resp = await postMethod(data);
          localStorage.setItem("usermobile", resp.mobile);
          setbuttonLoader(false);
          if (resp.status == true) {
            setFormValue(initialFormValue);
            toast.success(resp.Message);
            navigate("/verify_mobile");
          } else {
            toast.error(resp.Message);
          }
        } else {
          console.log("toast");
          toast.error("Recaptcha not verified");
        }
      } else {
      }
    }

    // } else {
    //   toast("Recaptcha not verified");
    // }
  };

  const onChange = (value) => {
    var recaptcha = value;
    if (recaptcha) {
      setcaptcha(recaptcha);
    }
  };
  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms] = useState(false);
  const [termsValidation, setTermsValidation, termsValidationref] =
    useState(false);

  const handleTerms = (event) => {
    if (event.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
    setIschecked((current) => !current);
  };

  const validate = async (values) => {
    const errors = {};
    if (activeTyperef.current == "Email") {
      if (values.email == "") {
        errors.email = "Email is required";
        setemailValidate(true);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
        setemailValidate(true);
      } else {
        setemailValidate(false);
      }
    }

    if (formValue.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (!formValue.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one lower character";
    } else if (!formValue.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one upper character";
    } else if (!formValue.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one digit";
    } else if (!formValue.password.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character";
    } else if (formValue.password.length < 6) {
      setpasswordValidate(true);
      errors.password = "Atleast enter 6 letters";
    } else if (formValue.password.length > 15) {
      setpasswordValidate(true);
      errors.password = "Only enter 15 letters";
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = "Confirm password is required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword =
        "Please ensure that the password and confirm password match.";
    } else {
      setconfirmPasswordValidate(false);
    }

    if (!Terms) {
      errors.terms = "Terms is a required field";
      setTermsValidation(true);
    }

    if (!Terms) {
      errors.terms = "Terms is a required field";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setTermsValidation(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };
  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const registerType = (type) => {
    if (type == "Email") {
      setactiveType("Email");
      setvalidationnErr("");
      //validationnErr.email = "";
    } else {
      setactiveType("Phone");
      setvalidationnErr("");
      //validationnErr.mobile = "";
    }
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg  bg-cover onlywhitee">
        <Header />
        <div class="container">
          <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
            <div className="header_pading">
              <div class="row justify-center">
                <div class="col-lg-5">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card_logoki pading_cardd register_card">
                        <div className="form_content ">
                          <h1 className="text-center">Welcome to Cashbit</h1>
                          <p className="text-center">
                            Begin by providing your registration information.
                          </p>
                        </div>
                        <div class="form_login_section p-0">
                          <div class="form register_login p-0">
                            <form className="form_pading_s">
                              {/* <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder="First Name"
                                  name="firstName"
                                  value={firstName}
                                  onChange={handleChange}
                                />

                                <div>
                                  {firstNamevalidation == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.firstName}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div> */}
                              {/* <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputPassword1"
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={lastName}
                                  onChange={handleChange}
                                />
                                <div>
                                  {lasttNamevalidation == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.lastName}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div> */}
                              <ul class="nav nav-tabs">
                                <li class="active">
                                  {/* <a
                                    data-toggle="tab"
                                    href="#email"
                                    className="active"
                                    onClick={() => registerType("Email")}
                                  >
                                    Email
                                  </a> */}
                                </li>
                              </ul>
                              <div class="tab-content">
                                <div
                                  id="email"
                                  class="tab-pane fade in active show"
                                >
                                  <div class="form-group">
                                    <input
                                      type="email"
                                      class="form-control"
                                      id="exampleInputPassword1"
                                      placeholder="Email ID"
                                      name="email"
                                      maxLength={40}
                                      value={email}
                                      onChange={handleChange}
                                    />
                                    <div>
                                      {emailValidate == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.email}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div id="mobile" class="tab-pane fade">
                                  <div className="form-group">
                                    <PhoneInput
                                      country="US"
                                      placeholder="Enter phone number"
                                      value={mobilevalue}
                                      onChange={setmobileValue}
                                    />
                                    <div>
                                      {mobilevalidationref.current == true ? (
                                        <p className="text-danger">
                                          {" "}
                                          {validationnErr.mobile}{" "}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div class="form-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="exampleInputPassword1"
                                  placeholder="Mobile Number"
                                  name="mobile"
                                  value={mobile}
                                  onChange={handleChange}
                                />

                                <div>
                                  {mobilevalidation == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.mobile}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div> */}
                              <div class="form-group postion_reletitt">
                                <input
                                  type={inputType}
                                  class="form-control"
                                  id="exampleInputPassword1"
                                  maxLength={40}
                                  placeholder="Password"
                                  className="form-control"
                                  name="password"
                                  value={password}
                                  onChange={handleChange}
                                />

                                <div className="input-group-addon">
                                  {passHide == false ? (
                                    <i
                                      className="bi bi-eye-slash-fill"
                                      onClick={() => passwordHide("hide")}
                                    ></i>
                                  ) : (
                                    <i
                                      className="bi bi-eye-fill"
                                      onClick={() => passwordHide("show")}
                                    ></i>
                                  )}
                                </div>
                                <div>
                                  {passwordValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.password}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>

                              <div class="form-group postion_reletitt">
                                <input
                                  type={inputTypeconf}
                                  class="form-control"
                                  id="exampleInputPassword1"
                                  placeholder="Confirm Password"
                                  name="confirmPassword"
                                  maxLength={40}
                                  value={confirmPassword}
                                  onChange={handleChange}
                                />
                                <div className="input-group-addon">
                                  {passHidconf == false ? (
                                    <i
                                      className="bi bi-eye-slash-fill"
                                      onClick={() => passwordHideconf("hide")}
                                    ></i>
                                  ) : (
                                    <i
                                      className="bi bi-eye-fill"
                                      onClick={() => passwordHideconf("show")}
                                    ></i>
                                  )}
                                </div>

                                <div>
                                  {confirmPasswordValidate == true ? (
                                    <p className="text-danger">
                                      {" "}
                                      {validationnErr.confirmPassword}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {/* {formValue.referral_code != "" &&
                              formValue.referral_code != null &&
                              formValue.referral_code != undefined ? ( */}
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputPassword1"
                                    placeholder="Referral Code (Optional)"
                                    name="referral_code"
                                    maxLength={40}
                                    value={referral_code}
                                    onChange={handleChange}
                                  />
                                  {/* <div>
  {referralValidationref.current == true ? (
    <p className="text-danger">
      {" "}
      {validationnErr.referral_code}{" "}
    </p>
  ) : (
    ""
  )}
</div> */}
                                </div>
                              {/* ) : (
                                ""
                              )} */}

                              <div className="form-group custom-checkbox pl-4">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customCheck"
                                  name="example1"
                                  onChange={handleTerms}
                                  value={isChecked}
                                />
                                <label
                                  class="custom-control-label text-left"
                                  for="customCheck"
                                >
                                  I have read and agree to Cashbit's{" "}
                                  <a href="/terms" target="_blank">
                                    Terms of Service
                                  </a>{" "}
                                  and{" "}
                                  <a href="/privacypolicy" target="_blank">
                                    Privacy Policy
                                  </a>
                                  .
                                </label>
                                {termsValidation == true ? (
                                  <p className="text-danger">
                                    {" "}
                                    Terms and Conditions is required
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group postion_reletitt">
                                <ReCAPTCHA
                                  sitekey="6LclCh0pAAAAAOCYAB3TORCzO1Q7hka_6SHO2YuR"
                                  onChange={onChange}
                                />
                              </div>
                              <div className="row">
                                <div class="col-lg-12">
                                  {buttonLoader == false ? (
                                    <button
                                      type="button"
                                      class="btn btn-primary w-100"
                                      onClick={formSubmit}
                                    >
                                      Sign up
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-primary w-100"
                                    >
                                      loading...
                                    </button>
                                  )}

                                  <p class="bottom_linnk">
                                    Already have an Account?
                                    <Link to="/login">
                                      <a className="">LOGIN</a>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <form class="">
            <div className="row justify-center">
              <div className="col-lg-5">
                <div className="card_logoki pading_cardd">
                  <div className="form_content">
                    <h1 className="gradion_text">Login toJustBit</h1>
                    <p>Enter your email and password to login</p>
                  </div>
                  <div class="form_login_section p-0">
                    <div class="form register_login p-0">
                      <div class="form_pading_s">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={handleChange}
                          />

                          <div>
                            {firstNamevalidation == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.firstName}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={handleChange}
                          />
                          <div>
                            {lasttNamevalidation == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.lastName}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Email ID"
                            name="email"
                            value={email}
                            onChange={handleChange}
                          />
                          <div>
                            {emailValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.email}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Mobile Number"
                            name="mobile"
                            value={mobile}
                            onChange={handleChange}
                          />

                          <div>
                            {mobilevalidation == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.mobile}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="form-group">
                          <input
                            type="password"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Password"
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={handleChange}
                          />

                          <div>
                            {passwordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.password}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div class="form-group">
                          <input
                            type="password"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                          />

                          <div>
                            {confirmPasswordValidate == true ? (
                              <p className="text-danger">
                                {" "}
                                {validationnErr.confirmPassword}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-lg-12">
                            {buttonLoader == false ? (
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={formSubmit}
                              >
                                Sign up
                              </button>
                            ) : (
                              <button type="button" class="btn btn-primary">
                                loading...
                              </button>
                            )}

                            <p class="bottom_linnk">
                              Already have an account?{" "}
                              <Link to="/login">
                                <a>Login to continue</a>{" "}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        className="form-control"
                        name = "password"  
                        value={password}
                        onChange={handleChange}
                      />

                      <div>
                      {
                         passwordValidate == true ?  ( <p className= "text-danger"> {validationnErr.password} </p> ):""
                     }
                      </div>

                    </div>
                      </div>
                      <div class="col-lg-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Last Name"
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                      />
                      <div>
                        {lasttNamevalidation == true ? (
                          <p className="text-danger">
                            {validationnErr.lastName}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Select a Language </label>
                      <select>
                        <option>English</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Referral Code</label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Referral Code"
                      />
                    </div>
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />

                      <div>
                        {passwordValidate == true ? (
                          <p className="text-danger">
                            {validationnErr.password}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                      />

                      <div>
                        {confirmPasswordValidate == true ? (
                          <p className="text-danger">
                            {validationnErr.confirmPassword}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                      <div class="col-lg-12">
                    {buttonLoader == false ? (
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={formSubmit}
                      >
                        Sign up
                      </button>
                    ) : (
                      <button type="button" class="btn btn-primary">
                        loading...
                      </button>
                    )}

                    <p class="bottom_linnk">
                      Already have an account?{" "}
                      <Link to="/login">
                        <a>Login to continue</a>{" "}
                      </Link>
                    </p>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form> */}
          {/* <div class="col-lg-12">
            <div class="register_login p-0">
              <div></div>
            </div>
          </div> */}
        </div>
        <Footernew />
      </main>
    </div>
  );
}

export default Home;
