import React, {useEffect, useContextt} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import {removeAuthorization} from "../core/service/axios";
import {Link, useNavigate} from "react-router-dom";
import {socket} from "./context/socket";
import apiService from "../core/service/detail";
import {getMethod, postMethod} from "../core/service/common.api";
import Moment from "moment";
import {toast} from "react-toastify";
import {RiWindow2Fill} from "react-icons/ri";

function Header() {
  const [isOpen, setIsOpen, isOpenref] = useState(true);
  const [loginCheck, setloginCheck] = useState(false);
  const [notifications, setNotification] = React.useState("");
  // const socket = useContext(SocketContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTfaData();
    socket.on("sitesettings", function (res) {
      console.log(res);
      fetchTfaData();
    });
    fetchTheme();

    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      socket.on("socketResponse" + socketToken, function (res) {
        console.log("notify response====", res);
        if (res.Reason == "notify") {
          getNotifications();
        }

        if (res.Message == "account_deactivate") {
          toast.error("Your account deactivated by admin");
          logout();
        }
      });
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }
  }, [0]);
  const changeTheme = (value) => {
    localStorage.setItem("theme", value);
    var settheme = value == "light" ? true : false;
    setIsOpen(settheme);

    var current_url = window.location.href.split("/")[3];
    if (current_url == "trade") {
      window.location.reload(true);
    } else {
      document.body.classList.toggle("light", isOpenref.current);
    }
  };
  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      if (resp.data.undermaintenenceStatus == "DeActive") {
        console.log(resp.data, "=--=-=-=-=-=resp=-=-");
        navigate("/undermaintanance");
        localStorage.setItem("sitestats", resp.data.undermaintenenceStatus);
      } else {
      }
    } catch (error) {}
  };
  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "dark") {
        setIsOpen(true);
        document.body.classList.toggle("light", isOpenref.current);
      } else {
        setIsOpen(false);
      }
    } else {
      localStorage.setItem("theme", "dask");
      setIsOpen(true);
    }
  };
  const logout = async () => {
    var data = {
      apiUrl: apiService.logout,
    };
    var resp = await getMethod(data);
    if (resp) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };

  const [navbar, setNavbar] = useState(false);

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeader);

  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.getnotifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) {}
  };

  const clickNotify = async (params) => {
    try {
      var obj = {
        _id: params,
      };
      var data = {
        apiUrl: apiService.updateNotifyRecord,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
      } else {
      }
    } catch (error) {}
  };

  return (
    <div>
      <header
        className={
          navbar
            ? "navbar-custom sticky sticky-dark  navbar navbar-expand-lg fixed-top fixed_header"
            : "navbar-custom sticky sticky-dark  navbar navbar-expand-lg fixed-top "
        }
      >
        <nav className="container navbar navbar-expand-lg ">
          <a className="navbar-brand" href="/">
            <img
              src={require("../img/newimg/logo-headerr.png")}
              className="logo darktheme logo_1"
            />
            <img
              src={require("../img/newimg/logo-headerr.png")}
              className="logo lighttheme logo_2"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list navbar-toggler-icon"></i>
          </button>

          {loginCheck == false ? (
            <div className="mr-auto display_movuike">
              {/* <Link to="/login" className="mr-3">
                <a className="trade111">Dashboard</a>
              </Link> */}
              <Link to="/trade/BTC_USDT" className="mr-3">
                <a className="trade111">Trade</a>
              </Link>
              <Link to="/market" className="mr-3">
                <a className="trade111">Market</a>
              </Link>
              <Link to="/login" className="mr-3">
                <a className="trade111">Swap</a>
              </Link>
              <Link to="/coming" className="mr-3 lable">
                <span className="blink">Soon</span>
                <a className="trade111">Future</a>
              </Link>
              <Link to="/coming" className="mr-3 lable launchpad_header">
                <span className="blink">Soon</span>
                <a className="trade111">Launchpad</a>
              </Link>
              {/* <Link to="/FiatDeposit" className="mr-3 mobile-show">
                <a className="trade111"> Fiat Withdraw</a>
              </Link>
              <Link to="/kyc" className="mr-3 mobile-show">
                <a className="trade111">KYC</a>
              </Link>
              {/* <Link to="/Bankdetails" className="mr-3 mobile-show">
                <a className="trade111">Bank Details</a>
              </Link> 
              <Link to="support" className="mr-3 mobile-show">
                <a className="trade111">Support</a>
              </Link>{" "}*/}
            </div>
          ) : (
            <div className="mr-auto display_movuike">
              <Link to="/dashboard" className="mr-3">
                <a className="trade111">Dashboard</a>
              </Link>
              <Link to="/trade/BTC_USDT" className="mr-3">
                <a className="trade111">Trade</a>
              </Link>

              <Link to="/market" className="mr-3">
                <a className="trade111">Market</a>
              </Link>

              <Link to="/coming" className="mr-3 lable">
                <span className="blink">Soon</span>
                <a className="trade111">Future</a>
              </Link>
              <Link to="/coming" className="mr-3 lable launchpad_header">
                <span className="blink">Soon</span>
                <a className="trade111">Launchpad</a>
              </Link>
              <Link to="/FiatDeposit" className="mr-3 mobile-show">
                <a className="trade111"> Fiat Withdraw</a>
              </Link>
              <Link to="/kyc" className="mr-3 mobile-show">
                <a className="trade111">KYC</a>
              </Link>
              {/* <Link to="/Bankdetails" className="mr-3 mobile-show">
                <a className="trade111">Bank Details</a>
              </Link> */}
              <Link to="support" className="mr-3 mobile-show">
                <a className="trade111">Support</a>
              </Link>
            </div>
          )}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="ml-auto padingrespos BUtton_fleds">
              {/* {loginCheck == false ? (
                <Link to="/staking" className="mr-3">
                  <a className="trade111">Staking</a>
                </Link>
              ) : (
                ""
              )} */}
              {/* <Link to="/launchpadNew" className="mr-3">
                <a className="trade111">Launchpad</a>
              </Link>

              <Link to="/p2phome" className="mr-3">
                <a className="trade111">P2P</a>
              </Link> */}
              <div className="mobile_show">
                <Link to="/dashboard" className="mr-3">
                  <a className="trade111">Dashboard</a>
                </Link>
                <Link to="/trade/BTC_USDT" className="mr-3">
                  <a className="trade111">Trade</a>
                </Link>

                <Link to="/market" className="mr-3">
                  <a className="trade111">Market</a>
                </Link>

                <Link to="/coming" className="mr-3 lable">
                  <span className="blink">Soon</span>
                  <a className="trade111">Future</a>
                </Link>
                <Link to="/coming" className="mr-3 lable launchpad_header">
                  <span className="blink">Soon</span>
                  <a className="trade111">Launchpad</a>
                </Link>
              </div>

              {loginCheck && loginCheck == true ? (
                <>
                  <Link to="/coming" className="mr-3 mobile-show">
                    <a className="trade111"> Fiat Withdraw</a>
                  </Link>
                  <Link to="/kyc" className="mr-3 mobile-show">
                    <a className="trade111">KYC</a>
                  </Link>
                  {/* <Link to="/Bankdetails" className="mr-3 mobile-show">
                    <a className="trade111">Bank Details</a>
                  </Link> */}
                  <Link to="support" className="mr-3 mobile-show">
                    <a className="trade111">Support</a>
                  </Link>
                </>
              ) : (
                ""
              )}

              {/* {loginCheck && loginCheck == true ? (
                <Link to="/kyc" className="mr-3">
                  <a className="trade111">KYC</a>
                </Link>
              ) : (
                ""
              )}
              {loginCheck && loginCheck == true ? (
                <Link to="/staking" className="mr-3">
                  <a className="trade111">Staking</a>
                </Link>
              ) : (
                ""
              )} */}

              {/* {loginCheck && loginCheck == true ? (
                <Link to="/p2ppost" className="mr-3">
                  <a className="trade111">Post Ad</a>
                </Link>
              ) : (
                ""
              )} */}

              {/* {loginCheck && loginCheck == true ? (
                <Link to="/dashboard" className="mr-3">
                  <a className="trade111">Portfolio</a>
                </Link>
              ) : (
                ""
              )}
              {loginCheck && loginCheck == true ? (
                <Link to="/transaction" className="mr-3">
                  <a className="trade111">Transaction</a>
                </Link>
              ) : (
                ""
              )} */}
              {/* {loginCheck && loginCheck == true ? (
                <>
                  <div class="dropdown">
                    <button
                      type="button"
                      class="btn dropdown-toggle hover_white"
                      data-toggle="dropdown"
                    >
                      Wallet
                    </button>
                    <div class="dropdown-menu bag_clos">
                      <Link to="/deposit" className="dropdown-item">
                        <span className="blink">Deposit</span>
                      </Link>
                      <Link to="/withdraw" className="dropdown-item">
                        <span className="blink">Withdraw</span>
                      </Link>
                      <Link to="/allhistory" className="dropdown-item">
                        <span className="blink">History</span>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )} */}
              {loginCheck == false ? (
                <Link
                  to="/login"
                  className="btn btn-primary-alta connectBtn colo-fff clooee "
                >
                  <Button className="login_btn text-dark">Login</Button>
                </Link>
              ) : (
                ""
              )}

              {loginCheck == false ? (
                <Link
                  to="/register"
                  className="btn btn-primary-alta connectBtn ml-3 colo-fff clooee"
                >
                  <Button className="register_btn">Register</Button>
                </Link>
              ) : (
                ""
              )}
              {/* {loginCheck && loginCheck == true ? (
                <>
                  <div class="dropdown">
                    <button
                      type="button"
                      class="btn dropdown-toggle hover_white"
                      data-toggle="dropdown"
                    >
                      <i class="bi bi-person-fill"></i>
                    </button>

                    <div class="dropdown-menu bag_clos">
                      <Link to="/dashboard" className="dropdown-item">
                        <span className="blink">Overview</span>
                      </Link>
                      <Link to="/security" className="dropdown-item">
                        <span className="blink">Account Security</span>
                      </Link>
                      <Link to="/allhistory" className="dropdown-item">
                        <span className="blink" onClick={logout}>
                          Signout
                        </span>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )} */}

              {/* {loginCheck && loginCheck == true ? (
                <Button
                  onClick={logout}
                  className="btn btn-primary-alta connectBtn colo-fff"
                >
                  Logout
                </Button>
              ) : (
                ""
              )} */}

              {/* <button className="btn btn-primary-alta notification">
                <i className="bi bi-bell"></i>
              </button> */}

              {/* {isOpenref.current == true ? (
                <button
                  onClick={() => changeTheme("dark")}
                  className="btn btn-primary-alta notification"
                >
                  <i className="bi bi-moon"></i>
                </button>
              ) : (
              <button
              onClick={() => changeTheme("light")}
              className="btn btn-primary-alta notification"
              >
              <i className="bi bi-brightness-high"></i>
              </button>
              )} */}
            </div>
            {/* {loginCheck && loginCheck == true ? (
              <div className="notification_section  padingrespos ">
                <button
                  className="btn btn-primary-alta notification"
                  data-toggle="collapse"
                  data-target="#notification"
                >
                  <i class="bi bi-bell-fill"></i>
                </button>
                <div id="notification" class="collapse">
                  <div className="content_notification">
                    <h3>
                      Notification <Link to="/profile">View all-----</Link>
                    </h3>
                    <div className="overflow_seee">
                      {notifications && notifications.length > 0
                        ? notifications.map((item, i) => {
                            // console.log(item,'-==-=-=-=-item--=-=-=-s')
                            // return (
                            <>
                              <a href={item.link} className="msg-noti">
                                <small>
                                  {Moment(item.createdAt).fromNow()}
                                </small>
                                <p>{item.message}</p>
                              </a>
                            </>;
                          })
                        : "-------------------------s"}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}

{loginCheck && loginCheck == true ? (
              <div className="notification_section  padingrespos menu_profile notifycount ">
                {notifications.length != 0 ? (
                  <Button data-toggle="tooltip" title="Site Notification">
                    <i
                      class="bi bi-bell-fill"
                      data-toggle="collapse"
                      data-target="#notification"
                    ></i>
                  </Button>
                ) : (
                  <Button data-toggle="tooltip" title="Site Notification">
                    <Link to="/notification">
                      <i class="bi bi-bell-fill"></i>
                    </Link>
                  </Button>
                )}

                {notifications.length != 0 ? (
                  <div className="count_data">
                    {notifications && notifications.length}
                  </div>
                ) : (
                  ""
                )}
                <div id="notification" class="collapse">
                  <div className="content_notification">
                    <h3>
                      Notification <Link to="/notification">View all</Link>
                    </h3>

                    <div className="overflow_seee">
                      {notifications && notifications.length > 0
                        ? notifications.map((item, i) => {
                            return (
                              <span
                                onClick={() => {
                                  clickNotify(item._id);
                                }}
                              >
                                <Link to={item.link} className="msg-noti">
                                  <small style={{color: "#d9d9d9"}}>
                                    {Moment(item.createdAt).fromNow()}
                                  </small>
                                  <p style={{color: "#e3b36f"}}>
                                    {item.message}
                                  </p>
                                </Link>
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
  {loginCheck && loginCheck == true ? (
              <button onClick={logout} class="logout_but">
                Logout
              </button>
            ) : (
              ""
            )}


            {/* {loginCheck && loginCheck == true ? (
              <div className="notification_section  padingrespos menu_profile notifycount ">
                {/* <Button>
                  <i
                    class="bi bi-bell-fill"
                    data-toggle="collapse"
                    data-target="#notification"
                  ></i>
                </Button> */}
                {/* {notifications.length != 0 ? (
                  <div className="count_data">
                    {notifications && notifications.length}
                  </div>
                ) : (
                  ""
                )}

                <div id="notification" class="collapse">
                  <div className="content_notification">
                    <h3>
                      Notification <Link to="/notification">View all</Link>
                    </h3>

                    <div className="overflow_seee">
                      {notifications && notifications.length > 0
                        ? notifications.map((item, i) => {
                            return (
                              <span
                                onClick={() => {
                                  clickNotify(item._id);
                                }}
                              >
                                {item.type != "Credentials" ? (
                                  <Link to={item.link} className="msg-noti">
                                    <small>
                                      {Moment(item.createdAt).fromNow()}
                                    </small>
                                    <p>{item.message}</p>
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */} 
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
