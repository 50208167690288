import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import apiService from "../core/service/v2/index";
import { postMethod } from "../core/service/common.api2";
import P2homePost from "./P2homePost";
import Newsideheader from "./Newsideheader";
import Undermaintain from "./Undermaintain";
import Select from "react-select";
function Home() {
  const options = ["one", "two", "three"];

  useEffect(() => {
    getlunchpadValue();
  }, [])

  const [launchpadDetails, setlaunchpadDetails] = useState([])
  const getlunchpadValue = async () => {
    try {
      var data = {
        apiUrl: apiService.getLaunchpadDetails,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        setlaunchpadDetails(resp.Data)
        console.log(resp.Data[0], "=-=-=-=-Data")
      }
    } catch (error) {

    }
  }

  return (
    <div>
      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Stake GPT token</h4>
              <button type="button" class="close" data-dismiss="modal">
                <i class="bi bi-x"></i>
              </button>
            </div>
            <div class="modal-body">
              <p className="tetx_note">
                Your amount will be deducted from your spot account and locked
                into your launchpad account within your wallet
              </p>
              <div className="launch_deteila">
                <p>
                  Your Min purchase <span>100.00</span>
                </p>
                <p>
                  Your Max purchase <span>200.00</span>
                </p>
                <p>
                  Min-Max Allocation <span>300.00</span>
                </p>
                <p>
                  Network <span>BEP20</span>
                </p>
              </div>
              <div className="input_stake">
                <h2>
                  Spot Balance <span>Available: 3.2006 BTC</span>
                </h2>
                <div className="input_max_data">
                  <input type="text" placeholder="Enter Amount" />
                  <span>MAX</span>
                </div>
              </div>
            </div>
            <div class="modal-footer pt-0">
              <Button>Stake Now</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contante">
                        <h1 className="text-left">Launchpool</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="filter_section justify-content-between">
                        <div className="filter_section">
                          <div className="Filter_section_input">
                            <div className="input_section_new">
                              <label>Enter token name or token symbol</label>
                              <div className="search_launch">
                                <input type="text" placeholder="" />
                                <span>Search</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="filter_section justify-content-between">
                            <div className="Filter_section_input">
                              <div className="input_section_new">
                                <label>Filter By</label>
                                <Select
                                  options={options}
                                  placeholder="No Filter"
                                  className="call_seclecy new_height"
                                />
                              </div>
                            </div>
                            <div className="Filter_section_input">
                              <div className="input_section_new">
                                <label>Sort By</label>
                                <Select
                                  options={options}
                                  placeholder="No Filter"
                                  className="call_seclecy new_height"
                                />
                              </div>
                            </div>
                            <div className="Filter_section_input">
                              <div className="input_section_new">
                                <label>Chain</label>
                                <Select
                                  options={options}
                                  placeholder="No Filter"
                                  className="call_seclecy new_height"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card_flex_r">
                    {launchpadDetails && launchpadDetails.map((data, i) => {
                      return (
                        <div className="card add_padin_card">
                          <div className="image_coin_">
                            <div className="iocn_section">
                              {" "}
                              <div className="coin_image">
                                <img
                                  src={require("../img/Coin.png")}
                                  className="img-fluid requirdss"
                                />
                                <img
                                  src={require("../img/network.png")}
                                  className="img-fluid small_img"
                                />
                              </div>
                              <div className="coin_name">
                                <h5>{data.TokenName}Token</h5>
                                <p>Fair Launch</p>
                              </div>
                            </div>
                            <div className="information_about">
                              <span>Upcoming</span>
                            </div>
                          </div>
                          <div className="heading_token">
                            <p>Minimum Staking</p>
                            <h4>{data.SoftCap} {data.TokenName}</h4>
                          </div>
                          <div className="heading_token">
                            <p>Maximum Staking</p>
                            <h4>{data.HardCap} {data.TokenName}</h4>
                          </div>
                          <div className="slaide_precess">
                            <h3>Progress (30%)</h3>
                            <div class="progress">
                              <div
                                class="progress-bar"
                                role="progressbar"
                                aria-valuenow="70"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <span class="sr-only">70% Complete</span>
                              </div>
                            </div>
                            <p>
                              <span>0 Days</span>
                              <span>5 Days</span>
                            </p>
                          </div>
                          <div className="datails_coin">
                            <p>
                              Reward %: <span>{data.RewardPercent}%</span>
                            </p>
                            <p>
                              Lockup Time:<span>50days</span>
                            </p>
                            <p>
                              Network %:<span>{data.TokenNetwork}</span>
                            </p>
                            <p>
                              Amount:<span>${data.StakeRateinusd}</span>
                            </p>
                          </div>
                          <div className="button_botrfds">
                            <Button
                              className="scar_bottm"
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Stake Now
                            </Button>
                            <Link to="/lauchpaddetails">View Details </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
