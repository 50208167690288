import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import Slider, {Range} from "rc-slider";
// import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Chart from "./chart/Chart";
import ReactPlayer from "react-player";
import {Link, useNavigate} from "react-router-dom";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {socket} from "./context/socket";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SliderNew from "react-slick";
import IconC from "../img/BNB.png";
import ReactLoading from "react-loading";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};
var newsticker = {
  speed: 4000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: "linear",
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
};

function Home() {
  const options = ["one", "two", "three"];
  const navigate = useNavigate();
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);

  const navpage = async () => {
    if (localStorage.getItem("user_token") !== null) {
      navigate("trade/BTC_USDT");
    } else {
      navigate("register");
    }
  };

  useEffect(() => {
    // socket.connect();
    // socket.removeListener("homepagemarketprice");
    // socket.emit("homepagemarketprice");
    // viewMoreCurrency(25);
    gethomeCurrency();
    fetchTfaData();
  }, []);

  const [siteData, setSiteData] = useState({});

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      setSiteData(resp.data);
      if (resp.data.undermaintenenceStatus == "DeActive") {
        console.log(resp.data, "=--=-=-=-=-=resp=-=-");

        navigate("/undermaintanance");
      } else {
        setSiteData(resp.data);
      }
    } catch (error) {}
  };
  const navtradepage = async (symbol) => {
    // if (symbol == "USDT") {
    //   navigate("trade/BTC_USDT");
    // } else {
    //   navigate("trade/" + symbol + "_USDT");
    // }
    window.location.href = `trade/${symbol}_USDT`;
  };
  // const viewMoreCurrency = async (limit) => {
  //   var data = {
  //     apiUrl: apiService.getCurrency,
  //     payload: {limit: limit},
  //   };
  //   var resp = await postMethod(data);
  //   if (resp) {
  //     setcurrencyCount(resp.countDocs);
  //     socket.on("gethomemarketprice", async (response) => {
  //       for (let index = 0; index < resp.data.length; index++) {
  //         // if(response.data[resp.data[index]["currencySymbol"]].INR != undefined)
  //         // {
  //         const element = resp.data[index];
  //         resp.data[index]["marketprice"] =
  //           response.data[resp.data[index]["currencySymbol"]].USDT;
  //         // }
  //         // else
  //         // {
  //         //   console.log("call===",response)
  //         // }
  //       }
  //       setcurrencylistData(resp.data);
  //     });
  //   } else {
  //   }
  // };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };
  const [copy_right_text, setcopy_right_text] = useState();

  const navigatepage = async (page) => {
    navigate(page);
  };
  return (
    <div className="home">
      <Header />
      {/* <ReactLoading type={"bars"} color="#fff" /> */}
      <main className="home_main">
        <section className="eight_secton  " id="available_currencies">
          <div className="currencies_bg pt-0 pt-5">
            <div className="container">
              <h2 className="take-your-crypto-gameto-the-next-level  ">
                Market Overview
              </h2>
              <p className="text_des">
                There are various crypto currencies available in Cashbit,
                and you may easily trade and <br />
                invest in all of them.
              </p>
              <div className="row justify-center latest-offer__item">
                <div
                  className="col-lg-10 "
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div className="table-responsive coin-list__main">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="text-center">Price (USD)</th>
                          <th className="text-right">Trade</th>
                        </tr>
                      </thead>
                      {currencylistData &&
                        currencylistData.map((obj, i) => {
                          return (
                            <tbody>
                              <tr>
                                <td>
                                  <a href="/trade" className="butn_flw_section">
                                    <span class="icon-btc">
                                      {" "}
                                      <img src={obj.Currency_image} />
                                    </span>
                                    <span>{obj.currencyName}</span>
                                    <span class="unit">
                                      {obj.currencySymbol}
                                    </span>
                                  </a>
                                </td>
                                <td className="text-center">
                                  {obj.marketprice <= 0 ? (
                                    <div className="text-red">
                                      {obj.currencySymbol == "SHIB"
                                        ? parseFloat(
                                            obj.estimatedValueInUSDT
                                          ).toFixed(8)
                                        : parseFloat(
                                            obj.estimatedValueInUSDT
                                          ).toFixed(2)}
                                    </div>
                                  ) : (
                                    <div className="text-green">
                                      {obj.currencySymbol == "SHIB"
                                        ? parseFloat(
                                            obj.estimatedValueInUSDT
                                          ).toFixed(8)
                                        : parseFloat(
                                            obj.estimatedValueInUSDT
                                          ).toFixed(2)}
                                    </div>
                                  )}
                                </td>

                                <td className="text-right">
                                  <Button
                                    href=""
                                    className="treiee"
                                    // onClick={navtradepage}
                                    onClick={() =>
                                      navtradepage(obj.currencySymbol)
                                    }
                                  >
                                    Buy
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                  {/* {currencylistData.length == 25 ? (
                  <a
                    href="javascript:void(0)"
                    className="link_more  btn btn-primary-alta connectBtn colo-fff m-auto button_view_modee"
                    onClick={() => viewMoreCurrency(currencyCount)}
                  >
                    More prices <i className="fas fa-arrow-right"></i>
                  </a>
                ) : (
                  ""
                )} */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
