import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button, useTheme} from "@material-ui/core";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import Slider, {Range} from "rc-slider";
// import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Chart from "./chart/Chart";
import ReactPlayer from "react-player";
import {Link, useNavigate} from "react-router-dom";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {socket} from "./context/socket";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from "react-js-pagination";

import SliderNew from "react-slick";
import IconC from "../img/BNB.png";
import {toast} from "react-toastify";
import Countdownchat from "./Countdown";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};
var newsticker = {
  speed: 4000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: "linear",
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
};

function Home() {
  const options = ["one", "two", "three"];
  const navigate = useNavigate();

  const [refferance, setrefferance] = useState("");
  const [refferHistoty, setRefferHistoty] = useState("");
  const [totalPage, setTotalPages] = useState("");
  const [totalRewards, setTotalRewards] = useState("");
  const [totalRefferal, setTotalRefferal] = useState("");
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    getReferralDetails(0);
    getReward(0);
    referralHistory(0);
  }, []);

  const title = "Welcome to JustBit";
  const getReferralDetails = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        var link = resp.refer_link;
        setrefferance(link);
        console.log(refferance, "reffernce");
      } else {
      }
    } catch (error) {}
  };

  const copy = (content) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
      toast.success("Referral Link copied successfully");
    } else {
      toast.success("Link not copied, please try after sometimes!");
    }
  };
  const getReward = async () => {
    var get = {
      apiUrl: apiService.getReward,
    };
    var response = await getMethod(get);
    console.log(response, "response");
    setTotalRewards(response.data.total_bonus);
    setTotalRefferal(response.data.totalRefCount);
  };
  const referralHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.referralHistory,
        payload: payload,
      };
      var resp = await postMethod(data);
      console.log(resp);
      if (resp.status) {
        setRefferHistoty(resp.data.data);
        console.log(resp.data.data);
        setTotalPages(resp.data.total);
      }
    } catch (error) {}
  };
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    referralHistory(pageNumber);
    setCurrentPage(pageNumber);
  };
  return (
    <div className="home contact fixed_header">
      <Header />
      <main className="chat_mainpadi">
        <div className="bg_roe">
          <div className="row justify-center">
            <div className="col-lg-10">
              <div className="chat_details">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contnte_chat_section">
                        <p>
                          <span className="green">Buy</span> Buy USDT From
                          junawasarkar
                        </p>
                        <p className="timersection">
                          This order is created, please wait for system
                          conformation
                          <span className="new_count_secti">
                            <Countdownchat />
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="contnte_chat_section">
                        <p className="text-right">
                          Order Number <span>6235345534534534575</span>
                        </p>
                        <p className="text-right">
                          Time Created <span>₹2023-4-03 19:09:09</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-center">
          <div className="col-lg-10">
            <div className="chart_section_new">
              <div className="contanier">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="chat_details_ss">
                      <h4>1, Confirm Order Info</h4>
                      <div className="account_dteils mb-4">
                        <div className="section_contant_row">
                          <label>Amount</label>
                          <p>
                            INR <span className="text-green">1.200.00</span>
                          </p>
                        </div>
                        <div className="section_contant_row">
                          <label>Price</label>
                          <p>$ 2,505.000</p>
                        </div>
                        <div className="section_contant_row">
                          <label>Quantity</label>
                          <p>10.00 USTD</p>
                        </div>
                      </div>
                      <h4 className="mb-4">
                        2, Transfer the funds to the sellers’s Account provided
                        below
                      </h4>
                      <p className="lable_section">Paytm:</p>
                      <div className="account_dteils mb-4">
                        <div className="section_contant_row">
                          <label>Name</label>
                          <p>junawasarkar</p>
                        </div>
                        <div className="section_contant_row">
                          <label>Account</label>
                          <p>8909890989</p>
                        </div>
                      </div>
                      <p className="lable_section">Gpay :</p>
                      <div className="account_dteils mb-4">
                        <div className="section_contant_row">
                          <label>Name</label>
                          <p>junawasarkar</p>
                        </div>
                        <div className="section_contant_row">
                          <label>Account</label>
                          <p>8909890989</p>
                        </div>
                      </div>
                      <h4 className="mb-4">
                        3, After transferring the funds, Click on the
                        “Transferred, notify seller ” button
                      </h4>
                      <div className="action_btn">
                        <Button className="width_full">
                          Transferred, notify seller
                        </Button>
                        <Button className="traspanree">Cancel order</Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="chat_screen_">
                      <div className="sender_setaoils">
                        <h2 className="name_sendaer">
                          <span className="first_letter">j </span>
                          junawasarkar
                        </h2>
                        <div className="account_dteils ">
                          <div className="section_contant_row">
                            <label>30d Trades</label>
                            <p>760</p>
                          </div>
                          <div className="section_contant_row">
                            <label>30d Completion rate</label>
                            <p>99.44%</p>
                          </div>
                        </div>
                      </div>
                      <div className="chat_section">
                        <div className="chat_content">
                          <div className="chat_send_content">
                            <p>
                              {" "}
                              After transferring the funds, Click on the
                              “Transferred, notify seller ” button
                            </p>
                          </div>
                          <div className="chat_recive_content">
                            <p>
                              {" "}
                              After transferring the funds, Click on the
                              “Transferred, notify seller ” button
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="inbt_class_send">
                        <input type="text" placeholder="Send Message" />
                        <div className="send_button">
                          <div className="flie_attac">
                            <input type="file" />
                            <span>
                              {" "}
                              <i class="ri-attachment-line"></i>
                            </span>
                          </div>
                          <a href="">
                            <i class="ri-send-plane-2-fill"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
