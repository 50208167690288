//import useState hook to create menu collapse state
import React, {useState, useEffect} from "react";
import apiService from "../core/service/detail";
import {getMethod} from "../core/service/common.api";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import {FaList, FaRegHeart} from "react-icons/fa";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import {RiPencilLine} from "react-icons/ri";
import {BiCog} from "react-icons/bi";
import {Link, NavLink, useNavigate, useLocation} from "react-router-dom";
import {removeAuthorization} from "../core/service/axios";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";

const Header = () => {
  const navigate = useNavigate();
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
      getSiteSettingstatus();
    } else {
      setloginCheck(false);
    }
  });
  const logout = async () => {
    var data = {
      apiUrl: apiService.logout,
    };
    var resp = await getMethod(data);
    if (resp) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    if (response.status) {
      setsitekycStatus(response.data.kycStatus);
    } else {
    }
  };

  return (
    <>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <div className="closemenu" onClick={menuIconClick}>
          {/* changing menu collapse icon on click */}
          {menuCollapse ? (
            <i class="bi bi-caret-right-fill"></i>
          ) : (
            <i class="bi bi-caret-left-fill"></i>
          )}
        </div>
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {/* small and big change using menucollapse state */}

              <p>
                {menuCollapse ? (
                  <a className="navbar-brand w-100" href="/">
                    <img
                      src={require("../img/newimg/logo-headerr.png")}
                      // src={require("../img/newimg/logo_symbol.png")}
                      className="logo  small"
                    />
                  </a>
                ) : (
                  <a className="navbar-brand w-100" href="/">
                    <img
                      // src={require("../img/newimg/logo_fo/r_light.png")}
                      src={require("../img/newimg/logo-headerr.png")}
                      className="logo lighttheme big"
                    />
                    <img
                      src={require("../img/newimg/logo-headerr.png")}
                      className="logo darktheme big"
                    />
                  </a>
                )}
              </p>
            </div>
          </SidebarHeader>
          <SidebarContent>
            {loginCheck && loginCheck == true ? (
              <NavLink
                to="/dashboard"
                data-toggle="tooltip"
                title="User Dashboard"
              >
                <div className="header_icon_meniu">
                  <i class="bi bi-house-door"></i>
                  <span className="text_inn"> Overview</span>
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {/* {loginCheck && loginCheck == true ? (
              <NavLink to="/profile">
                <div className="header_icon_meniu">
                  <i class="bi bi-person"></i>
                  <span className="text_inn">Profile</span>
                </div>
              </NavLink>
            ) : (
              ""
            )} */}
            <NavLink to="/trade/BTC_USDT" data-toggle="tooltip" title="Trade">
              <div className="header_icon_meniu">
                <i class="bi bi-graph-up"></i>
                <span className="text_inn"> Trade</span>
              </div>
            </NavLink>

            {/* <NavLink to="/p2phome">
              <div className="header_icon_meniu">
                <i class="bi bi-hdd-rack-fill"></i>
                <span className="text_inn"> P2P</span>
              </div>
            </NavLink> */}
            {loginCheck && loginCheck == true ? (
              sitekycStatus == "Active" ? (
                <NavLink to="/kyc" data-toggle="tooltip" title="User kyc">
                  <div className="header_icon_meniu">
                    <i class="bi bi-person-check"></i>
                    <span className="text_inn"> KYC</span>
                  </div>
                </NavLink>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <NavLink
              to="/security"
              data-toggle="tooltip"
              title="Account securities"
            >
              <div className="header_icon_meniu">
                <i class="bi bi-shield-check"></i>
                <span className="text_inn"> Account Security</span>
              </div>
            </NavLink>
            {/* <NavLink to="/Bankdetails">
              <div className="header_icon_meniu">
                <i class="fa fa-credit-card" aria-hidden="true"></i>
                <span className="text_inn"> Bank Details</span>
              </div>
            </NavLink> */}
            <NavLink to="/deposit" data-toggle="tooltip" title="Crypto Deposit">
              <div className="header_icon_meniu">
                <i class="bi bi-cash"></i>
                <span className="text_inn"> Deposit</span>
              </div>
            </NavLink>
            <NavLink
              to="/withdraw"
              data-toggle="tooltip"
              title="Crypto Withdraw"
            >
              <div className="header_icon_meniu">
                <i class="fa fa-university" aria-hidden="true"></i>
                <span className="text_inn"> Withdraw</span>
              </div>
            </NavLink>

            {loginCheck && loginCheck == true ? (
              <NavLink
                to="/swap"
                data-toggle="tooltip"
                title="Currencies swaping"
              >
                <div className="header_icon_meniu">
                  <i class="bi bi-arrow-left-right"></i>
                  <span className="text_inn">Swap</span>
                </div>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink
              to="/coming"
              data-toggle="tooltip"
              title="Fiat Deposit COMING SOON"
            >
              <div className="header_icon_meniu">
                <i class="bi bi-cash"></i>
                <span className="text_inn"> Fiat Deposit</span>
              </div>
            </NavLink>
            {/* <NavLink to="/staking">
              <div className="header_icon_meniu">
                <i class="bi bi-unlock"></i>
                <span className="text_inn"> Staking</span>
              </div>
            </NavLink> */}
            {/* {loginCheck && loginCheck == true ? (
              <NavLink to="/p2ppost">
                <div className="header_icon_meniu">
                  <i class="bi bi-badge-ad-fill"></i>
                  <span className="text_inn"> Post Ad</span>
                </div>
              </NavLink>
            ) : (
              ""
            )} */}

            {loginCheck && loginCheck == true ? (
              <NavLink
                to="/allhistory"
                data-toggle="tooltip"
                title="All history"
              >
                <div className="header_icon_meniu">
                  <i class="bi bi-arrow-left-right"></i>
                  <span className="text_inn">History</span>
                </div>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink to="/support" data-toggle="tooltip" title="Support ticket">
              <div className="header_icon_meniu">
                <i class="bi bi-info-circle"></i>
                <span className="text_inn">Support</span>
              </div>
            </NavLink>
            {loginCheck && loginCheck == true ? (
              <NavLink
                to="/referral"
                data-toggle="tooltip"
                title="Currencies swaping"
              >
                <div className="header_icon_meniu">
                  <i class="bi bi-people"></i>
                  <span className="text_inn">Referral</span>
                </div>
              </NavLink>
            ) : (
              ""
            )}
            {/* <NavLink to="/applylaunchpad">
              <div className="header_icon_meniu">
                <i class="bi bi-cash"></i>
                <span className="text_inn">Apply Launchpad</span>
              </div>
            </NavLink>

            <NavLink to="/launchpad">
              <div className="header_icon_meniu">
                <i class="bi bi-cash"></i>
                <span className="text_inn"> Launchpool </span>
              </div>
            </NavLink>

            <NavLink to="/lauchpaddetails">
              <div className="header_icon_meniu">
                <i class="bi bi-cash"></i>
                <span className="text_inn"> Launchpad </span>
              </div>
            </NavLink> */}

            {/* <NavLink to="/notification">
              <div className="header_icon_meniu">
                <i class="bi bi-bell"></i>
                <span className="text_inn">Notification</span>
              </div>
            </NavLink> */}
            {/* {loginCheck && loginCheck == true ? (
              <NavLink to="/transfer">
                <div className="header_icon_meniu">
                  <i class="bi bi-arrow-left-right"></i>
                  <span className="text_inn">Transfer</span>
                </div>
              </NavLink>
            ) : (
              ""
            )} */}
            {/* <Menu iconShape="square">
              <MenuItem active={true} icon={<FiHome />}></MenuItem>
              <MenuItem icon={<FaList />}>Category</MenuItem>
              <MenuItem icon={<FaRegHeart />}>Favourite</MenuItem>
              <MenuItem icon={<RiPencilLine />}>Author</MenuItem>
              <MenuItem icon={<BiCog />}>Settings</MenuItem>
            </Menu> */}
          </SidebarContent>
          <SidebarFooter>
            {loginCheck && loginCheck == true ? (
              <Menu iconShape="square" onClick={logout}>
                <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
              </Menu>
            ) : (
              ""
            )}
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default Header;
