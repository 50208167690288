export const env = {
  apiHost: "https://cashbit.io:3033/",
  frontUrl: "https://cashbit.io/",
  // apiHost: "https://switchexchange.beleaftechnologies.com:3033/",
  // frontUrl: "https://switchexchange.beleaftechnologies.com/",
  // apiHost: "http://localhost:3033/",
  // frontUrl: "http://localhost:3000/",
  upload_preset: "rnqpus0o",
  cloud_name: "dq0wkfflg",
};
