import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import Header from "./Header";
import Footer from "./Footer";
import Sideheader from "./Sidebarheader";
import apiService from "../core/service/v2/index";
import { postMethod } from "../core/service/common.api2";
import P2homePost from "./P2homePost";
import Newsideheader from "./Newsideheader";
import Undermaintain from "./Undermaintain";
import Select from "react-select";

function Home() {
  const options = ["one", "two", "three"];
  useEffect(() => {
    getlunchpadValue();
  }, [])

  const navigate = useNavigate();

  const [LaunchpadValue, setLaunchpadValue] = useState([]);

  const getlunchpadValue = async () => {
    try {
      var data = {
        apiUrl: apiService.getLaunchpadDetails,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        setLaunchpadValue(resp.Data)
        console.log(resp.Data[0], "=-=-=-=-Data")
      }
    } catch (error) {

    }
  }
  const stakeNow = () => {
    navigate("/launchpad")
  }
  const viewWhitepaper = () => {

  }
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Sideheader />
          <div className="main-panel">
            <div>
              <Newsideheader />
            </div>
            <div className="content-wrapper">
              <div className="card add_padin_card ">
                <div className="crypto_p2p">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="contante launch_btnr_col">
                        <div>
                          <h1 className="text-left">{LaunchpadValue.TokenSymbol}Token (Raise 1)</h1>
                          <a href={LaunchpadValue.WebsiteLink} target="_blank">
                            {LaunchpadValue.WebsiteLink}{" "}
                            <i class="bi bi-chevron-right"></i>
                          </a>
                          <p className="description_projec">
                            Building truly Bitcoin-native DeFi
                          </p>
                        </div>
                        <div>
                          <Button onClick={stakeNow} className="stake_btn">Stake Now</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {LaunchpadValue && LaunchpadValue.map((data, i) => {
                return (
                  <div className="card add_padin_card ">
                    <div className="crypto_p2p">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="tabs_launchpad">
                            <ul class="nav nav-tabs">
                              <li class="active">
                                <a
                                  data-toggle="tab"
                                  href="#home"
                                  className="active"
                                >
                                  Project Overview
                                </a>
                              </li>
                              <li>
                                <a data-toggle="tab" href="#menu1">
                                  Tokenomics
                                </a>
                              </li>
                              {/* <li>
                                <a data-toggle="tab" href="#menu2">
                                  Team & Roadmap
                                </a>
                              </li> */}
                            </ul>
                            <div className="row">
                              <div className="col-lg-8">
                                <div class="tab-content">
                                  <div
                                    id="home"
                                    class="tab-pane fade in active show"
                                  >
                                    <div className="into_content">
                                      <div className="coin_orgin">
                                        <div className="origin_content">
                                          <p>Token name:</p>
                                          <h4>
                                            {" "}
                                            <img
                                              src={require("../img/network.png")}
                                              className="img-fluid small_img"
                                            />{" "}
                                            {data.TokenName}
                                          </h4>
                                        </div>
                                        <div className="origin_content">
                                          <p>Token Network:</p>
                                          <h4>
                                            {" "}
                                            <img
                                              src={require("../img/network.png")}
                                              className="img-fluid small_img"
                                            />{" "}
                                            {data.TokenNetwork}
                                          </h4>
                                        </div>
                                        <div className="origin_content">
                                          <p>Token Total Supply:</p>
                                          <h4>
                                            {" "}
                                            <img
                                              src={require("../img/network.png")}
                                              className="img-fluid small_img"
                                            />{" "}
                                            {data.TotalSupply} {data.TokenSymbol}
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="content_dteails">
                                      <h5>Summary</h5>
                                      <p>
                                        {data.Summary}
                                      </p>
                                    </div>
                                  </div>
                                  <div id="menu1" class="tab-pane fade">
                                    <div className="content_dteails">
                                      <h5 className="pt-4">Tokenomics</h5>
                                      <div className="into_content pt-0 width_imside">
                                        <div className="coin_orgin w-100 flex-wrap">
                                          <div className="origin_content">
                                            <p>Ticker:</p>
                                            <h4> {data.tokenomics}</h4>
                                          </div>
                                          <div className="origin_content">
                                            <p>Total token supply:</p>
                                            <h4> {data.TotalSupply} at launch*</h4>
                                          </div>
                                          <div className="origin_content">
                                            <p>Market Cap on Launch:</p>
                                            <h4> $9,770,126</h4>
                                          </div>
                                          <div className="origin_content">
                                            <p>Amount being raised (total):</p>
                                            <h4>$400,000</h4>
                                          </div>
                                          <div className="origin_content">
                                            <p>Token Price:</p>
                                            <h4>${data.StakeRateinusd}</h4>
                                          </div>
                                        </div>
                                      </div>
                                      <h5 className="">Vesting Terms :</h5>
                                      <p>
                                        3.33% on TGE, remaining vested over 34
                                        months
                                      </p>
                                      <p>
                                        *600,000,000 hardcap reached in 10 years
                                        after 200,000,000 GPT have been mined.
                                      </p>
                                      <p>
                                        <b>
                                          * Note: Changes (which will be
                                          communicated to the Launchpool community)
                                          may occur to vesting schedule,
                                          distribution, and token claims, which are
                                          outside of Launchpool's control.
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="socila_ionxs">
                                  <h5>Community and Social Channels</h5>
                                  <div className="icon_color_secioss">
                                    <ul>
                                      <li>
                                        <a href={data.Telegram} target="_blank">
                                          <i class="bi bi-telegram"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href={data.Twitter} target="_blank">
                                          <i class="bi bi-twitter"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href={data.Facebook} target="_blank">
                                          <i class="bi bi-facebook"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href={data.Instagram} target="_blank">
                                          <i class="bi bi-instagram"></i>
                                        </a>
                                      </li>
                                      <li>
                                        <a href={data.WebsiteLink} target="_blank">
                                          <i class="bi bi-globe"></i>
                                        </a>
                                      </li>
                                    </ul>
                                    <Button onClick={viewWhitepaper}>View Whitepaper</Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
