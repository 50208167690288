// import "./App.css";
// import React, { useEffect, useState } from "react";

// import { Routes, Route } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
// import Uploadtype from "./Component/Uploadtype";
// import Create from "./Component/Create";
// import Login from "./Component/Login";
// import Register from "./Component/Register";
// import Otp from "./Component/Otp";
// import Myfund from "./Component/Myfund";
// import Profile from "./Component/Profile";
// import VerifyTfa from "./Component/verifyTfa";
// import Activity from "./Component/Activity";
// import Creators from "./Component/Creators";
// import Staking from "./Component/Staking";
// import Launchpad from "./Component/Launchpad";
// import Launchpadnow from "./Component/Launchpadnow";
// import Launchpadbuy from "./Component/Launchpadbuy";
// import Launchpadapplay from "./Component/Launchpadapplay";
// import Support from "./Component/Support";
// import Bankdetails from "./Component/sidebarbankdetails";
// import P2Ppost from "./Component/P2Ppost";
// import P2home from "./Component/P2home";
// import P2Pchat from "./Component/P2Pchat";
// import HomeNew from "./Component/HomeNew";
// import Sidebarcontent from "./Component/Sidebarcontent";
// import TransactionNew from "./Component/Sidebartransaction";
// import TradeNew from "./Component/SidebarTrade";
// import SidebarLaunchpad from "./Component/SidebarLaunchpad";
// import SidebarLaunchpadapplay from "./Component/SidebarLaunchpadApplay";
// import SidebarLaunchpadbuy from "./Component/SidebarLaunchpadbuy";
// import SidebarLaunchpadnow from "./Component/SidebarLaunchpadnow";
// import SidebarP2ppost from "./Component/SidebarP2ppost";
// import SidebarP2ppostnew from "./Component/SidebarP2ppostnew";
// import SidebarP2pChat from "./Component/SidebarP2pChat";
// import Stakingnew from "./Component/Sidebarstaking";
// import Sidebarprofile from "./Component/Sidebarprofile";
// import SidebarKyc from "./Component/SidebarKyc";
// import SidebarSupport from "./Component/SidebarSupport";
// import SidebarAllhistory from "./Component/SidebarAllhistory";
// import SidebarKYCnew from "./Component/SidebarKYCnew";
// import SidebarAccountsecurity from "./Component/SidebarAccountsecurity";
// import SidebarAccountEdit from "./Component/SidebarAccountEdit";
// import SidebarWithdraw from "./Component/SidebarWithdraw";
// import SidebarDeposit from "./Component/SidebarDeposit";
// import SidebarInternaltransfer from "./Component/SidebarInternaltransfer";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Chart from "./Component/Chart";
// import ForgotPassword from "./Component/ForgotPassword";
// import Transfer from "./Component/Transfer";
// import Sidebarp2pview from "./Component/Sidebarp2pview";
// import Privacy from "./Component/Privacy";
// import IdleTimeOutHandler from "./Component/IdleTimeOutHandler";
// import P2PmyOrders from "./Component/P2PmyOrders";
// import Sidebarp2pmyOrder from "./Component/Sidebarp2pmyOrder";
// import SidebarP2Pedit from "./Component/SidebarP2Pedit";
// import Sidebarp2pprocessOrder from "./Component/Sidebarp2pprocessOrder";
// import Sidebarp2pconfirmOrder from "./Component/Sidebarp2pconfirmOrder";
// import Contactus from "./Component/Contactus";
// import Coming from "./Component/Coming";
// import Sidebarwarning from "./Component/Sidebarwarning";
// import SidebarNotification from "./Component/SidebarNotification";
// import SidebarFiatDeposit from "./Component/SidebarFiatDeposit";
// import Gridtrade from "./Component/Gridtrade";
// import MobileOtp from "./Component/MobileOtp";
// import SupportnewChat from "./Component/SupportnewChat";
// import SidebarDeposit_new from "./Component/SidebarDeposit_new";
// import SidebarWithdraw_new from "./Component/SidebarWithdraw_new";
// import Refarral from "./Component/refarral";
// import UnderConstruction from "./Component/underConstruction";

// function App() {
//   useEffect(() => {

//   }, [])

//   const [isActive, setIsActive] = useState(true);
//   return (
//     <>
//       <Router>
//         <IdleTimeOutHandler
//           onActive={() => {
//             setIsActive(true);
//           }}
//           onIdle={() => {
//             setIsActive(false);
//           }}
//         />
//         <ToastContainer />
//         <Routes>
//           <Route path="/" element={<UnderConstruction />} />
//           <Route path="/contactus" element={<UnderConstruction />} />
//           <Route path="/coming" element={<UnderConstruction />} />
//           <Route path="/gridtrade" element={<UnderConstruction />} />

//           <Route path="/dashboard" element={<UnderConstruction />} />
//           <Route path="/transaction" element={<UnderConstruction />} />
//           <Route path="/trade" element={<UnderConstruction />} />
//           <Route path="/trade/:pair" element={<UnderConstruction />} />
//           {/* <Route path="/launchpadNew" element={<SidebarLaunchpad />} /> */}
//           {/* <Route path="/launchpadNewnow" element={<SidebarLaunchpadnow />} /> */}
//           {/* <Route path="/p2ppost" element={<SidebarP2ppost />} /> */}
//           {/* <Route path="/p2phome" element={<SidebarP2ppostnew />} /> */}
//           {/* <Route path="/p2p/chat/:id" element={<SidebarP2pChat />} /> */}
//           {/* <Route path="/staking" element={<Stakingnew />} /> */}
//           {/* <Route path="/profile" element={<Sidebarprofile />} /> */}
//           {/* <Route path="/Kyc" element={<SidebarKyc />} /> */}
//           <Route path="/support" element={<UnderConstruction />} />
//           <Route path="/Bankdetails" element={<UnderConstruction />} />
//           {/* <Route path="/p2p/view/:id" element={<Sidebarp2pview />} /> */}
//           <Route path="/privacy" element={<UnderConstruction />} />
//           {/* <Route path="/p2ppost-edit/:id" element={<SidebarP2Pedit />} /> */}
//           <Route path="/Contactus" element={<UnderConstruction />} />
//           <Route path="/allhistory" element={<UnderConstruction />} />
//           <Route path="/kyc" element={<UnderConstruction />} />
//           <Route path="/security" element={<UnderConstruction />} />
//           <Route path="/AccountEdit" element={<UnderConstruction />} />
//           {/* <Route path="/withdraw" element={<SidebarWithdraw />} />
//           <Route path="/deposit" element={<SidebarDeposit />} /> */}
//           <Route path="/kycwarning" element={<UnderConstruction />} />
//           <Route path="/notification" element={<UnderConstruction />} />
//           <Route path="/FiatDeposit" element={<UnderConstruction />} />
//           <Route
//             path="/internaltransfer"
//             element={<SidebarInternaltransfer />}
//           />
//           <Route path="/supportChat" element={<UnderConstruction />} />
//           <Route path="/refarral" element={<UnderConstruction />} />

//           {/*
//           <Route
//             path="/launchpadNewbuy/:id"
//             element={<SidebarLaunchpadbuy />}
//           /> */}

//           {/* <Route
//             path="/launchpadapplayNew"
//             element={<SidebarLaunchpadapplay />}
//           /> */}

//           {/* <Route path="/p2p-Orders" element={<Sidebarp2pmyOrder />} />
//           <Route
//             path="/p2p-processOrders"
//             element={<Sidebarp2pprocessOrder />}
//           /> */}
//           {/* <Route path="/p2p-History" element={<Sidebarp2pconfirmOrder />} /> */}

//           {/* <Route path="/Sidebarcontent" element={<Sidebarcontent />} /> */}

//           {/* <Route path="/trade" element={<Trade />} /> */}
//           <Route path="/verify-tfa" element={<UnderConstruction />} />
//           {/* <Route path="/trade/:pair" element={<Trade />} /> */}
//           <Route path="/login" element={<UnderConstruction />} />
//           <Route path="/register" element={<UnderConstruction />} />
//           <Route path="/otp" element={<UnderConstruction />} />
//           <Route path="/myfund" element={<UnderConstruction />} />
//           {/* <Route path="/kyc" element={<Kyc />} /> */}
//           {/* <Route path="/transaction" element={<Transaction />} /> */}
//           <Route path="/forgot/:id" element={<UnderConstruction />} />
//           <Route path="/forgot" element={<UnderConstruction />} />
//           {/* <Route path="/profile" element={<Profile />} /> */}
//           <Route path="/tradeview/:pair" element={<UnderConstruction />} />
//           {/* <Route path="/staking" element={<Staking />} /> */}
//           {/* <Route path="/launchpad" element={<Launchpad />} />
//           <Route path="/launchnow" element={<Launchpadnow />} />
//           <Route path="/launchbuy/:id" element={<Launchpadbuy />} />
//           <Route path="/launchpadapplay" element={<Launchpadapplay />} /> */}
//           {/* <Route path="/support" element={<Support />} /> */}
//           {/* <Route path="/p2p/post_ad" element={<P2home />} />
//           <Route path="/p2p" element={<P2Ppost />} /> */}
//           {/* <Route path="/transfer" element={<Transfer />} /> */}

//           {/* <Route path="/p2p/chat/:id" element={<P2Pchat />} /> */}

//           <Route path="/verify_mobile" element={<UnderConstruction />} />
//           <Route path="/deposit" element={<UnderConstruction />} />
//           <Route path="/withdraw" element={<UnderConstruction />} />
//           <Route path="/UnderConstruction" element={<UnderConstruction />} />

//         </Routes>
//       </Router>
//     </>
//   );
// }

// export default App;

import "./App.css";
import React, {useEffect, useState} from "react";

import {Routes, Route} from "react-router-dom";
import {BrowserRouter as Router} from "react-router-dom";
import Uploadtype from "./Component/Uploadtype";
import Create from "./Component/Create";
import Login from "./Component/Login";
import Register from "./Component/Register";
import Otp from "./Component/Otp";
import Myfund from "./Component/Myfund";
import Profile from "./Component/Profile";
import VerifyTfa from "./Component/verifyTfa";
import Activity from "./Component/Activity";
import Creators from "./Component/Creators";
import Staking from "./Component/Staking";
import Launchpad from "./Component/Launchpad";
import Launchpadnow from "./Component/Launchpadnow";
import Launchpadbuy from "./Component/Launchpadbuy";
import Support from "./Component/Support";
import Bankdetails from "./Component/sidebarbankdetails";
import P2Ppost from "./Component/P2Ppost";
import P2home from "./Component/P2home";
import P2Pchat from "./Component/P2Pchat";
import HomeNew from "./Component/HomeNew";
import ApplyLaunchpad from "./Component/ApplyLaunchpad";
import Sidebarcontent from "./Component/Sidebarcontent";
import TransactionNew from "./Component/Sidebartransaction";
import TradeNew from "./Component/SidebarTrade";
import SidebarLaunchpad from "./Component/SidebarLaunchpad";
import SidebarLaunchpadapplay from "./Component/SidebarLaunchpadApplay";
import SidebarLaunchpadbuy from "./Component/SidebarLaunchpadbuy";
import SidebarLaunchpadnow from "./Component/SidebarLaunchpadnow";
import SidebarP2ppost from "./Component/SidebarP2ppost";
import SidebarP2ppostnew from "./Component/SidebarP2ppostnew";
import SidebarP2pChat from "./Component/SidebarP2pChat";
import Stakingnew from "./Component/Sidebarstaking";
import Sidebarprofile from "./Component/Sidebarprofile";
import SidebarKyc from "./Component/SidebarKyc";
import SidebarSupport from "./Component/SidebarSupport";
import SidebarAllhistory from "./Component/SidebarAllhistory";
import SidebarKYCnew from "./Component/SidebarKYCnew";
import SidebarAccountsecurity from "./Component/SidebarAccountsecurity";
import SidebarAccountEdit from "./Component/SidebarAccountEdit";
import SidebarWithdraw from "./Component/SidebarWithdraw";
import SidebarDeposit from "./Component/SidebarDeposit";
import SidebarInternaltransfer from "./Component/SidebarInternaltransfer";

import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "./Component/Chart";
import ForgotPassword from "./Component/ForgotPassword";
import Transfer from "./Component/Transfer";
import Sidebarp2pview from "./Component/Sidebarp2pview";
import Privacy from "./Component/Privacy";
import IdleTimeOutHandler from "./Component/IdleTimeOutHandler";
import P2PmyOrders from "./Component/P2PmyOrders";
import Sidebarp2pmyOrder from "./Component/Sidebarp2pmyOrder";
import SidebarP2Pedit from "./Component/SidebarP2Pedit";
import Sidebarp2pprocessOrder from "./Component/Sidebarp2pprocessOrder";
import Sidebarp2pconfirmOrder from "./Component/Sidebarp2pconfirmOrder";
import Contactus from "./Component/Contactus";
import Coming from "./Component/Coming";
import Sidebarwarning from "./Component/Sidebarwarning";
import SidebarNotification from "./Component/SidebarNotification";
import SidebarFiatDeposit from "./Component/SidebarFiatDeposit";
import Gridtrade from "./Component/Gridtrade";
import MobileOtp from "./Component/MobileOtp";
import SupportnewChat from "./Component/SupportnewChat";
import SidebarDeposit_new from "./Component/SidebarDeposit_new";
import SidebarWithdraw_new from "./Component/SidebarWithdraw_new";
import Refarral from "./Component/refarral";
import Undermaintain from "./Component/undermaintanance";
import SidebarNewP2p from "./Component/SidebarP2ppost";
import P2pnewchat from "./Component/P2pnewchat";
import {removeAuthToken} from "../src/core/lib/localStorage";
import SidebarLaunchpadnew from "./Component/SidebarLaunchpadnew";
import SidebarLaunchpaddetails from "./Component/SidebarLaunchpaddetails";
import Sidebarp2pPostad from "./Component/Sidebarp2pPostad";
import TearmsCondition from "./Component/TermsCondition";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import About from "./Component/About";
import Marketprice from "./Component/Marketprice";
import Tradingfees from "./Component/Tradingfees";

function App() {
  useEffect(() => {}, []);

  const [isActive, setIsActive] = useState(true);

  function RequireAuth({children}) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }
  return (
    <>
      <Router>
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<HomeNew />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/market" element={<Marketprice />} />

          <Route path="/coming" element={<Coming />} />
          <Route path="/gridtrade" element={<Gridtrade />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Sidebarcontent />
              </RequireAuth>
            }
          />
          <Route path="/transaction" element={<TransactionNew />} />
          <Route path="/trade" element={<TradeNew />} />
          <Route path="/trade/:pair" element={<TradeNew />} />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <SidebarSupport />
              </RequireAuth>
            }
          />
          {/* <Route path="/Bankdetails" element={<Bankdetails />} /> */}
          {/* <Route path="/p2p/view/:id" element={<Sidebarp2pview />} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tradingfees" element={<Tradingfees />} />
          {/* <Route path="/p2ppost-edit/:id" element={<SidebarP2Pedit />} /> */}
          <Route path="/Contactus" element={<Contactus />} />
          <Route
            path="/allhistory"
            element={
              <RequireAuth>
                <SidebarAllhistory />
              </RequireAuth>
            }
          />
          <Route
            path="/kyc"
            element={
              <RequireAuth>
                <SidebarKYCnew />
              </RequireAuth>
            }
          />
          <Route
            path="/security"
            element={
              <RequireAuth>
                <SidebarAccountsecurity />
              </RequireAuth>
            }
          />
          <Route path="/AccountEdit" element={<SidebarAccountEdit />} />
          {/* <Route path="/withdraw" element={<SidebarWithdraw />} />
          <Route path="/deposit" element={<SidebarDeposit />} /> */}
          <Route path="/kycwarning" element={<Sidebarwarning />} />
          <Route
            path="/notification"
            element={
              <RequireAuth>
                <SidebarNotification />
              </RequireAuth>
            }
          />
          <Route path="/FiatDeposit" element={<SidebarFiatDeposit />} />
          <Route path="/applylaunchpad" element={<ApplyLaunchpad />} />
          <Route
            path="/swap"
            element={
              <RequireAuth>
                <SidebarInternaltransfer />
              </RequireAuth>
            }
          />
          <Route
            path="/supportChat"
            element={
              <RequireAuth>
                <SupportnewChat />
              </RequireAuth>
            }
          />
          <Route path="/referral" element={<Refarral />} />
          <Route path="/p2p" element={<SidebarNewP2p />} />
          <Route path="/p2ppost" element={<Sidebarp2pPostad />} />

          <Route path="/chatp2p" element={<P2pnewchat />} />
          <Route path="/launchpad" element={<SidebarLaunchpadnew />} />
          <Route
            path="/lauchpaddetails"
            element={<SidebarLaunchpaddetails />}
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TearmsCondition />} />
          <Route path="/about" element={<About />} />
          <Route path="/verify-tfa" element={<VerifyTfa />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/myfund" element={<Myfund />} />
          <Route path="/forgot/:id" element={<ForgotPassword />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/tradeview/:pair" element={<Chart />} />
          <Route path="/verify_mobile" element={<MobileOtp />} />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <SidebarDeposit_new />
              </RequireAuth>
            }
          />
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                <SidebarWithdraw_new />
              </RequireAuth>
            }
          />
          <Route path="/undermaintanance" element={<Undermaintain />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
