import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import "rc-slider/assets/index.css";
import Header from "./Header";
import {Button} from "@material-ui/core";

import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {setAuthToken, getAuthToken} from "../core/lib/localStorage";
import OTPInput, {ResendOTP} from "otp-input-react";
import moment from "moment";
import Pagination from "react-js-pagination";
import useStateRef from "react-usestateref";

function Home() {
  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [Formdata, setFormdata] = useState(value);

  const [SubjectErr, setSubjectErr] = useState(value);
  const [CategoryErr, setCategoryErr] = useState(value);
  const [textErr, settextErr] = useState(value);

  const [formErr, setformErr] = useState("");

  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [supportCategories, setCategories] = useState(0);
  const [viewChat, setViewChat] = useState(false);
  const [replyMess, replyMessage, replyMessref] = useStateRef("");
  const [chatDatas, setChatDatas] = useState("");
  const [chatHistory, setchatHistory] = useState([]);

  const {Subject, Category, text} = Formdata;

  const getItem = (e) => {
    var {name, value} = e.target;
    let check = {...Formdata, ...{[name]: value}};
    setFormdata(check);
    validate(Formdata);
  };

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    viewData();
    getCategory();
  }, [0]);
  const checkKeyDown = (e) => {
    if (e.key === 'Enter'){
      e.preventDefault();
      reply();
    }

  };

  const validate = async (condition) => {
    var error = {};
    if (condition.Subject == "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (condition.Subject.length < 5) {
      error.Subject = "Minimum 5 Characters only allowed ";
      setSubjectErr(true);
    } else if (condition.Subject.length > 50) {
      error.Subject = "Maximum 50 Characters only allowed ";
      setSubjectErr(true);
    } else {
      setSubjectErr(false);
    }

    if (condition.Category == "Choose Category") {
      error.Category = "Category is Required";
      setCategoryErr(true);
    }

    if (condition.text == "") {
      error.text = "Message is a required field";
      settextErr(true);
    } else if (condition.text.length < 10) {
      error.text = "Minimum 10 Characters only allowed ";
      settextErr(true);
    } else if (condition.text.length > 250) {
      error.text = "Maximum 250 Characters only allowed ";
      settextErr(true);
    } else {
      settextErr(false);
    }
    setformErr(error);
  };

  const submit = async () => {
    validate(Formdata);
    // return false;
    if (
      Formdata.Subject != "" &&
      Formdata.Subject.length > 5 &&
      Formdata.Subject.length < 50 &&
      Formdata.Category != "Choose Category" &&
      Formdata.text != "" &&
      Formdata.text.length > 10 &&
      Formdata.text.length < 250
    ) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData();
    } else {
      //toast.error("All are required fields");
    }
  };
  const viewData = async () => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
      };
      var view = await postMethod(api);
      console.log(view, "=-=-view=-=-=-");
      if (view.status) setuser(view.data.data);
      setTotalpages(view.data.total);
      console.log(view.data.total);
    } catch (error) {}
  };

  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      var response = await getMethod(api);
      if (response.status) {
        setCategories(response.data);
      }
    } catch (error) {}
  };

  const userChat = async (data) => {
    try {
      console.log("=-=-==data=--=-=", data);
      setChatDatas(data);
      setViewChat(true);
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.getSupportDetails,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        setchatHistory(view.Message.reply);
      } else {
        setchatHistory([]);
      }
      console.log(view, "=-=-view=-=-=-view=-=-=-view=-=");
      console.log(view.data.total);
    } catch (error) {}
  };
  const closeTicket = async (data) => {
    try {
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.closeTicket,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        // userChat(chatDatas);
        viewData();
        toast.success(view.Message);
      } else {
        toast.error(view.Message);
      }
    } catch (error) {}
  };

  const back = async (data) => {
    try {
      setViewChat(false);
    } catch (error) {}
  };

  const reply = async () => {
    try {
      if (Object.keys(replyMessref.current).length > 0) {
        var obj = {
          message: replyMessref.current,
          chatId: chatDatas._id,
          tag: "user",
          image: "",
          status: "0",
        };
        var api = {
          apiUrl: apiService.addUserSupport,
          payload: obj,
        };
        var view = await postMethod(api);
        if (view) {
          console.log("**********");
          userChat(chatDatas);
          replyMessage("");
          toast.success(view.Message);
        }
      } else {
        toast.error("Please enter the message!");
      }
    } catch (error) {}
  };

  return (
    <div className="">
      <main className="main-content tradepage-bg login_body_ bg-cover height_100">
        {/* <Header /> */}
        <div className="container pt-5 padin_zero">
          {viewChat == false ? (
            <span>
              <div className=" padin_zero">
                <div className="container d-flex justify-content-center flexnew_center contecnt_ee_pading">
                  <div className="card add_padin_card w-100">
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <h1 className="bard_tirlrr">Support</h1>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 padin_zero">
                    <div className="staking_title launcpad d-block fonrm_card_s need_meanissss">
                      <div class="row">
                        <div className="col-lg-6">
                          <div class="form-group">
                            <label>Subject</label>
                            <input
                              type="email"
                              class="form-control support_input"
                              placeholder="Subject"
                              name="Subject"
                              value={Subject}
                              onChange={getItem}
                            />
                          </div>
                          {SubjectErr == true ? (
                            <p style={{color: "red"}}>{formErr.Subject} </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <div class="form-group ">
                            <label>Select Category</label>
                            <select
                              class="form-control  support_input"
                              name="Category"
                              value={Category}
                              onChange={getItem}
                            >
                              <option selected>Choose Category</option>
                              {supportCategories.length > 0 ? (
                                supportCategories.map((item, i) => (
                                  <option selected>{item.category}</option>
                                ))
                              ) : (
                                <option selected>Choose Category</option>
                              )}
                            </select>
                          </div>
                          {CategoryErr == true ? (
                            <p style={{color: "red"}}>{formErr.Category} </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-12">
                          <div class="form-group ">
                            <label>Message</label>
                            <textarea
                              className="support_textarea"
                              name="text"
                              value={text}
                              placeholder="Message"
                              onChange={getItem}
                            ></textarea>
                          </div>
                          {textErr == true ? (
                            <p style={{color: "red"}}>{formErr.text} </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-12">
                          <div className="submit_butn_s">
                            {buttonLoader == false ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={submit}
                              >
                                Submit
                              </button>
                            ) : (
                              <button type="button" className="btn btn-primary">
                                loading...
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8 padin_zero">
                <div className="container d-flex justify-content-center flexnew_center">
                  <div className="col-lg-12 padin_zero">
                    <div className="staking_title launcpad d-block fonrm_card_s need_meanissss ">
                      <div class="fixTableHead">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Ticket ID</th>
                              <th scope="col">Subject</th>
                              <th scope="col">Message</th>
                              <th scope="col">Category</th>
                              <th scope="col">Date / Time</th>
                              <th scope="col">Action</th>
                              <th scope="col">Ticket Close</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user.length > 0 ? (
                              user.map((data, i) => (
                                <tr>
                                  <td>#{data._id.substring(0, 8)}</td>
                                  <td>{data.subject}</td>
                                  <td>{data.message}</td>
                                  <td>{data.category}</td>
                                  <td>
                                    {moment(data.updated_at).format("lll")}
                                  </td>
                                  <td onClick={() => userChat(data)}> View </td>
                                  {data.status == "0" ? (
                                    <td
                                      onClick={() => closeTicket(data)}
                                      style={{color: "green"}}
                                    >
                                      {" "}
                                      Close{" "}
                                    </td>
                                  ) : (
                                    <td style={{color: "red"}}> Closed </td>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <td colSpan="7"> No data found </td>
                            )}
                          </tbody>
                        </table>
                        {user.length > 0 ? (
                          <Pagination
                            itemClass="page-item" // add it for bootstrap 4
                            linkClass="page-link" // add it for bootstrap 4
                            activePage={currentPage}
                            itemsCountPerPage={recordPerPage}
                            totalItemsCount={totalPage}
                            pageRangeDisplayed={pageRange}
                            onChange={handlePageChange}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          ) : (
            <div className="container pt-5 padin_zero">
              <div className=" padin_zero">
                <div className="container d-flex justify-content-center flexnew_center contecnt_ee_pading">
                  <div className="card add_padin_card w-100">
                    <div className="row">
                      {/* <div className="col-lg-12 p-0">
                        {viewChat == true ? (
                          <h1 className="bard_tirlrr">Chat</h1>
                        ) : (
                          ""
                        )}
                      </div> */}

                      <div className="col-lg-12 p-0">
                        <Button>
                          <i
                            class="fa fa-arrow-left"
                            aria-hidden="true"
                            onClick={back}
                          >
                            {" "}
                            Back{" "}
                          </i>
                        </Button>
                        {/* <h1 className="bard_tirlrr"  >Back</h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 padin_zero">
                    <div className="staking_title launcpad d-block fonrm_card_s need_meanissss">
                      <div class="row justify-center">
                        <div className="col-lg-6">
                          <div className="chat-interface">
                            <div className="admin_message">
                              {chatHistory &&
                                chatHistory.map((item, i) => {
                                  return item.tag == "admin" ? (
                                    <div className="messamge_section admin">
                                      <p>
                                        Admin <span>
                                    {moment(item.posted_at).format("lll")}
                                         </span>
                                      </p>
                                      <div className="content">
                                        {item.message}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="messamge_section user">
                                      <p>
                                        User{" "}
                                        <span>
                                          {moment(item.posted_at).format("lll")}
                                        </span>
                                      </p>
                                      <div className="content">
                                        {item.message}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            {chatDatas && chatDatas.status == "0" ? (
                              <div className="msg_send_section">
                                <input
                                  type="text"
                                  placeholder="Enter your message"
                                  value={replyMessref.current}
                                  onChange={(e) => replyMessage(e.target.value)}
                                  onKeyDown={(e) => checkKeyDown(e)}
                                />
                                <div className="actionbutton">
                                  {/* <div className="file_upload">
                          <input type="file" />
                          <span>
                            <i class="bi bi-paperclip"></i>
                          </span>
                        </div> */}
                                  <Button onClick={reply}>
                                    <i
                                      class="fa fa-paper-plane"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div className="msg_send_section">
                                <p>Your ticket was already closed</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default Home;
