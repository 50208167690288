import React, { useState, useEffect } from "react";
import Header from "./Header";
import Arrow from "../img/ArrowRight.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { setAuthToken, getAuthToken } from "../core/lib/localStorage";
import { useParams } from "react-router-dom";
import { setAuthorization } from "../core/service/axios";
import { Button } from "@material-ui/core";
import "rc-slider/assets/index.css";
import Pagination from "react-js-pagination";
import useStateRef from "react-usestateref";
import Loader from "../Component/Loader";
import { socket } from "../Component/context/socket";
import { env } from "../core/service/envconfig";
import Moment from "moment";

function Home() {
  const options = ["one", "two", "three"];

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch, searchref] = useStateRef("");
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [balanceDatas, setbalanceDatas] = useState([]);

  const handleChange = (e) => {
    try {
      console.log(e.target.value, "eeeeeeeeeeeeeeeeeeeeeeeee");
      setsearch(e.target.value);
      searchWalletList();
    } catch (error) {}
  };

  const recordPerPage = 5;

  // total number of the records
  const totalRecords = 7;

  // range of pages in paginator
  const pageRange = 5;
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    //getUserbalance(pageNumber);
    var current_page = +pageNumber * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      resp_balance.push(balanceDatas[i]);
    }
    setbalanceDetails(resp_balance);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        setprofileData(resp.data);
        // console.log(resp.data);
        setLastLogin(resp.lastLogin);
        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) {}
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,

    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      var balanceData = resp.balance;
      setSiteLoader(false);
      console.log(balanceData)
      console.log(balanceData.total_balance)
      console.log(balanceData.available_balance)
      console.log(balanceData.inorder_balance)
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const getUserbalance = async (pages) => {
    setSiteLoader(false);

    var obj = {
      perpage: perpage,
      page: pages,
      search: search,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        resp_balance.push(balanceData[i]);
      }
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
      // setToatalINRPrice(balanceData.total_balance);
      // setAvailablePrice(balanceData.available_balance);
      // setinorderPrice(balanceData.inorder_balance);
      // setAvailablePrice(availableAmount);
      // setinorderPrice(holdAMount);
    } else {
    }
  };
  // const searchWalletList = async () => {
  //   if(search != '' && search != undefined && search != null)
  //   {
  //     var searchwallet = balanceDatas.filter(function (data) {
  //       return data.currencysymbol.toLowerCase() == search.toLowerCase()
  //     });
  //     // console.log("searchwallet===",searchwallet);
  //     if(searchwallet.length > 0)
  //     {
  //       setbalanceDetails(searchwallet);
  //       settotal(1);
  //     }
  //   }
  //   else
  //   {
  //     getUserbalance(1)
  //   }
  // };

  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = balanceDatas.filter((data) =>
        regexPattern.test(data.currencysymbol)
      );
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
      if (searchWallet.length > 0) {
        setbalanceDetails(searchWallet);
        settotal(1);
      } else {
        getUserbalance(1);
      }
    } else {
      getUserbalance(1);
    }
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      // console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    //getPortfolio();
    socket.on("sitesettings", function (res) {
      console.log(res);
    });
    getProfile();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage)
    refreshUse();
  }, [0]);

  const refreshUse = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    var resp = await getMethod(data);
    if (resp.message == true) {
      getUserbalance(1);
    }
  };

  const [loader, setloader] = useState(false);

  const refresh = async () => {
    var data = {
      apiUrl: apiService.transaction,
    };
    setloader(true);
    var resp = await getMethod(data);
    setloader(false);
    if (resp.message == true) {
      getUserbalance(1);
    }
  };

  return (
    <div className="">
      <main className="main-content  bg-cover dashboard_padind">
        {/* <Header /> */}

        {siteLoader == true ? (
          <Loader />
        ) : (
          <div class="container p-0">
            <div className="p-0">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="card_profile car_alightdd overview_card">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="profile_content_edit">
                          <div className="logo_icon">
                            <img
                              src={require("../img/cahbitlogo.png")}
                              className="img-fluid"
                            />
                          </div>
                          <div className="name_profile">
                            <h2>
                              {profileData.username}{" "}
                              {/* <Link to="/AccountEdit">Edit</Link> */}
                            </h2>
                            <p>{profileData.email} </p>

                            <div className="login_details">
                              <span>
                                Last login Time:{" "}
                                {Moment(lastLogin.createdDate).format("lll")}
                              </span>
                              <span>IP : {lastLogin.ipAddress}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-sm-6">
                  <div className="card_profile car_alightdd">
                    <div className="profile_content_edit flex-grow_1">
                      <div className="copy_referal">
                        <h2> Referral Link </h2>

                        <div className="link_section">
                          <input
                            type="text"
                            value={
                              env.frontUrl +
                              "register?invite=" +
                              profileData.referral_id
                            }
                            disabled
                          />
                          <span
                            onClick={() =>
                              copy(
                                env.frontUrl +
                                  "register?invite=" +
                                  profileData.referral_id
                              )
                            }
                          >
                            <i class="bi bi-clipboard-check"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div class="my_fund_content">
                <div class="container p-0">
                  <div class="">
                    <p class="title_aa textsswallet">Balances</p>
                    <div className="row mb-4">
                      <div className="col-lg-3 col-sm-6">
                        <div className="card_profile overview_card">
                          <p>Portfolio Total Value In (USD)</p>
                          <h3>
                            ${" "}
                            {parseFloat(totalINRPrice && totalINRPrice).toFixed(
                              4
                            )}
                          </h3>
                          {/* <h3>$ 0.0000</h3> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="card_profile clorerree  overview_card">
                          <div>
                            <p>Available Balance In (USD)</p>
                            <h3>$ {AvailablePrice.toFixed(4)}</h3>
                            {/* <h3>$ 0.0000</h3> */}
                          </div>
                          <div className="button_aligng">
                            <Button className="deposit_btn">
                              <Link to="/deposit">Deposit</Link>
                            </Button>
                            <Button className="withdraw_btn">
                              <Link to="/withdraw">Withdraw</Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="card_profile overview_card">
                          <p> Locked Balance In (USD)</p>
                          <h3>${inorderPrice.toFixed(4)}</h3>
                        </div>
                      </div>
                    </div>

                    <p class="title_aa textsswallet">Your Assets</p>
                  </div>
                </div>
              </div>

              <div className="prime_deposit copyicon right search_theme overview_card">
                <input
                  type="text"
                  name="searchvallist"
                  // value={searchvallist}
                  placeholder="Search by Currency Name"
                  onChange={handleChange}
                />
                <span onClick={() => searchWalletList()}>
                  <i class="bi bi-search"></i>
                </span>
              </div>
              <div className="refresh_buton">
                {loader == true ? (
                  <button className="refresh">
                    <i class="bi bi-arrow-clockwise"></i>
                    Loading...
                  </button>
                ) : (
                  <button onClick={refresh} className="refresh">
                    <i class="bi bi-arrow-clockwise"></i>
                    Refresh
                  </button>
                )}
              </div>
              <div class="table_section_">
                <div class="container p-0">
                  <div class="table-responsive bg_card_section overview_table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Asset</th>
                          <th scope="col" class="text_222">
                            On Orders
                          </th>
                          <th scope="col" class="text_222">
                            Available Balance
                          </th>
                          <th scope="col" class="text_222">
                            Total Balance
                          </th>
                          {/* <th scope="col" class="text_222">
                          Launchpad balance
                        </th> */}
                          {/* <th scope="col" class="text_222">
                          P2P balance
                        </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {balanceDetails && balanceDetails.length > 0 ? (
                          balanceDetails.map((item, i) => {
                            if (!item) {
                              console.error(`Item at index ${i} is undefined.`);
                              return null; // Skip rendering this item
                            }
                            console.log(item);
                            return (
                              <tr key={i}>
                                <td>
                                  <div class="coin_table_ta">
                                    <img
                                      src={item.currencyImage}
                                      className=""
                                    />
                                    <div class="row_eee">
                                      <h3>{item.currencysymbol}</h3>
                                      <span>{item.currencyName}</span>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {parseFloat(item.holdAmount).toFixed(8)}{" "}
                                      {item.currencysymbol}{" "}
                                    </h5>
                                    <span>
                                      {parseFloat(
                                        item.estimatedUSDThold
                                      ).toFixed(8)}
                                      $
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {parseFloat(item.currencyBalance).toFixed(
                                        8
                                      )}{" "}
                                      {item.currencysymbol}
                                    </h5>
                                    <span>
                                      {parseFloat(
                                        item.estimatedUSDTbalance
                                      ).toFixed(8)}
                                      $
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="row_oiu_p">
                                    <h5>
                                      {parseFloat(
                                        item.currencyBalance +
                                          parseFloat(item.holdAmount)
                                      ).toFixed(8)}{" "}
                                      {item.currencysymbol}
                                    </h5>
                                    <span>
                                      {parseFloat(
                                        item.estimatedUSDTtotal
                                      ).toFixed(8)}{" "}
                                      $
                                    </span>
                                  </div>
                                </td>
                                {/* <td>
                                <div class="row_oiu_p">
                                  <h5>
                                    {item.launchPadAmount != undefined &&
                                    item.launchPadAmount != null &&
                                    item.launchPadAmount != ""
                                      ? parseFloat(
                                          item.launchPadAmount
                                        ).toFixed(8)
                                      : (0).toFixed(8)}{" "}
                                    {item.currencysymbol}{" "}
                                  </h5>
                                </div>
                              </td> */}

                                {/* <td>
                                <div class="row_oiu_p">
                                  <h5>
                                    {" "}
                                    {parseFloat(
                                        item.p2p
                                    ).toFixed(8)}{" "}
                                    {item.currencysymbol}{" "}
                                  </h5>
                                </div>
                              </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="4">
                              <span className="w-100 text-center text-white d-block">
                                No History found!
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {balanceDetails && balanceDetails.length > 0 ? (

                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={recordPerPage}
                      totalItemsCount={total}
                      pageRangeDisplayed={pageRange}
                      onChange={handlePageChange}
                    />
                    ):""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default Home;
