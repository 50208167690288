import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import "react-dropdown/style.css";
import "rc-slider/assets/index.css";
import Headernew from "./Header";
import TradePage from "./Tradenew";
import {Link, useNavigate} from "react-router-dom";
import {socket} from "./context/socket";
import apiService from "../core/service/detail";
import {postMethod, getMethod} from "../core/service/common.api";
import Moment from "moment";
import {removeAuthorization} from "../core/service/axios";
import $ from "jquery";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";

function Newsideheader() {
  const [alretmessage, setalretmessage] = useState("");
  const [popup, setpopup, popupref] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [isOpen, setIsOpen, isOpenref] = useState(true);
  const [sitekycStatus, setsitekycStatus] = useState("DeActive");
  const [notifications, setNotification] = React.useState("");
  let navigate = useNavigate();

  useEffect(() => {
    socket.connect();
    const Socket = localStorage.getItem("socket_token");
    if (Socket) {
      var count = 0;
      const ID = Socket.split("_")[0];
      socket.on("socketResponse3" + ID, function (res) {
        const settime = setInterval(() => {
          count = count + 1;
          console.log(count, "===counts");
          $("#hiddenbtn").trigger("click");
          console.log(res.message, "resres");
          setalretmessage(res.message);
          setpopup(true);
          logout();
          if (count == 2) {
            clearInterval(settime);
            count = 1;
          }
        }, 1000);
      })
    }
    fetchTheme();
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }

    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      socket.on("socketResponse" + socketToken, function (res) {
        if (res.Reason == "notify") {
          getNotifications();
        }
      });
    }
  }, [0]);
  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setIsOpen(true);
        document.body.classList.toggle("light", isOpenref.current);
      } else {
        setIsOpen(false);
      }
    } else {
      localStorage.setItem("theme", "dark");
      setIsOpen(true);
    }
  };

  const changeTheme = (value) => {
    localStorage.setItem("theme", value);
    var settheme = value == "light" ? true : false;
    setIsOpen(settheme);
    document.body.classList.toggle("light", isOpenref.current);
  };

  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.getnotifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) {}
  };

  const clickNotify = async (params) => {
    try {
      var obj = {
        _id: params,
      };
      var data = {
        apiUrl: apiService.updateNotifyRecord,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
      } else {
      }
    } catch (error) {}
  };

  const logout = async () => {
    var data = {
      apiUrl: apiService.logout,
    };
    var resp = await getMethod(data);
    if (resp) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };

  const getSiteSettingstatus = async () => {
    var data = {
      apiUrl: apiService.getSiteDatas,
    };
    var response = await getMethod(data);
    console.log("getkyc===", response);
    if (response.status) {
      console.log(response.data.kycStatus, "==kyc======");
      setsitekycStatus(response.data.kycStatus);
    } else {
    }
  };

  return (
    <>
      <header className="sideber_header">
        {/* <div className="mr-auto">
          <TradePage thememode={themeMode}/>
          <div className="search_banner">
            <i class="bi bi-search"></i>
            <input type="text" placeholder="Search token" />
          </div>
        </div> */}
        <div className="mr-auto">
          <Link to="/dashboard" className="mr-3">
            <a
              className="trade111"
              data-toggle="tooltip"
              title="User Dashboard"
            >
              Dashboard
            </a>
          </Link>
          <Link to="/trade/BTC_USDT" className="mr-3">
            <a className="trade111" data-toggle="tooltip" title="Trade">
              Trade
            </a>
          </Link>
          <Link to="/market" className="mr-3">
            <a className="trade111" data-toggle="tooltip" title="Today Market">
              Market
            </a>
          </Link>
          <Link to="/swap" className="mr-3">
            <a className="trade111" data-toggle="tooltip" title="Swaping">
              Swap
            </a>
          </Link>
          <Link to="/coming" className="mr-3 lable">
            <span className="blink">Soon</span>
            <a
              className="trade111"
              data-toggle="tooltip"
              title="Future trade COMING SOON"
            >
              Future
            </a>
          </Link>
          <Link to="/coming" className="mr-3 lable">
            <span className="blink">Soon</span>
            <a
              className="trade111"
              data-toggle="tooltip"
              title="Launchpad COMING SOON"
            >
              Launchpad
            </a>
          </Link>
          <Link to="/FiatDeposit" className="mr-3 mobile-show">
            <a className="trade111"> Fiat Withdraw</a>
          </Link>
          {sitekycStatus == "Active" ? (
            <Link to="/kyc" className="mr-3 mobile-show">
              <a className="trade111">KYC</a>
            </Link>
          ) : (
            ""
          )}
          <Link to="/Bankdetails" className="mr-3 mobile-show">
            <a className="trade111">Bank Details</a>
          </Link>
          <Link to="support" className="mr-3 mobile-show">
            <a className="trade111">Support</a>
          </Link>
        </div>
        <div className="ml-auto">
          <div className="menu_profile">
            <div class="dropdown">
              {loginCheck && loginCheck === true
                ? ""
                : // <button
                  //   class="btn btn-secondary dropdown-toggle"
                  //   type="button"
                  //   id="dropdownMenuButton"
                  //   data-toggle="dropdown"
                  //   aria-haspopup="true"
                  //   aria-expanded="false"
                  // >
                  //   <img
                  //     src={require("../img/newimg/AIEC.png")}
                  //     className="logo "
                  //   />
                  // </button>
                  ""}
              {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">
                  Action
                </a>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
                <a class="dropdown-item" href="#">
                  Something else
                </a>
              </div> */}
            </div>
            {/* <div class="dropdown">
              <button
                type="button"
                class="btn dropdown-toggle hover_white"
                data-toggle="dropdown"
              >
                Wallet
              </button>
              <div class="dropdown-menu bag_clos">
                <Link to="/deposit" className="dropdown-item">
                  <span className="blink">Deposit</span>
                </Link>
                <Link to="/withdraw" className="dropdown-item">
                  <span className="blink">Withdraw</span>
                </Link>
                <Link to="/allhistory" className="dropdown-item">
                  <span className="blink">History</span>
                </Link>
              </div>
            </div> */}
            {/* <div class="dropdown">
              <button
                type="button"
                class="btn dropdown-toggle hover_white"
                data-toggle="dropdown"
              >
                <i class="bi bi-person-fill"></i>
              </button>
              <div class="dropdown-menu bag_clos">
                <Link to="/dashboard" className="dropdown-item">
                  <span className="blink">Overview</span>
                </Link>
                <Link to="/security" className="dropdown-item">
                  <span className="blink">Account Security</span>
                </Link>
                <Link className="dropdown-item">
                  <span className="blink" onClick={logout}>
                    Signout
                  </span>
                </Link>
              </div>
            </div> */}
            {/* {isOpenref.current === true ? (
              <Button onClick={() => changeTheme("dark")}>
                <i style={{color: "black"}} class="bi bi-moon"></i>
              </Button>
            ) : (
              <Button onClick={() => changeTheme("light")}>
                <i class="bi bi-sun"></i>
              </Button>
            )} */}
            {loginCheck && loginCheck == true ? (
              <div className="notification_section  padingrespos menu_profile notifycount ">
                {notifications.length != 0 ? (
                  <Button data-toggle="tooltip" title="Site Notification">
                    <i
                      class="bi bi-bell-fill"
                      data-toggle="collapse"
                      data-target="#notification"
                    ></i>
                  </Button>
                ) : (
                  <Button data-toggle="tooltip" title="Site Notification">
                    <Link to="/notification">
                      <i class="bi bi-bell-fill"></i>
                    </Link>
                  </Button>
                )}

                {notifications.length != 0 ? (
                  <div className="count_data">
                    {notifications && notifications.length}
                  </div>
                ) : (
                  ""
                )}
                <div id="notification" class="collapse">
                  <div className="content_notification">
                    <h3>
                      Notification <Link to="/notification">View all</Link>
                    </h3>

                    <div className="overflow_seee">
                      {notifications && notifications.length > 0
                        ? notifications.map((item, i) => {
                            return (
                              <span
                                onClick={() => {
                                  clickNotify(item._id);
                                }}
                              >
                                <Link to={item.link} className="msg-noti">
                                  <small style={{color: "#d9d9d9"}}>
                                    {Moment(item.createdAt).fromNow()}
                                  </small>
                                  <p style={{color: "#e3b36f"}}>
                                    {item.message}
                                  </p>
                                </Link>
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {loginCheck && loginCheck == true ? (
              <button onClick={logout} class="logout_but">
                Logout
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </header>
      <div className="header-non fixed_header bg-none">
        <Headernew />
        {popupref.current == true ? (
          <>
            <button
              type="button"
              id="hiddenbtn"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Launch demo modal
            </button>

            <div
              className="modal fade popupalert"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header mx-auto">
                    <h3 class="text-white">Alert Message</h3>
                  </div>
                  <div class="modal-body">
                    <h5 className="h4 text-white my-3 text-center">
                      {alretmessage}
                    </h5>
                  </div>
                  <div class="">
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                        onClick={logout}
                      >
                        Logout
                      </button>

                      <button
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Stay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Newsideheader;
