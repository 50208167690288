import React, {useEffect} from "react";
import useState from "react-usestateref";
import {Button} from "@material-ui/core";
import Count from "./Countdown";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Header from "./Header";
import Footer from "./Footer";
import {Link, useNavigate} from "react-router-dom";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";
import {getMethod} from "../core/service/common.api";
import {socket} from "./context/socket";
import "react-phone-number-input/style.css";
import GridLayout from "react-grid-layout";

function Home() {
  const layout = [
    {i: "a", x: 0, y: 0, w: 1, h: 2, static: true},
    {i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
    {i: "c", x: 4, y: 0, w: 1, h: 2},
  ];
  return (
    <GridLayout className="layout" cols={12} rowHeight={30} width={1200}>
      <div key="a" data-grid={{x: 0, y: 0, w: 1, h: 2, static: true}}>
        a
      </div>
      <div key="b" data-grid={{x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4}}>
        b
      </div>
      <div key="c" data-grid={{x: 4, y: 0, w: 1, h: 2}}>
        c
      </div>
    </GridLayout>
  );
}

export default Home;
